"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateRowNumberColumn = generateRowNumberColumn;
//表格多选列
//checkbox 组件
var RowNumberCell = {
  name: 'RowNumberCell',
  props: {
    cellText: String,
    select: Boolean
  },
  data: function data() {
    return {};
  },
  render: function render(h, params) {
    var _$this = this;

    return h("div", {
      on: {}
    }, [h("span", {
      //staticClass: "",
      staticStyle: {
        'font-size': '12px',
        'width': '40px',
        'font-weight': _$this.select ? 'bold' : '',
        'color': _$this.select ? '#2d8cf0' : ''
      },
      attrs: {}
    }, _$this.cellText)]);
  }
};

function generateRowNumberColumn(vmThis, //主表体组件this
tableOwner) {
  return {
    title: '序号',
    sortable: true,
    width: 45,
    disableDrag: true,
    fixed: 'left',
    renderHeader: function renderHeader(h, params) {
      return h(RowNumberCell, {
        props: {
          cellText: "序号"
        }
      });
    },
    render: function render(h, params) {
      var _row = params.row;
      var _index = _row.__dataIndex;
      return h(RowNumberCell, {
        props: {
          cellText: "" + (_index + 1),
          select: vmThis.scrollSynclData.clicked_index == _index
        }
      });
    },
    key: '',
    noNeedVertical: true,
    enableEllipsis: true
  };
}