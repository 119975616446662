"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.string.fixed");

require("core-js/modules/es6.number.constructor");

var _vue = _interopRequireDefault(require("vue"));

var _SingleTableHeader = _interopRequireDefault(require("./SingleTableHeader.vue"));

var _SingleTableSummaryFooter = _interopRequireDefault(require("./SingleTableSummaryFooter.vue"));

var _VirtualScrollTableBody = _interopRequireDefault(require("./VirtualScrollTableBody.vue"));

var _VirtualScrollTableFixed = _interopRequireDefault(require("./VirtualScrollTableFixed.vue"));

var _selectionUtil = require("./tableHelper/selectionUtil");

var _rowNumberUtil = require("./tableHelper/rowNumberUtil");

//import elementResizeDetectorMaker from 'element-resize-detector';
var VmProps =
/** @class */
function () {
  function VmProps() {
    //主表体组件this
    this.tableOwner = Object;
    this.columnsConfig = Array;
    this.data = Array;
    this.recordKey = String;
    this.headerHeight = Number;
    this.bodyHeight = Number;
    this.tableWidth = Number;
    this.recordHeight = Number;
    this.headerClass = {
      type: String,
      default: 'c-table-header__default'
    };
    /*是否启用多选*/

    this.multiSelection = {
      type: Boolean,
      default: false
    };
    /*是否显示序号*/

    this.showRowNumber = {
      type: Boolean,
      default: true
    };
    /*多选模式下用于初始化勾选状态*/

    this.initRowSelection = Function;
    this.loading = Boolean;
    /*在加载数据的时候是否重置滚动条 */

    this.autoResetScroll = Boolean;
    /*显示底部统计列*/

    this.showSummaryFooter = {
      type: Boolean,
      default: false
    };
  }

  return VmProps;
}();

var VmComputed =
/** @class */
function () {
  function VmComputed() {
    this.getUnFixedColumnsConfig = function () {
      return this.cloneColumnsConfig.filter(function (m) {
        return m.fixed != 'right' && m.fixed != 'left';
      });
    };

    this.getFixedLeftColumnsConfig = function () {
      return this.cloneColumnsConfig.filter(function (m) {
        return m.fixed == 'left';
      });
    };

    this.getFixedRightColumnsConfig = function () {
      return this.cloneColumnsConfig.filter(function (m) {
        return m.fixed == 'right';
      });
    };

    this.getFixedLeftWidth = function () {
      return getColumnsWidth(this.getFixedLeftColumnsConfig);
    };

    this.getFixedRightWidth = function () {
      return getColumnsWidth(this.getFixedRightColumnsConfig);
    };

    this.getUnFixedWidth = function () {
      return getColumnsWidth(this.getUnFixedColumnsConfig);
    };

    this.getAllColumnsWidth = function () {
      return getColumnsWidth(this.cloneColumnsConfig);
    };

    this.getBodyHeight = function () {
      return this.data.length * this.recordHeight;
    };

    this.getTableWrapperStyle = function () {
      var _$this = this; //最外层嵌套元素样式


      var _width = _$this.bodyVisable ? _$this.tableWidth ? _$this.tableWidth : _$this.getTableWrapperWidth() : 0;

      return {
        'width': 0 != _width ? _width + "px" : 'inherit',
        'position': "relative"
      };
    };

    this.getTableShadowStyle = function () {
      //最外层嵌套元素样式
      var _width = this.bodyVisable ? this.tableWidth ? this.tableWidth : this.bodyWidth : 0;

      return {
        'width': 0 != _width ? _width + "px" : 'inherit',
        height: this.getTableHeight + "px",
        "position": "absolute",
        "top": "0px"
      };
    };

    this.getTableFooterStyle = function () {
      //最外层嵌套元素样式
      var _width = this.bodyVisable ? this.tableWidth ? this.tableWidth : this.bodyWidth : 0;

      return {
        width: 0 != _width ? _width + "px" : 'inherit'
      };
    };

    this.getHeaderStyle = function () {
      //let _totalWidth = this.getUnFixedWidth + this.getFixedLeftWidth + this.getFixedRightWidth;
      return {
        'width': this.tableWidth ? this.tableWidth + "px" : this.bodyVisable ? this.bodyWidth - 18 + "px" : 'inherit',
        "overflow-x": "hidden",
        "overflow-y": "hidden"
      }; //return `${(this.scrollSynclData ? this.scrollSynclData.offsetWidth : this.tableWidth)}px`;
    };

    this.getHeaderColumnWidth = function () {
      return (this.scrollSynclData ? this.scrollSynclData.offsetWidth : this.tableWidth) - this.scrollSynclData.scrollbarWidth - 4 + "px";
    };

    this.getTableHeight = function () {
      return this.bodyHeight + this.headerHeight;
    };

    this.getFixedRightClass = function () {
      return 'c-table-fiexed-right'; //return this.hiddenVerticalScroll ? '' : 'c-table-fiexed-right';
    };

    this.getFixedRightStyle = function () {
      var _height = this.getTableHeight;

      if (this.hiddenVerticalScroll) {} else {
        //有滚动条
        if (this.data && this.data.length > 0) {
          _height = _height - this.scrollSynclData.scrollbarWidth;
        } else {
          _height = _height - this.scrollSynclData.scrollbarWidth - 2;
        }
      }

      return {
        'top': 0 + "px",
        'right': this.scrollSynclData.scrollbarWidth + 1 + "px",
        'width': this.getFixedRightWidth + "px",
        'height': _height + "px",
        'position': 'absolute',
        'background-color': '#fff',
        "overflow-x": "hidden",
        "overflow-y": "hidden",
        'box-sizing': 'order-box',
        "border-bottom": "0px none #dddddd"
      };
    };

    this.getFixedRightHeadStyle = function () {
      var _height = this.headerHeight - 1;

      return {
        'top': 1 + "px",
        'right': "0px",
        'width': this.getFixedRightWidth + "px",
        'height': _height + "px",
        'position': 'absolute',
        'background-color': '#f8f8f9',
        "overflow-x": "hidden",
        "overflow-y": "hidden",
        'box-sizing': 'order-box'
      };
    };

    this.getFixedLeftClass = function () {
      return 'c-table-fiexed-left'; //return this.hiddenVerticalScroll ? '' : 'c-table-fiexed-left';
    };

    this.getFixedLeftStyle = function () {
      var _height = this.getTableHeight;

      if (this.hiddenVerticalScroll) {} else {
        //有滚动条
        if (this.data && this.data.length > 0) {
          _height = _height - this.scrollSynclData.scrollbarWidth;
        } else {
          _height = _height - this.scrollSynclData.scrollbarWidth - 2;
        }
      }

      return {
        'border-right': this.hiddenVerticalScroll ? '1px solid #dddddd' : '',
        'top': 0 + "px",
        'left': 1 + "px",
        'width': this.getFixedLeftWidth + "px",
        'height': _height + "px",
        'position': 'absolute',
        'background-color': '#fff',
        "overflow-x": "hidden",
        "overflow-y": "hidden",
        'box-sizing': 'order-box',
        "border-bottom": "0px none #dddddd"
      };
    };

    this.getFloatRightHeaderStyle = function () {
      return {
        height: this.headerHeight + "px"
      };
    };
  }

  return VmComputed;
}();

var VmData =
/** @class */
function () {
  function VmData() {
    //滚动条同步对像,body通过这个对像将同步信息传给header
    this.scrollSynclData = {
      scrollTop: 0,
      scrollLeft: 0,
      scrollbarWidth: 16,
      offsetWidth: 0,
      hover_index: -1,
      clicked_index: -1,
      virtualItems: {
        renderData: [],
        newItems: [],
        replaceItemsIndex: 0
      }
    };
    this.cloneColumnsConfig = null;
    this.bodyVisable = false; //needResize = false;

    this.hiddenVerticalScroll = false;
    this.bodyWidth = 0;
    this.selectionColumn = null;
    this.rowNumberColumn = null;
    this.isDoResize = false;
  }

  return VmData;
}();

var VmWatch =
/** @class */
function () {
  function VmWatch() {
    this.columnsConfig = {
      handler: function handler(config) {
        var _$this = this;

        if (null == _$this.rowNumberColumn) {
          _$this.rowNumberColumn = (0, _rowNumberUtil.generateRowNumberColumn)(_$this, _$this.tableOwner);
        }

        if (!config || config.length == 0) {
          _$this.cloneColumnsConfig = [_$this.rowNumberColumn];
        }

        if (null == _$this.selectionColumn) {
          //用临时变量的话会selectionColumn绑定到组件上会有异常
          //（因为构建时, VmWatch进入了两次, 函数内的_toggleSelectObject和绑定到组件上的_toggleSelectObject不是同一对象），回头研究
          _$this.selectionColumn = (0, _selectionUtil.generateSelectionColumn)(_$this, _$this.tableOwner);
        }

        if (_$this.multiSelection) {
          config = [_$this.selectionColumn].concat(config);
        }

        if (_$this.showRowNumber) {
          config = [_$this.rowNumberColumn].concat(config);
        }

        var _resize = false;

        if (_$this.cloneColumnsConfig) {
          getAndInitColumnsDefaultWidth(config);

          for (var _cIndex = 0; _cIndex < _$this.cloneColumnsConfig.length; _cIndex++) {
            var _col_1 = _$this.cloneColumnsConfig[_cIndex];
            var _col_2 = config[_cIndex];

            if (_col_1.defaultWidth != _col_2.defaultWidth || _col_1.key != _col_2.key || _col_1.render != _col_2.render || _col_1.title != _col_2.title || _col_1.renderHeader != _col_2.renderHeader) {
              _$this.cloneColumnsConfig = config;
              _resize = true;
              break;
            }
          }
        } else {
          _$this.cloneColumnsConfig = config;
          _resize = true;
        }

        if (_resize || _$this.getTableWrapperWidth() > getColumnsWidth(_$this.cloneColumnsConfig)) {
          //"debug code"
          //console.log('columns resize');
          //"end debug code"
          _$this.handleResize();
        }
      },
      immediate: true
    };
    this.data = {
      handler: function handler(rowAry) {
        //"debug code"
        //console.log('SingleTable handler data');
        //"end debug code"
        var _$this = this;

        var _selectionColumn = _$this.selectionColumn;

        if (_$this.multiSelection && _selectionColumn) {
          var _toggleSelectObject = _selectionColumn.toggleSelectObject;

          _selectionColumn.resetSelection();

          var _cellDict = _selectionColumn.cellSelectionDict;

          for (var _index in _cellDict) {
            _cellDict[_index].checked = false;
          }

          if (rowAry && rowAry.length > 0) {
            var _checkedCount = 0;

            for (var _index = 0; _index < rowAry.length; _index++) {
              var _cellObject = _cellDict[_index];

              if (!_cellObject) {
                _cellDict[_index] = _cellObject = {};
              }

              if (_$this.initRowSelection) {
                _cellObject.checked = _$this.initRowSelection(rowAry[_index]);
              } else {
                _cellObject.checked = rowAry[_index]._checked || false; //_checked 用于兼容iview
              }

              if (_cellObject.checked) {
                _checkedCount++; //console.log(rowAry.length, _index, _checkedCount);
              }

              if (_cellObject.setCellChecked) {
                _cellObject.setCellChecked(_cellObject.checked);
              }
            } //判断全选


            _toggleSelectObject.checked = _checkedCount == rowAry.length;
          } else {
            _toggleSelectObject.checked = false;
          }

          if (_toggleSelectObject && _toggleSelectObject.setCellChecked) {
            _toggleSelectObject.setCellChecked(_toggleSelectObject.checked);
          }
        }

        _$this.scrollSynclData.clicked_index = -1;
      },
      immediate: true,
      deep: true
    };
  }

  return VmWatch;
}();

var VmMethods =
/** @class */
function () {
  function VmMethods() {
    /**命名为了兼容iview*/
    this.getCheckedIndexAry = function () {
      return this.selectionColumn ? this.selectionColumn.getSelectionIndexAry() : [];
    };

    this.getFocusdIndex = function () {
      return this.scrollSynclData ? this.scrollSynclData.clicked_index : -1;
    };

    this.clickCurrentRow = function (index) {
      var _$this = this;

      _$this.scrollSynclData.clicked_index = index;

      _$this.tableOwner.$emit('on-row-click', JSON.parse(JSON.stringify(_$this.data[index])), index);
    };

    this.focusRowByIndex = function (index) {
      var _$this = this;

      var _virtualScrollBody = _$this.$refs.virtualScrollBody;

      if (_virtualScrollBody) {
        var _totalHeight_1 = index * _$this.recordHeight; //console.log('setVirtualScrollToY', _totalHeight, index, _$this.recordHeight, _virtualScrollBody.setVirtualScrollToY);


        _$this.$nextTick(function () {
          if (_virtualScrollBody.setVirtualScrollToY) {
            _virtualScrollBody.setVirtualScrollToY(_totalHeight_1);
          }
        });
      }
    };

    this.hasFooterSlot = function () {
      var _$this = this;

      return _$this.$slots.footer !== undefined;
    };

    this.getSelectionData = function () {
      return this.selectionColumn.getSelectionData();
    };

    this.getTableWrapperWidth = function () {
      var _$this = this;

      var _tableWraper = _$this.$refs.tableWrapper;

      if (!_tableWraper) {
        return -1;
      }

      var _bodyWidth = _tableWraper.clientWidth; //tableHeader

      if (0 == _bodyWidth) {
        _bodyWidth = _tableWraper.scrollWidth;
      }

      return 0 == _bodyWidth ? 0 : _bodyWidth;
    };

    this.tableResize = function (cb) {
      //自适应列宽
      var _$this = this;

      _$this.bodyWidth = _$this.getTableWrapperWidth();

      var _getAllColumnsWidth = getAndInitColumnsDefaultWidth(_$this.cloneColumnsConfig, _$this.bodyWidth); // _$this.getAllColumnsWidth;


      var _getUnFixedWidth = _$this.getUnFixedWidth;
      _$this.hiddenVerticalScroll = _$this.bodyWidth > _getAllColumnsWidth;

      var _diffWidth = _$this.bodyWidth - _getAllColumnsWidth; //console.log('bodyWidth=', _$this.bodyWidth, '_defWidth=', _diffWidth);


      var _lessWidth = _diffWidth;
      var _unFixColumns = _$this.getUnFixedColumnsConfig;

      for (var _c = 0, _cLen = _unFixColumns.length, _cLast = _cLen - 1; _c < _cLen; _c++) {
        var _col = _unFixColumns[_c];

        if (_$this.hiddenVerticalScroll) {
          //如果长度超过设定宽度，调整列宽度
          var _defaultWidth = parseInt(_col.defaultWidth);

          var _rateWidth = parseInt(_defaultWidth / _getUnFixedWidth * _diffWidth);

          _col.width = _defaultWidth + (_lessWidth < _rateWidth || _cLast == _c ? _lessWidth : _rateWidth);
          _lessWidth -= _rateWidth;
          _col.cWidth = _col.width + "px"; //console.log('_col=', _col.key, '_lessWidth=', _lessWidth, '_w2=', _rateWidth, 'cWidth=', _col.cWidth);
        } else {
          _col.cWidth = _col.defaultWidth + "px";
        }
      } //强制刷新computed依赖缓存


      _$this.$set(_$this, 'cloneColumnsConfig', _$this.cloneColumnsConfig.slice());

      _$this.$nextTick(function () {
        _$this.bodyVisable = !!_$this.$refs.tableHeader;
        cb && cb();
      });
    };

    this.handleResize = function () {
      var _$this = this;

      if (_$this.isDoResize) {
        return;
      }

      _$this.isDoResize = true;
      _$this.bodyVisable = false;

      _$this.tableResize(function () {
        _$this.$nextTick(function () {
          _$this.isDoResize = false;
        });
      }); //window.setTimeout(
      //	() =>
      //	{
      //		let _tableWraper = _$this.$refs.tableWrapper as HTMLElement;
      //		let _isVisable = true;
      //		if (_tableWraper)
      //		{
      //			do 
      //			{
      //				if ('none' == _tableWraper.style.display)
      //				{
      //					_isVisable = false;
      //					break;
      //				}
      //			}
      //			while (_tableWraper = _tableWraper.parentElement);
      //		}
      //		_$this.tableResize();
      //		//if (_isVisable)
      //		//{
      //		//	_$this.tableResize();
      //		//}
      //		//else
      //		//{
      //		//	console.log('_$this.needResize = true');
      //		//	_$this.needResize = true;
      //		//}
      //	},
      //	300
      //);

    };
  }

  return VmMethods;
}();

var _default = {
  components: {
    TableHeader: _SingleTableHeader.default,
    TableSummaryFooter: _SingleTableSummaryFooter.default,
    VirtualScrollTableBody: _VirtualScrollTableBody.default,
    VirtualScrollTableFixed: _VirtualScrollTableFixed.default
  },
  name: 'SingleTable',
  props: new VmProps(),
  watch: new VmWatch(),
  data: function data() {
    return new VmData();
  },
  computed: new VmComputed(),
  methods: new VmMethods(),
  mounted: function mounted() {
    var _$this = this;

    _$this.handleResize(); ////_$this.$nextTick(() => _$this.ready = true);


    on(window, 'resize', _$this.handleResize); //_$this.observer = elementResizeDetectorMaker();
    //_$this.observer.listenTo(_$this.$el, _$this.handleResize);

    _$this.$on('on-visible-change', function (val) {
      if (val) {
        _$this.handleResize();
      }
    });
  },
  updated: function updated() {
    var _$this = this;

    if (0 == _$this.bodyWidth) {
      _$this.bodyWidth = _$this.getTableWrapperWidth();
    }

    var _wrapWidth = _$this.getTableWrapperWidth(); //div隐藏状态下为0


    if (_wrapWidth > getColumnsWidth(_$this.cloneColumnsConfig)) {
      //"debug code"
      //console.log('updated resize', _$this.scrollSynclData.clicked_index);
      //"end debug code"
      _$this.handleResize();
    } else {
      if (!_$this.isDoResize && 0 == _wrapWidth && _$this.bodyVisable) {
        //console.log(
        //	'handleResize',
        //	_wrapWidth,
        //	getColumnsWidth(_$this.cloneColumnsConfig),
        //	_$this.bodyVisable
        //);
        window.setTimeout(function () {
          _$this.handleResize();
        }, 400);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleResize); //this.observer.removeListener(this.$el, this.handleResize);
  }
};
exports.default = _default;
var isServer = _vue.default.prototype.$isServer;
/* istanbul ignore next */

var on = function () {
  if (!isServer && document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
}();
/* istanbul ignore next */


var off = function () {
  if (!isServer && document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
}();
/**
 * 计算列宽
 */


function getColumnsWidth(columnsConfigArray) {
  var _bodyWidth = 0;
  var _cfgAry = columnsConfigArray;

  for (var _c = 0; _c < _cfgAry.length; _c++) {
    var _col = _cfgAry[_c];

    var _columnWidth = parseInt(_col.cWidth ? _col.cWidth.replace('px', '') : _col.width);

    _col.cWidth = _columnWidth + "px";
    _bodyWidth += _columnWidth;
  }

  return _bodyWidth;
}
/**
 * 计算默认列宽
 */


function getAndInitColumnsDefaultWidth(columnsConfig, tableWidth) {
  if (!columnsConfig) {
    return 0;
  }

  var _bodyWidth = 0;
  var _cfg = columnsConfig;

  for (var _c = 0; _c < _cfg.length; _c++) {
    var _col = _cfg[_c];

    if (!_col.defaultWidth) {
      _col.defaultWidth = parseInt(_col.cWidth ? _col.cWidth.replace('px', '') : _col.width ? _col.width : 0);

      if (0 == _col.defaultWidth || isNaN(_col.defaultWidth)) {
        _col.defaultWidth = _col.minWidth || 85; //默认60
      }

      _col.cWidth = _col.defaultWidth + "px";
    }

    _bodyWidth += _col.defaultWidth;
  }

  return _bodyWidth;
}