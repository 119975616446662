import Vue from "vue";
const Login = r=>require.ensure([],
() => require("./src/pages/account/loginMultOrgz"),
"Login"
);
; 
const Layout = r=>require.ensure([],
() => require("./src/components/layout/LayoutMGM"),
"Layout"
);
; 
const InvoiceOCRScanMGM = r=>require.ensure([],
() => require("./src/pages/OCRScan/InvoiceOCRScanMGM"),
"InvoiceOCRScanMGM"
);
; 
const Organization = r=>require.ensure([],
() => require("./src/pages/Organization/Organization"),
"Organization"
);
; 
const Department = r=>require.ensure([],
() => require("./src/pages/Organization/Department"),
"Department"
);
; 
const RoleView = r=>require.ensure([],
() => require("./src/pages/Organization/RoleView"),
"RoleView"
);
; 
const UserList = r=>require.ensure([],
() => require("./src/pages/Organization/UserManagement/UserList"),
"UserList"
);
; 
const InvOCRCountReport = r=>require.ensure([],
() => require("./src/pages/Report/InvOCRCountReport/InvOCRCountReport"),
"InvOCRCountReport"
);
;
export default{ routes:[{path:"/account/login",name:"Login",component:Login}, 
{path:"*",name:"Layout",component:Layout,
children:[
{path:"/0/0",name:"InvoiceOCRScanMGM",component:InvoiceOCRScanMGM}, 
{path:"/1/0",name:"Organization",component:Organization}, 
{path:"/1/1",name:"Department",component:Department}, 
{path:"/1/2",name:"RoleView",component:RoleView}, 
{path:"/1/3",name:"UserList",component:UserList}, 
{path:"/1/4",name:"InvOCRCountReport",component:InvOCRCountReport}
]}],menus:[{"Route":{"Name":"进项发票管理","Icon":"","Remark":"","Order":3},"Subs":[{"Name":"进项发票验收","AuthUnitToken":"InvoiceOCRScan","Icon":"","Remark":"","Url":"/0/0"}]},{"Route":{"Name":"组织结构","Icon":"","Remark":"","Order":12},"Subs":[{"Name":"公司管理","AuthUnitToken":"Organization","Icon":"","Remark":"","Url":"/1/0"},{"Name":"部门管理","AuthUnitToken":"Department","Icon":"","Remark":"","Url":"/1/1"},{"Name":"角色管理","AuthUnitToken":"Role","Icon":"","Remark":"","Url":"/1/2"},{"Name":"用户管理","AuthUnitToken":"User","Icon":"","Remark":"","Url":"/1/3"},{"Name":"发票识别查验次数统计","AuthUnitToken":"InvOCRCountReport","Icon":"","Remark":"","Url":"/1/4"}]}]};