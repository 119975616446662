var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      style: _vm.getHeaderTableStyle,
      attrs: { cellspacing: "0", cellpadding: "0", border: "0" }
    },
    [
      _c(
        "colgroup",
        _vm._l(_vm.columnsConfig, function(column, index) {
          return _c("col", { attrs: { width: _vm.getColumnWidth(column) } })
        }),
        0
      ),
      _vm._v(" "),
      _c("thead", [
        _c("tr", [
          _c(
            "th",
            {
              staticStyle: {
                "text-align": "center",
                "border-right-color": "darkgray",
                "border-right-width": "1px"
              },
              attrs: { colspan: _vm.subColumnCount }
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.mainTitle) + "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "th",
            {
              staticStyle: { "text-align": "center" },
              attrs: { colspan: _vm.mainColumnCount }
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.subTitle) + "\n            "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "tr",
          _vm._l(_vm.columnsConfig, function(column, index) {
            return _c(
              "th",
              {
                key: column[_vm.cIdKey],
                style: _vm.getColumnStyle(column),
                attrs: { columnKey: column[_vm.cIdKey], title: column.title }
              },
              [
                _c(
                  "div",
                  { staticClass: "ivu-table-cell" },
                  [
                    !column.renderHeader
                      ? _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(column.title) +
                              "\n                    "
                          )
                        ])
                      : _c("render-header", {
                          attrs: {
                            render: column.renderHeader,
                            column: column,
                            "column-index": index
                          }
                        })
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }