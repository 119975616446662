"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.assign");

require("core-js/modules/es6.number.constructor");

var _expand = _interopRequireDefault(require("./tableHelper/expand"));

var _constant = require("./tableHelper/constant");

var _TableUtils2 = _interopRequireDefault(require("../../../../../src/tool/TableUtils"));

var _default = {
  name: 'SingleTableHeader',
  components: {
    RenderHeader: _expand.default
  },
  props: {
    data: Array,
    fixedLeftWidth: Number,
    fixedRightWidth: Number,
    unFixedWidth: Number,
    viewportWidth: Number,
    columnsConfig: Array,
    height: Number,
    scrollSynclData: Object,
    headerClass: {
      type: String,
      default: 'c-table-header__default'
    }
  },
  data: function data() {
    return {
      cIdKey: _constant.ID_NAME,
      headerShowWidth: 0,
      SummaryObject: {},
      Ver: 0
    };
  },
  watch: {
    data: {
      handler: function handler(rowAry) {
        var _$this = this;

        var _colAry = _$this.columnsConfig;
        var _summaryAry = [];

        for (var _i = 0; _i < _colAry.length; _i++) {
          var _col = _colAry[_i];

          if (_col.SummaryType) {
            _summaryAry.push({
              FieldName: _col.key,
              SummaryType: _col.SummaryType,
              Fixed: 2,
              Formater: _col.Formater
            });
          }
        }

        if (0 == _summaryAry.length) {
          return;
        }

        var _sObj = _TableUtils2.default.TableSummays(rowAry, _summaryAry);

        Object.assign(_$this.SummaryObject, _sObj);
        _$this.Ver++;
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    getSummaryTitalStyle: function getSummaryTitalStyle() {
      return {
        "z-index": 999,
        "top": "0px",
        "left": "0px",
        "width": "45px",
        "position": "absolute",
        "background-color": "rgb(248, 248, 249)",
        "overflow": "hidden",
        "border-bottom": "0px none rgb(221, 221, 221)",
        'height': this.height + "px",
        'line-height': this.height + "px",
        "text-align": "center"
      };
    },
    getHeaderStyle: function getHeaderStyle() {
      //表体宽度
      var _bodyWidht = 0;
      var _cfg = this.columnsConfig;

      for (var _c = 0; _c < _cfg.length; _c++) {
        var _col = _cfg[_c];
        _bodyWidht += parseInt(_col.cWidth ? _col.cWidth.replace('px', '') : _col.width);
      }

      var _translateX = "translateX(" + -this.scrollSynclData.scrollLeft + "px)";

      return {
        'transform': _translateX,
        'height': this.height + "px",
        'width': _bodyWidht + "px"
      };
    },
    getHeaderWrapperStyle: function getHeaderWrapperStyle() {
      return {
        'margin-left': this.fixedLeftWidth + "px",
        'margin-right': this.fixedRightWidth + "px",
        'width': this.unFixedWidth + "px",
        'position': 'relative'
      };
    },
    getHeaderShowStyle: function getHeaderShowStyle() {
      //如果存在右冻结列需要减去滚动条宽度，用于拦住滚动后的列头
      var _$this = this;

      var _width = _$this.headerShowWidth;
      return {
        'width': _$this.fixedRightWidth > 0 && 0 != _width ? _width - 16 + "px" : 'inherit',
        'overflow': 'hidden'
      };
    }
  },
  methods: {
    getColumnStyle: function getColumnStyle(column) {
      return {
        width: column.cWidth,
        height: this.height + "px"
      };
    }
  },
  mounted: function mounted() {
    var _$this = this;

    _$this.$nextTick(function () {
      var _width = 0;
      var _tableWraper = _$this.$refs.tableHeader;

      if (_tableWraper) {
        _width = _tableWraper.clientWidth; //tableHeader

        if (0 == _width) {
          _width = _tableWraper.scrollWidth;
        }
      }

      _$this.headerShowWidth = _width;
    });
  }
};
exports.default = _default;