var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ivu-table-wrapper ivu-table-with-footer",
      staticStyle: {
        "border-left-width": "1px",
        "border-left-style": "solid",
        "border-left-color": "#dddddd",
        "border-top-width": "1px",
        "border-top-style": "solid",
        "border-top-color": "#dddddd"
      }
    },
    [
      _c(
        "div",
        {
          ref: "tableWrapper",
          staticClass: "ivu-table ivu-table-small ivu-table-border"
        },
        [
          _c(
            "div",
            { staticClass: "ivu-table-header", style: _vm.getHeaderStyle },
            [
              _c("table-header", {
                ref: "tableHeader",
                attrs: {
                  "main-column-count": _vm.getMainColumns.length,
                  "sub-column-count": _vm.getSubColumns.length + 1,
                  "main-title": _vm.mainTitle,
                  "sub-title": _vm.subTitle,
                  "header-class": _vm.headerClass,
                  "columns-config": _vm.getExpHeaderColumns,
                  height: _vm.headerHeight,
                  "un-fixed-width": _vm.getUnFixedWidth,
                  "viewport-width": _vm.tableWidth,
                  "scroll-syncl-data": _vm.scrollSynclData
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "virtualScrollBody",
              staticClass: "ivu-table-body",
              style: { "overflow-y": "scroll", height: _vm.tableHeight + "px" },
              on: {
                "&scroll": function($event) {
                  return _vm.onVirtualScroll($event)
                }
              }
            },
            [
              _c("table-body", {
                attrs: {
                  "table-owner": _vm.tableOwner,
                  "hidden-vertical-scroll": _vm.hiddenVerticalScroll,
                  data: _vm.data,
                  "record-key": _vm.recordKey,
                  "columns-config": _vm.getExpMainBodyColumnsConfig,
                  "un-fixed-width": _vm.getUnFixedWidth,
                  "item-height": _vm.recordHeight,
                  "viewport-height": _vm.bodyHeight,
                  "viewport-width": _vm.tableWidth,
                  "scroll-syncl-data": _vm.scrollSynclData,
                  "auto-reset-scroll": _vm.autoResetScroll
                }
              }),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                class: { "c-table-loading-shadow": _vm.loading },
                style: {
                  position: "absolute",
                  top: "0px",
                  width:
                    (_vm.tableWidth ? _vm.tableWidth : _vm.bodyWidth) + "px"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.bodyVisable
            ? _c(
                "div",
                {
                  staticClass: "ivu-table-tip",
                  staticStyle: { display: "none" }
                },
                [_vm._m(0)]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasFooterSlot()
            ? _c(
                "div",
                { staticClass: "ivu-table-footer" },
                [_vm._t("footer")],
                2
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "table",
      { attrs: { cellspacing: "0", cellpadding: "0", border: "0" } },
      [
        _c("tbody", [
          _c("tr", [
            _c("td", { staticStyle: { width: "1178px" } }, [
              _c("span", [_vm._v("暂无筛选结果")])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }