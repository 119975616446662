"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));

require("core-js/modules/es6.number.constructor");

var _vue = _interopRequireDefault(require("vue"));

var VmData =
/** @class */
function () {
  function VmData() {
    this.DataIndexArray = [];
    this.FocusIndex = -1;
    this.Searcher = SearchCtor();
  }

  return VmData;
}();

var VmProps =
/** @class */
function () {
  function VmProps() {
    this.value = Object;
    this.data = Array; //传入数据，数组类型

    this.keyField = String; ////数据唯一标志字段

    this.valueField = String; ////要显示的字段

    this.columns = Array; ////下拉表格要显示的列{title:string,key:string}

    this.filterMethod = null;
    this.pageCount = Number;
  }

  return VmProps;
}();

var _TableRowHeight = 22;

function VueComponentRender($this, h, params) {
  var _$this = $this;
  var _searcher = _$this.Searcher;
  var _vNodeOfTBody = null;

  var _visable = _searcher.IsSearching() || _$this.DataIndexArray.length > 0;

  return JsxProper(h, _visable);

  function JsxProper(h, visible) {
    var _paddingLeft = '0px';
    var _tableWidth = 35;

    for (var _i = 0, _iCnt = _$this.columns.length; _i < _iCnt; _i++) {
      var _col = _$this.columns[_i];
      _tableWidth += _col.Width;
    }

    return h("div", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      staticClass: "ivu-poptip",
      on: {}
    }]), [h("div", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      staticClass: "ivu-poptip-popper",
      style: visible ? 'background-color:#fff;' : 'display:none',
      attrs: {
        slot: "content"
      },
      slot: "content"
    }]), [h("table", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      attrs: {
        'cellspacing': "0",
        'cellpadding': "0",
        'border': "0",
        'width': _tableWidth + 'px'
      },
      style: {
        'padding-left': _paddingLeft
      }
    }]), [h("thead", [h("tr", [JsxTableHead(h)])])]), h("ul", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      staticClass: 'ivu-select-dropdown ivu-select-dropdown-list',
      style: {
        'margin-top': '0px',
        'padding-left': _paddingLeft,
        'padding-bottom': '6px',
        'max-height': _$this.pageCount * _TableRowHeight + 'px'
      },
      hook: {
        'create': function create(vNode0, vNode1) {
          _vNodeOfTBody = vNode1;
        }
      }
    }]), [h("table", {
      "attrs": {
        "cellspacing": "0",
        "cellpadding": "0",
        "border": "0"
      }
    }, [h("tbody", {
      "class": "ivu-table-tbody"
    }, [JsxTableBody(h)])])])])]);
  }

  function JsxTableHead(h) {
    var _ret = [h("th", {
      "style": "width:35px"
    }, ["#"])];

    for (var _i = 0, _iCnt = _$this.columns.length; _i < _iCnt; _i++) {
      var _col = _$this.columns[_i];

      _ret.push(h("th", (0, _babelHelperVueJsxMergeProps.default)([{}, {
        style: {
          'width': _col.Width + 'px'
        }
      }]), [h("p", {
        "class": "ivu-table-cell"
      }, [_col.title])]));
    } //_ret.push(<td style="width:21px"> </td>);


    return _ret;
  }

  function JsxTableBody(h) {
    var _ret = [];

    _$this.DataIndexArray.map(function (val, index) {
      _ret.push(h("tr", (0, _babelHelperVueJsxMergeProps.default)([{}, {
        style: {
          'height': _TableRowHeight + "px"
        },
        class: _$this.FocusIndex == index ? "ivu-table-row-hover" : "ivu-table-row",
        on: {
          mouseenter: function mouseenter(ev) {
            _$this.FocusIndex = index; //HandlerMouseIn(val, index)
          },
          click: function click(ev) {
            _$this.FocusIndex = index;
            console.log('row-click');

            _$this.$emit('row-click', index);
          }
        }
      }]), [JsxTableRow(h, _$this.data[val], index)]));
    });

    return _ret;
  }

  function JsxTableRow(h, val, index) {
    var _ret = [h("td", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      style: {
        'height': _TableRowHeight + "px",
        'background-color': '#f8f8f9',
        'width': '35px',
        'color': _$this.FocusIndex == index ? 'blue' : ''
      }
    }]), [h("p", [index + 1])])];

    for (var _i = 0; _i < _$this.columns.length; _i++) {
      _ret.push(h("td", (0, _babelHelperVueJsxMergeProps.default)([{}, {
        staticClass: 'ivu-table-column-center',
        //css样式传递，由于在表格中会继承到选中行的样式，所以改成styel设置样式
        style: {
          'height': _TableRowHeight + "px",
          'background-color': _$this.FocusIndex == index ? '#ebf7ff' : '#fff',
          'width': _$this.columns[_i].Width + 'px'
        }
      }]), [h("p", [val[_$this.columns[_i].key]])]));
    }

    return _ret;
  }

  function UpdatePoperScroll() {
    if (_vNodeOfTBody) {
      var _elm_1 = _vNodeOfTBody.elm;

      if (_elm_1) {
        _$this.$nextTick(function () {
          var _sTopOld = _elm_1.scrollTop;

          var _sTopCurMin = _TableRowHeight * (_$this.FocusIndex - (_$this.pageCount - 1));

          var _sTopCurMax = _TableRowHeight * _$this.FocusIndex;

          if (_sTopOld < _sTopCurMin) {
            _elm_1.scrollTop = _sTopCurMin;
          } else if (_sTopOld > _sTopCurMax) {
            _elm_1.scrollTop = _sTopCurMax;
          }
        });
      }
    }
  }
}

function SearchCtor() {
  var _loopHandler = null,
      _isSearching = false;

  var _dIndexAry;

  var _index = 0,
      _iCnt = 0;

  var _innerSearcher = ContinueSearchCtor();

  return {
    StopLooper: StopLooper,
    Search: Search,
    IsSearching: IsSearching
  }; //返回类对像
  //  判断是否正在查询

  function IsSearching() {
    return _isSearching || _innerSearcher.IsSearching();
  }

  function StopLooper() {
    _innerSearcher.StopLooper();

    if (_loopHandler) {
      clearTimeout(_loopHandler);
      _loopHandler = null;
    }

    _isSearching = false;
    
  }

  function Search(data, filter, isContinue) {
    StopLooper();

    if (isContinue) {
      return _innerSearcher.Search(_dIndexAry, data, filter, function () {
        _loopHandler = setTimeout(function () {
          doInnerLoop();
        }, 300);
      });
    }

    _isSearching = true;
    _dIndexAry = []; //新查询时生成新数组

    _index = 0, _iCnt = data.length;
    _loopHandler = setTimeout(function () {
      doInnerLoop();
    }, 300);
    return _dIndexAry;

    function doInnerLoop() {
      var _loopCnt = 0;
      var _indexAry = [];

      for (; _index < _iCnt; _index++) {
        var _dIndex = _index; //***

        if (filter(data[_dIndex])) {
          _indexAry.push(_dIndex);
        }

        _loopCnt++;

        if (_loopCnt >= 250) {
          _loopHandler = setTimeout(function () {
            doInnerLoop();
          }, 300);
          break;
        }
      }

      if (_indexAry.length > 0) {
        _dIndexAry.push.apply(_dIndexAry, _indexAry);
      }

      

      if (_index == _iCnt) {
        _isSearching = false;
      }
    }
  } /////************内部类


  function ContinueSearchCtor() {
    var _loopHandler = null,
        _isSearching = false;
    var _dIndexAry4Loop = null;
    var _index = 0,
        _iCnt = 0;
    return {
      StopLooper: StopLooper,
      Search: Search,
      IsSearching: IsSearching
    }; //返回类对像

    function IsSearching() {
      return _isSearching;
    }

    function StopLooper() {
      if (_loopHandler) {
        clearTimeout(_loopHandler);
        _loopHandler = null;
      }

      _isSearching = false;
      
    }

    function Search(dIndexAry, data, filter, cbOfComplete) {
      StopLooper();
      _isSearching = true; //之前未来查询完的元素

      var _dIndexAryOdd = _dIndexAry4Loop ? _dIndexAry4Loop.slice(_index) //返回数组中 >=_index 的元素
      : []; //b=a.splice(0) => a（清空）将数组元素->移到->b


      _dIndexAry4Loop = dIndexAry.splice(0).concat(_dIndexAryOdd);
      _index = 0, _iCnt = _dIndexAry4Loop.length;
      _loopHandler = setTimeout(function () {
        doInnerLoop();
      }, 300);
      return dIndexAry;

      function doInnerLoop() {
        
        var _loopCnt = 0;
        var _indexAry = [];

        for (; _index < _iCnt; _index++) {
          var _dIndex = _dIndexAry4Loop[_index]; //***

          if (filter(data[_dIndex])) {
            _indexAry.push(_dIndex);
          }

          _loopCnt++;

          if (_loopCnt >= 250) {
            _loopHandler = setTimeout(function () {
              doInnerLoop();
            }, 300);
            break;
          }
        }

        if (_indexAry.length > 0) {
          dIndexAry.push.apply(dIndexAry, _indexAry);
        }

        if (_index == _iCnt) {
          _isSearching = false;

          if (cbOfComplete) {
            cbOfComplete();
          }
        }
      }
    }
  }
}

var _default = _vue.default.extend({
  render: function render(h, params) {
    return VueComponentRender(this, h, params);
  },
  props: new VmProps(),
  data: function data() {
    return new VmData();
  },
  //使用jsx computed和methods可以一起写在闭包里
  //computed: VmProxyFactory(VmComputed),
  watch: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {
    var _$this = this;

    console.log('destroyed InnerVueCls');
    var _searcher = _$this.Searcher;

    if (_searcher) {
      _searcher.StopLooper();

      _$this.Searcher = null;
    } //hook: {
    //	'create': function (vNode0, vNode1)
    //	{
    //		_vNodeOfPopper = vNode1;
    //		UpPopper();
    //	},
    //	'destroy': function (vNode0)
    //	{
    //		if (_Popper)
    //		{
    //			_Popper.destroy();
    //			_Popper = null;
    //		}
    //	}
    //}

  },
  methods: {}
});

exports.default = _default;