import { render, staticRenderFns } from "./VirtualScrollTableFixed.vue?vue&type=template&id=6b35c5f0&"
import script from "./VirtualScrollTableFixed.vue?vue&type=script&lang=ts&"
export * from "./VirtualScrollTableFixed.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("L:\\Yodoo_Projects\\Yodoo.E-Invoice\\Yodoo.ATPiao.WebSpa\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('6b35c5f0', component.options)
    } else {
      api.reload('6b35c5f0', component.options)
    }
    module.hot.accept("./VirtualScrollTableFixed.vue?vue&type=template&id=6b35c5f0&", function () {
      api.rerender('6b35c5f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/vue-virtual-scroll-table/src/components/VirtualScrollTableFixed.vue"
export default component.exports