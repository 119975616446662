var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      ref: "virtualScrollBody",
      staticClass:
        "c-table-wrapper__body-wrapper c-table-body-wrapper__virtual",
      style: _vm.getTableWrapperStyle,
      on: {
        "&scroll": function($event) {
          return _vm.onVirtualScroll($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          style: {
            width:
              _vm.unFixedWidth + _vm.fixedLeftWidth + _vm.fixedRightWidth + "px"
          }
        },
        [
          _c(
            "div",
            { style: _vm.getBodyWrapperStyle },
            _vm._l(_vm.renderData, function(record, rIndex) {
              return _c(
                "div",
                {
                  key: _vm.recordKey ? record[_vm.recordKey] : rIndex,
                  staticClass:
                    "c-table-body-container c-table-body-container__virtual",
                  style: _vm.getRowWrapperStyle(record),
                  on: {
                    click: function($event) {
                      return _vm.handleRowClick(record)
                    },
                    dblclick: function($event) {
                      return _vm.handleRowDblClick(record)
                    },
                    mouseenter: function($event) {
                      $event.stopPropagation()
                      return _vm.handleMouseIn(record.__dataIndex)
                    },
                    mouseleave: function($event) {
                      $event.stopPropagation()
                      return _vm.handleMouseOut(record.__dataIndex)
                    }
                  }
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass: "c-table-body__record",
                      style: _vm.getRowContainerStyle(record)
                    },
                    _vm._l(_vm.columnsConfig, function(column, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "c-table-body-column",
                          style: _vm.getColumnStyle(column),
                          attrs: {
                            columnKey: column.key,
                            title: column.getRowTitle
                              ? column.getRowTitle(record)
                              : record[column.key]
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "c-table-body-column__container" },
                            [
                              !column.render
                                ? _c("span", [
                                    _vm._v(_vm._s(record[column.key]))
                                  ])
                                : _c("render-body", {
                                    key: column.key,
                                    attrs: {
                                      row: record,
                                      render: column.render,
                                      "column-index": index,
                                      column: column
                                    }
                                  })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }