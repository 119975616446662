"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.number.constructor");

var _expand = _interopRequireDefault(require("./tableHelper/expand"));

var _constant = require("./tableHelper/constant");

var _lodash = _interopRequireDefault(require("lodash"));

var _tableUtil = require("./tableHelper/tableUtil");

var VmProps =
/** @class */
function () {
  function VmProps() {
    //主表体组件this
    this.tableOwner = Object;
    this.hiddenVerticalScroll = Boolean;
    this.columnsConfig = Array;
    this.data = Array;
    this.recordKey = String;
    this.fixedLeftWidth = Number;
    this.fixedRightWidth = Number;
    this.unFixedWidth = Number;
    this.itemHeight = Number;
    this.viewportHeight = Number;
    this.viewportWidth = Number;
    this.scrollSynclData = Object;
    this.autoResetScroll = Boolean;
  }

  return VmProps;
}();

var VmWatch =
/** @class */
function () {
  function VmWatch() {
    this.data = {
      handler: function handler(val) {
        //"debug code"
        //console.log('VirtualScrollTableBody handler data');
        //"end debug code"
        var _$this = this;

        _$this.renderData.splice(0, _$this.renderData.length);

        _$this.virtualData = _lodash.default.cloneDeep(val);

        _$this.refreshRenderData();

        if (_$this.autoResetScroll) {
          var _virtualScrollBody_1 = _$this.$refs.virtualScrollBody;

          if (_virtualScrollBody_1) {
            _$this.scrollSynclData.hover_index = -1;

            _$this.$nextTick(function () {
              if (_virtualScrollBody_1.scrollTo) {
                _virtualScrollBody_1.scrollTo(0, 0);
              } else {
                //IE 下用这方法重置滚动条，能触发scroll事件
                _virtualScrollBody_1.scrollTop = 0;
              }

              console.log('_virtualScrollBody.scrollTo(0, 0)');
            });
          } //"debug code"
          //console.log('handler VirtualScrollTableBody autoResetScroll');
          //"end debug code"

        }
      },
      immediate: true,
      deep: false
    };
  }

  return VmWatch;
}();

var VmData =
/** @class */
function () {
  function VmData(vmThis) {
    this.virtualData = {};
    this.renderData = [];
    this.minItemKeyHeight = -1;
    this.maxItemKeyHeight = -1;

    var renderItems = Math.ceil(vmThis.viewportHeight / vmThis.itemHeight) + 2 * _constant.VIRTUAL_REMAIN_COUNT;

    this.remainHeight = _constant.VIRTUAL_REMAIN_COUNT * vmThis.itemHeight;
    this.renderItems = renderItems;
    this.renderItemsHeight = renderItems * vmThis.itemHeight;
  }

  return VmData;
}();

var VmComputed =
/** @class */
function () {
  function VmComputed() {
    this.getTableWrapperStyle = function () {
      return {
        'height': this.viewportHeight + "px",
        'width': this.viewportWidth + "px",
        'overflow-x': this.hiddenVerticalScroll ? 'hidden' : 'scroll' //hidden

      };
    };

    this.getRecordHeight = function () {
      return this.itemHeight + "px";
    };

    this.getBodyWrapperStyle = function () {
      return {
        'margin-left': this.fixedLeftWidth + "px",
        'margin-right': this.fixedRightWidth + "px",
        'height': this.data.length * this.itemHeight + "px",
        'width': this.unFixedWidth + "px",
        'position': 'relative'
      };
    };
  }

  return VmComputed;
}();

var VmMethods =
/** @class */
function () {
  function VmMethods() {
    this.handleRowClick = function (record) {
      var _$this = this;

      var _index = record.__dataIndex;
      _$this.scrollSynclData.clicked_index = _index;

      _$this.tableOwner.$emit('on-row-click', JSON.parse(JSON.stringify(record)), _index);
    };

    this.handleRowDblClick = function (record) {
      var _$this = this;

      var _index = record.__dataIndex;
      console.log('dblClick');

      _$this.tableOwner.$emit('on-row-dblclick', JSON.parse(JSON.stringify(record)), _index);
    };

    this.handleMouseIn = function (vkey) {
      this.scrollSynclData.hover_index = vkey;
    };

    this.handleMouseOut = function (vkey) {
      this.scrollSynclData.hover_index = -1;
    };

    this.getColumnStyle = function (column) {
      return {
        width: column.cWidth,
        height: this.itemHeight + "px"
      };
    };

    this.buildRenderData = function (minHeight, maxHeight) {
      var _$this = this;

      var _minItemKey = minHeight / _$this.itemHeight;

      var _maxItemKey = maxHeight / _$this.itemHeight;

      var _startIndex = _minItemKey > 0 ? _minItemKey : -1;

      var _endIndex = _maxItemKey > _$this.virtualData.length ? _$this.data.length : _maxItemKey;

      var _renderData = [];

      for (var _index = _startIndex + 1; _index < _endIndex; _index++) {
        var _item = _$this.virtualData[_index];

        var _recordIndexHight = "" + _index * _$this.itemHeight;

        _item.__dataIndex = _index;
        _item.translateY = _recordIndexHight + "px";

        _renderData.push(_item);
      }

      return _renderData;
    };

    this.getRowWrapperStyle = function (record) {
      var _$this = this;

      var _isChecked = _$this.scrollSynclData.clicked_index == record.__dataIndex;

      ;

      var _isHover = _isChecked || _$this.scrollSynclData.hover_index == record.__dataIndex;

      var _color = _isHover ? "#ebf7ff" : "";

      return {
        'transform': "translateY(" + record.translateY + ")",
        'box-shadow': _isHover ? '0px 2px 6px -2px rgba(0,0,0,.2)' : '',
        'height': _$this.itemHeight + "px",
        'background-color': _color,
        'margin-top': _isHover ? "-1px" : "",
        'border-top': _isHover ? "1px solid #dddddd" : "" //背景会挡住上一行的下边框显示

      };
    };

    this.getRowContainerStyle = function (record) {
      var _$this = this;

      var _isChecked = _$this.scrollSynclData.clicked_index == record.__dataIndex;

      ;

      var _isHover = _isChecked || _$this.scrollSynclData.hover_index == record.__dataIndex;

      return {
        'box-shadow': _isHover ? '0px 2px 6px 0px rgba(0,0,0,.2)' : '',
        'height': _$this.getRecordHeight
      };
    };

    this.buildNewItems = function (newData) {
      var _newItems = [];

      for (var _i = 0, newData_1 = newData; _i < newData_1.length; _i++) {
        var _newRecord = newData_1[_i];

        if (_lodash.default.findIndex(this.renderData, {
          __dataIndex: _newRecord.__dataIndex
        }) < 0) {
          _newItems.push(_newRecord);
        }
      }

      return _newItems;
    };

    this.buildOutDateItems = function (newData) {
      var _$this = this;

      var _replaceItemsIndex = [];

      for (var _index = 0; _index < _$this.renderData.length; _index++) {
        var _record = _$this.renderData[_index];

        if (_lodash.default.findIndex(newData, {
          __dataIndex: _record.__dataIndex
        }) < 0) {
          _replaceItemsIndex.push(_index);
        }
      }

      return _replaceItemsIndex;
    };

    this.refreshVirtualItems = function (newItems, replaceItemsIndex) {
      var _$this = this;

      if (newItems.length === _$this.renderData.length) {
        _$this.renderData = newItems;
        return;
      } //"debug code"
      //console.log('refreshVirtualItems', replaceItemsIndex, newItems)
      //"end debug code"


      for (var index = 0; index < newItems.length; index++) {
        if (index < replaceItemsIndex.length) {
          _$this.$set(_$this.renderData, replaceItemsIndex[index], newItems[index]);

          continue;
        }

        _$this.renderData.push(newItems[index]);
      }
    };

    this.updateRenderData = function (newData) {
      var _$this = this;

      var _newItems = [];
      var _replaceItemsIndex = [];

      if (_$this.renderData.length === 0) {
        _$this.renderData = newData;
      } else {
        _newItems = _$this.buildNewItems(newData);
        _replaceItemsIndex = _$this.buildOutDateItems(newData);

        _$this.refreshVirtualItems(_newItems, _replaceItemsIndex);
      }

      _$this.scrollSynclData.virtualItems = {
        renderData: _$this.renderData
      };
    };

    this.refreshRenderData = function () {
      var _$this = this;

      var _virtualScrollBody = _$this.$refs.virtualScrollBody;

      var _scrollTop = _virtualScrollBody ? _virtualScrollBody.scrollTop : 0;

      var _scrollLeft = _virtualScrollBody ? _virtualScrollBody.scrollLeft : 0; //console.log('_scrollTop=', _scrollTop);


      var _a = (0, _tableUtil.calDomItemsHeight)(_$this.itemHeight, _$this.remainHeight, _$this.viewportHeight, _$this.renderItemsHeight, _scrollTop),
          _minItemHeight = _a[0],
          _maxItemHeight = _a[1];

      _$this.updateRenderData(_$this.buildRenderData(_minItemHeight, _maxItemHeight));

      _$this.scrollSynclData.scrollTop = _scrollTop;
      _$this.scrollSynclData.scrollLeft = _scrollLeft;
      _$this.scrollSynclData.scrollbarWidth = _virtualScrollBody //https://www.cnblogs.com/panjun-Donet/articles/1294033.html
      ? _virtualScrollBody.offsetWidth - _virtualScrollBody.clientWidth - 2 * _virtualScrollBody.clientLeft : 16;
      _$this.scrollSynclData.offsetWidth = _virtualScrollBody ? _virtualScrollBody.offsetWidth : _$this.viewportWidth + 2;
    };

    this.onVirtualScroll = function (e) {
      var _$this = this;

      window.requestAnimationFrame(_$this.refreshRenderData); //if (!_$this.throttleVirtualScroll)
      //{
      //    _$this.throttleVirtualScroll = throttle(
      //        () =>
      //        {
      //            console.log(+new Date());
      //            _$this.refreshRenderData();
      //        },
      //        125
      //    );
      //}
      //_$this.throttleVirtualScroll();
    };
  }

  VmMethods.prototype.setVirtualScrollToY = function (y) {
    var _$this = this;

    var _virtualScrollBody = _$this.$refs.virtualScrollBody;

    if (_virtualScrollBody) {
      _$this.$nextTick(function () {
        if (_virtualScrollBody.scrollTo) {
          _virtualScrollBody.scrollTo(0, y);
        } else {
          //IE 下用这方法重置滚动条，能触发scroll事件
          _virtualScrollBody.scrollTop = y;
        } //console.log(' _virtualScrollBody.scrollTop=', _virtualScrollBody.scrollTop, y);

      });
    }
  };

  return VmMethods;
}();

var _default = {
  name: 'VirtualScrollTableBody',
  components: {
    RenderBody: _expand.default
  },
  props: new VmProps(),
  watch: new VmWatch(),
  data: function data() {
    return new VmData(this);
  },
  computed: new VmComputed(),
  methods: new VmMethods()
}; ///**
// * 节流函数
// */
//function throttle(fn, delay)
//{
//    let _timer = null
//    let _previous = null
//    return function ()
//    {
//        let _now = +new Date()
//        if (null == _previous)
//        {
//            _previous = _now;
//            fn();
//        }
//        else
//        {
//            let _delay = _now - _previous;
//            console.log(_delay);
//            if (_delay < delay)
//            {
//                _timer = setTimeout(
//                    function ()
//                    {
//                        _previous = _now;
//                        fn();
//                        _timer = null;
//                    },
//                    delay - _delay
//                );
//            }
//            else
//            {
//                _previous = _now;
//                fn();
//            }
//        }
//    }
//}

exports.default = _default;