"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _VirtualScrollTableFixedHeader = _interopRequireDefault(require("./VirtualScrollTableFixedHeader"));

var _VirtualScrollTableFixedBody = _interopRequireDefault(require("./VirtualScrollTableFixedBody"));

var _default = {
  name: 'VirtualScrollTableFixed',
  components: {
    VirtualScrollTableFixedHeader: _VirtualScrollTableFixedHeader.default,
    VirtualScrollTableFixedBody: _VirtualScrollTableFixedBody.default
  },
  props: {
    //主表体组件this
    tableOwner: Object,
    headerClass: String,
    tableFiexedClass: String,
    fixedStyle: Object,
    columnsConfig: Array,
    virtualItems: Object,
    recordKey: String,
    itemHeight: Number,
    headerHeight: Number,
    bodyHeight: Number,
    fixedHeight: Number,
    fixedWidth: Number,
    scrollSynclData: Object,
    fixedRight: Boolean,
    hiddenVerticalScroll: Boolean,
    //是否启用选中样式
    enableSelectStyle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getTableFixeStyle: function getTableFixeStyle() {
      //固定列样式
      var _$this = this;

      if (_$this.fixedRight) {
        return {
          width: _$this.fixedWidth + "px",
          "border-right": "1px none #dddddd",
          "border-bottom": "1px none #dddddd"
        };
      }

      return {
        width: _$this.fixedWidth + "px",
        "border-left": "1px none #dddddd",
        "border-bottom": "1px none #dddddd"
      };
    }
  },
  data: function data() {
    return {};
  }
};
exports.default = _default;