var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible"
              },
              { name: "transfer-dom", rawName: "v-transfer-dom" }
            ],
            ref: "popper",
            class: _vm.popperClasses,
            style: _vm.styles,
            attrs: { "data-transfer": _vm.transfer },
            on: {
              click: _vm.handleTransferClick,
              mouseenter: _vm.handleMouseenter,
              mouseleave: _vm.handleMouseleave
            }
          },
          [
            _c(
              "div",
              {
                ref: "popperContent",
                class: [_vm.prefixCls + "-content"],
                style: _vm.contentStyle
              },
              [
                _c("div", {
                  class: [_vm.prefixCls + "-arrow"],
                  style: _vm.arrowStyle
                }),
                _vm._v(" "),
                _vm.confirm
                  ? _c("div", { class: [_vm.prefixCls + "-inner"] }, [
                      _c("div", { class: [_vm.prefixCls + "-body"] }, [
                        _c("i", {
                          staticClass: "ivu-icon ivu-icon-ios-help-circle"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: [_vm.prefixCls + "-body-message"] },
                          [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: [_vm.prefixCls + "-footer"] },
                        [
                          _c(
                            "i-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.cancel($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.localeCancelText))]
                          ),
                          _vm._v(" "),
                          _c(
                            "i-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.ok($event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.localeOkText))]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.confirm
                  ? _c("div", { class: [_vm.prefixCls + "-inner"] }, [
                      _vm.showTitle
                        ? _c(
                            "div",
                            {
                              ref: "title",
                              class: [_vm.prefixCls + "-title"],
                              style: _vm.contentPaddingStyle
                            },
                            [
                              _vm._t("title", [
                                _c(
                                  "div",
                                  { class: [_vm.prefixCls + "-title-inner"] },
                                  [_vm._v(_vm._s(_vm.title))]
                                )
                              ])
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [_vm.prefixCls + "-body"],
                          style: _vm.contentPaddingStyle
                        },
                        [
                          _c(
                            "div",
                            { class: _vm.contentClasses },
                            [
                              _vm._t("content", [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.prefixCls + "-body-content-inner"
                                    ]
                                  },
                                  [_vm._v(_vm._s(_vm.content))]
                                )
                              ])
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }