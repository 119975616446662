var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      style: _vm.getHeaderTableStyle,
      attrs: { cellspacing: "0", cellpadding: "0", border: "0" }
    },
    [
      _c(
        "colgroup",
        _vm._l(_vm.columnsConfig, function(column, cIndex) {
          return _c("col", { attrs: { width: _vm.getColumnWidth(column) } })
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "tbody",
        { staticClass: "ivu-table-tbody" },
        _vm._l(_vm.data, function(record, rIndex) {
          return _c(
            "tr",
            { staticClass: "ivu-table-row" },
            _vm._l(_vm.columnsConfig, function(column, cIndex) {
              return _c(
                "td",
                {
                  style:
                    _vm.showLeftBorder && cIndex == _vm.columnsConfig.length - 1
                      ? "border-right-color:darkgray;border-right-width:1px"
                      : "",
                  attrs: { title: record[column.key] }
                },
                [
                  _c(
                    "div",
                    { staticClass: "union-table-cell" },
                    [
                      !column.render
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(record[column.key]) +
                                "\n                    "
                            )
                          ])
                        : _c("render-body", {
                            key: column.key,
                            attrs: {
                              row: record,
                              index: rIndex,
                              render: column.render,
                              "column-index": cIndex,
                              column: column
                            }
                          })
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }