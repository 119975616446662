"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _VirtualScrollTableBody = _interopRequireDefault(require("./VirtualScrollTableBody.Vm"));

var _default = _VirtualScrollTableBody.default;
exports.default = _default;