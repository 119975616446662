"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.starts-with");

var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));

require("core-js/modules/es6.number.constructor");

var _HTMLElementUtils = require("../../tool/HTMLElementUtils");

var _popper2 = _interopRequireDefault(require("popper.js/dist/umd/popper.js"));

var _Popper = _interopRequireDefault(require("./Popper"));

var _GlobalElement = document.createElement('div');

document.body.appendChild(_GlobalElement);

var VmData =
/** @class */
function () {
  function VmData() {
    this.DataIndexArray = [];
    this.ComponentInstance = null;
    this.PopperInstance = null;
    this.PrevIndex = -1;
    this.ValueClone = "";
  }

  return VmData;
}();

var VmProps =
/** @class */
function () {
  function VmProps() {
    this.size = String;
    this.value = String;
    this.data = Array; //传入数据，数组类型

    this.keyField = String; ////数据唯一标志字段

    this.valueField = String; ////要显示的字段

    this.columns = Array; ////下拉表格要显示的列{title:string,key:string}

    this.filterMethod = null;
    this.pageCount = Number;
  }

  return VmProps;
}();

var VmWatch =
/** @class */
function () {
  function VmWatch() {
    this.value = {
      handler: function handler(val) {
        this.ValueClone = val;
      },
      immediate: true
    };
  }

  return VmWatch;
}();

var _default = {
  render: function render(h, params) {
    var _$this = this;

    var _componentInstance = _$this.ComponentInstance;
    var _prvInputText = "";
    var _searcher = null;

    if (_componentInstance) {
      _searcher = _componentInstance.Searcher;
      _componentInstance.DataIndexArray = _$this.DataIndexArray;
    }

    var _popper = _$this.PopperInstance;
    var _elementInput = _$this.$refs.InputRef;

    if (null != _popper) {
      _popper.reference = _elementInput;

      _popper.scheduleUpdate();

      _popper.update();
    }

    if (null != _elementInput) {
      _elementInput.value = _$this.ValueClone;
    }

    return h("div", {
      "class": "ivu-poptip"
    }, [h("input", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      ref: "InputRef",
      staticClass: 'ivu-input ' + (_$this.size == 'small' ? 'ivu-input-small' : ''),
      attrs: {
        'icon': "arrow-down-b",
        'value': _$this.value
      },
      on: {
        'input': HandlerInput,
        //'click': HandlerInputClick,
        'blur': HandlerBlur,
        'keydown': HandlerInputKeydown
      },
      nativeOn: {}
    }]))]);

    function PoperCanVisible() {
      return _searcher.IsSearching() || _$this.DataIndexArray.length > 0;
    }

    function HandlerInput(event //InputEvent 
    ) {
      var _value = event.target.value;
      
      _$this.ValueClone = _value;

      _$this.$emit('input', _value);

      _$this.$nextTick(function () {
        _$this.DataIndexArray = GenerDataIndexArray(_value);
      });
    }

    ;

    function HandlerInputClick(event) {
      
      _$this.DataIndexArray = GenerDataIndexArray(_$this.ValueClone);
      var _elementInput = _$this.$refs.InputRef;

      _elementInput.focus();
    }

    function HandlerBlur(event //FocusEvent 
    ) {
      

      _searcher.StopLooper();

      SetInputText();
      SetFocusIndex(-1);
      _$this.DataIndexArray = [];
    }

    function HandlerInputKeydown(event) {
      

      var _focIndex = GetFocusIndex();

      if (40 == event.keyCode) //下
        {
          if (!PoperCanVisible()) {
            _$this.DataIndexArray = GenerDataIndexArray(_$this.ValueClone);
          }

          if (_focIndex + 1 < _$this.DataIndexArray.length) {
            IncFocusIndex(1);
          }

           //UpdatePoperScroll();

          return (0, _HTMLElementUtils.StopEvent)(event);
        }

      if (38 == event.keyCode) //上
        {
          if (!PoperCanVisible()) {
            _$this.DataIndexArray = GenerDataIndexArray(_$this.ValueClone);
          }

          if (_focIndex > 0) {
            IncFocusIndex(-1);
          } else {
            SetFocusIndex(0);
          } //UpdatePoperScroll();


          return (0, _HTMLElementUtils.StopEvent)(event);
        }

      

      if (13 == event.keyCode //回车
      && 0 != _$this.DataIndexArray.length) {
        SetInputText();
        return (0, _HTMLElementUtils.StopEvent)(event);
      }

      return true;
    }

    ;

    function GenerDataIndexArray(txt) {
      var _dIndexAry = []; //continueSearch ?: boolean

      if (!txt || (txt = txt.trim().toUpperCase()).length == 0) {
        SetFocusIndex(-1);

        _searcher.StopLooper();

        return [];
      }

      if (_prvInputText === txt && PoperCanVisible()) {
        return _dIndexAry;
      }

      _searcher.StopLooper();

      var _filter = function _filter(dObj) {
        var result = true;

        if (_$this.filterMethod) {
          result = _$this.filterMethod(txt, dObj);
        } else {
          result = dObj[_$this.keyField].indexOf(txt) >= 0;
        }

        return result;
      };

      var _data = _$this.data;

      var _continueSearch = _prvInputText && txt.startsWith(_prvInputText);

      _prvInputText = txt;
      _dIndexAry = _searcher.Search(_data, _filter, _continueSearch);

      if (_dIndexAry.length == 0) {
        SetFocusIndex(-1);
      }

      return _dIndexAry;
    }

    ;

    function SetInputText() {
      var _focIndex = GetFocusIndex();

      _elementInput = _$this.$refs.InputRef;

      if (_elementInput.value == _$this.value && _$this.PrevIndex == _focIndex) {
        
        return;
      } else {
        
      }

      var _dObj = null;
      var _value = _elementInput.value;

      if (_focIndex >= 0) {
        _$this.PrevIndex = _focIndex;
        var _index = _$this.DataIndexArray[_focIndex];
        _dObj = _$this.data[_index];
        _value = _dObj[_$this.keyField];
      }

      
      _$this.ValueClone = _value;

      _$this.$emit('input', _value, _dObj);

      _$this.PrevIndex = -1;
      SetFocusIndex(-1);
      _$this.DataIndexArray = []; //_elementInput.value = _$this.ValueClone;
      //_elementInput.focus();
    }

    ;

    function SetFocusIndex(index) {
      if (_componentInstance) {
        _componentInstance.HoverIndex = index;
        _componentInstance.FocusIndex = index;
      }
    }

    function GetFocusIndex() {
      if (_componentInstance) {
        return _componentInstance.FocusIndex;
      }

      return -1;
    }

    function IncFocusIndex(val) {
      if (_componentInstance) {
        _componentInstance.FocusIndex += val;
      }
    }
  },
  props: new VmProps(),
  data: function data() {
    return new VmData();
  },
  watch: new VmWatch(),
  mounted: function mounted() {
    var _$this = this;

    if (null == _$this.ComponentInstance) {
      var _componentInstance = new _Popper.default({
        propsData: {
          data: _$this.data,
          columns: _$this.columns,
          pageCount: 20
        }
      });

      _componentInstance.$on('row-click', function () {
        console.log('row-click', arguments);
      });

      _componentInstance.$mount();

      _GlobalElement.appendChild(_componentInstance.$el);

      _$this.ComponentInstance = _componentInstance;
      var _popper_1 = _$this.PopperInstance;

      if (null == _popper_1) {
        _popper_1 = new _popper2.default(_$this.$el, //rel
        _componentInstance.$el, //显示的元素必需要有正确的长宽，不然定位会有问题
        {
          placement: "bottom-start",
          modifiers: {
            flip: {
              behavior: ['left', 'bottom', 'top']
            },
            computeStyle: {
              gpuAcceleration: false
            },
            preventOverflow: {
              boundariesElement: 'window' //boundariesElement: 'container',

            }
          },
          onCreate: function onCreate() {
            _$this.$nextTick(function () {
              _popper_1 && _popper_1.update();
            });
          },
          onUpdate: function onUpdate() {}
        });
        _$this.PopperInstance = _popper_1;
      } else {
        _popper_1.reference = _$this.$el;

        _popper_1.scheduleUpdate();

        _popper_1.update();
      }
    }
  },
  destroyed: function destroyed() {
    var _$this = this;

    console.log('top destroyed d');
    var _componentInstance = _$this.ComponentInstance;

    if (_componentInstance) {
      var _el = _componentInstance.$el;

      if (_el.remove) {
        _el.remove();
      } else if (_el.parentNode && _el.parentNode.removeChild) {
        _el.parentNode.removeChild(_el);
      }

      _componentInstance.$destroy();
    }

    var _popper = _$this.PopperInstance;

    if (_popper) {
      _popper.destroy();

      _$this.PopperInstance = null;
    } //hook: {
    //	'create': function (vNode0, vNode1)
    //	{
    //		_vNodeOfPopper = vNode1;
    //		UpPopper();
    //	},
    //	'destroy': function (vNode0)
    //	{
    //		if (_Popper)
    //		{
    //			_Popper.destroy();
    //			_Popper = null;
    //		}
    //	}
    //}

  },
  methods: {}
};
exports.default = _default;