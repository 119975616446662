"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.assign");

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

var _loadVue = _interopRequireDefault(require("./loadVue"));

var _AppSettings2 = _interopRequireDefault(require("AppSettings"));

var _AuthUtils = _interopRequireDefault(require("../src/tool/AuthUtils"));

var _ApiUtils = _interopRequireDefault(require("../src/tool/ApiUtils"));

var _TableUtils = _interopRequireDefault(require("../src/tool/TableUtils"));

require("view-design/dist/styles/iview.css");

require("./assets/css/main.css");

require("./assets/css/self.css");

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _App = _interopRequireDefault(require("./App.vue"));

///<reference path='../types/AppSettings.d.ts' />
var _Vue = _loadVue.default;
var _Host = _AppSettings2.default.Host,
    _ImageRoot = _AppSettings2.default.ImageRoot,
    _LoginPath = _AppSettings2.default.LoginPath,
    _LogoutPath = _AppSettings2.default.LogoutPath,
    _RefresPath = _AppSettings2.default.RefresPath,
    _VerifyAuthUnitTokenPath = _AppSettings2.default.VerifyAuthUnitTokenPath,
    _UserInfoLocalKey = _AppSettings2.default.UserStorageKey; //  引入VueRouter

_Vue.use(_vueRouter.default); //路由配置


var _RouteOptions = {
  //mode: 'history',
  routes: []
}; // 创建路由实例

var _Router = new _vueRouter.default(_RouteOptions);

(function () {
  //增加 addRoutes 回调函数
  var _cb = null;

  _Router.afterAddRoutes = function (cb) {
    _cb = cb;
  };

  var _addRoutesHandler = _Router.addRoutes;

  _Router.addRoutes = function () {
    _addRoutesHandler.apply(this, arguments);

    _cb && _cb();
  };
})(); //页面跳转验证


var _EndaleScrollY = false; //单点登录

function ATPiaoAuthLogin() {
  var _searchHashViewName = "";
  var _searchHashAtpiaoBearer = "";

  if (_Router.mode === "hash") {
    //http://localhost:8088?ViewName=InvoiceOCRScan&atpiao_bearer=eyJJc3N1ZXIiOiJkeXRtZ20iLCJVc2VyIjoiVGVzdFVzZXIiLCJFeHBpcmUiOjYzNjg5NTA1NjcwNTM0MDU2OCwiVG9rZW4iOiI1QzYwQTBGN0I1NDZDODhBQUUwRjlDNkU1NjI5RUY3QTQ1QzJGNDgzQkZENjFFMjhBQjNGRkRFREU4RENGN0RGIn0=
    //http://localhost:8088?ViewName=Organization&atpiao_bearer=eyJJc3N1ZXIiOiJkeXRtZ20iLCJVc2VyIjoiVGVzdFVzZXIiLCJFeHBpcmUiOjYzNjg5NTA1NjcwNTM0MDU2OCwiVG9rZW4iOiI1QzYwQTBGN0I1NDZDODhBQUUwRjlDNkU1NjI5RUY3QTQ1QzJGNDgzQkZENjFFMjhBQjNGRkRFREU4RENGN0RGIn0=
    //hash模式 通过?ViewName=InvoiceOCRScan访问网站跳转至对应页面
    var _searchDict = _AuthUtils.default.DecodeWindowSearch();

    if (_searchDict["atpiao_bearer"]) {
      _searchHashAtpiaoBearer = _searchDict["atpiao_bearer"];
    }

    if (_searchDict["ViewName"]) {
      _searchHashViewName = _searchDict["ViewName"];
    }

    if (_searchHashAtpiaoBearer) {
      var _urlOfATPiao = _ApiUtils.default.Account.ATPiaoLogin.ApiUrl + "?atpiao_bearer=" + _searchHashAtpiaoBearer;

      

      _Vue.http.post(_urlOfATPiao, {
        authUnitCodeArray: [_searchHashViewName]
      }, {
        emulateJSON: true
      }).then(function (ret) {
        console.log(ret);

        if (ret.body && ret.body.Code == 1) {
          //登入成功
          var _userInfo = ret.body.Data;
          
          _userInfo.expireTime = new Date().getTime() + 20 * 60 * 1000; //登入时间是20分钟

          localStorage.setItem(_AppSettings2.default.UserStorageKey, JSON.stringify(_userInfo));
          var _winLoca = window.location;

          var _webRootUrl = _winLoca.protocol + "//" + _winLoca.host + _winLoca.pathname;

          _webRootUrl = _webRootUrl + "?ViewName=" + _searchHashViewName;
          console.log(_webRootUrl);
          window.location.href = _webRootUrl;
          return;
        }

        localStorage.clear();

        _Router.push(_LoginPath);

        return;
      });

      return true;
    }
  }

  return false;
}

function CheckUserIsLogin(cb) {
  var _data = JSON.parse(localStorage.getItem(_UserInfoLocalKey));

  if (!_data || !_data.expireTime) {
    return cb && cb(false);
  }

  var _inExpireTime = _data.expireTime > new Date().getTime();

  if (_inExpireTime) {
    RunCallBack(true);
  } else {
     //超时刷新登录Cookie

    _Vue.http.post("" + _Host + _RefresPath).then(function (res) {
       //成功=>更新登入过期时间

      UpdateLoginExpireTime();
      RunCallBack(200 == res.status && res.body && 1 == res.body.Code);
    }, function (ex) {
      
      RunCallBack(false);
    });
  }

  function RunCallBack(ok) {
    if (!ok) {
      //失败=>清除登录信息
      localStorage.clear();
    }

    cb && cb(ok);
  }

  function UpdateLoginExpireTime() {
    var _data = JSON.parse(localStorage.getItem(_UserInfoLocalKey));

    _data.expireTime = new Date().getTime() + 20 * 60 * 1000;
    localStorage.setItem(_UserInfoLocalKey, JSON.stringify(_data));
  }
}

var _FirstRun = true; //  注册VueRouter的全局前置守卫

_Router.beforeEach(function (to, from, next) {
  _LoadingBar.start();

  

  if (ATPiaoAuthLogin()) {
    return;
  }

  var _menuLv2 = _AppSettings2.default.FindLv2Menu(to.path || "");

  _EndaleScrollY = false;
  CheckUserIsLogin(NextRoute);

  function NextRoute(userIsLogin) {
    if (userIsLogin) {
      
      _EndaleScrollY = true; //如果

      if (to.path == _LoginPath) {
        next({
          path: '/'
        });
      } else {
        if (!_FirstRun && _menuLv2 && !_menuLv2.ForGuest) {
          _Vue.http.headers.common = {
            AuthUnitToken: _menuLv2.AuthUnitToken
          }; //超时刷新登录Cookie

          _Vue.http.post("" + _Host + _VerifyAuthUnitTokenPath, {
            authUnitToken: _menuLv2.AuthUnitToken
          }, {
            DisableSuccessMessage: true,
            emulateJSON: true
          }).then(function (result) {
            

            var _result = result.body;

            if (_result.Code == 1) {
              next();
            }
          });
        } else {
          next();
        } //next();

      }
    } else {
      //如果用户没登入
      if (to.path == _LoginPath) {
        next();
      } else {
        
        next({
          path: _LoginPath,
          query: {
            redirect: to.fullPath // 将跳转的路由path作为参数，登录成功后跳转到该路由

          }
        });
      }
    }
  }
});

_Router.afterEach(function (to, from) {
  _LoadingBar.finish();

  _FirstRun = false;
  

  if (_EndaleScrollY) {
    _Vue.prototype.$nextTick(function () {
       //强制显示滚动条
      //IE滚动条不显示，需要加: -ms-overflow-style: scrollbar; overflow - y: auto

      document.body.setAttribute("style", "-ms-overflow-style: scrollbar;overflow-y:scroll");
    });
  }
});

var _LoadingBar = _Vue.prototype.$LoadingBar;
var _Message = _Vue.prototype.$Message;
var _Notice = _Vue.prototype.$Notice; //http请求拦截

_Vue.http.interceptors.push(function (request, next) {
  var _disableSucMsg = true === request.DisableSuccessMessage;

  request.credentials = true;

  var _reqUrl = request.url.toLowerCase();

  console.log('interceptors   ' + _reqUrl);

  if (localStorage.getItem(_UserInfoLocalKey) //登录才检查
  && _reqUrl.indexOf(_RefresPath.toLowerCase()) < 0 && _reqUrl.indexOf(_LoginPath.toLowerCase()) < 0 && _reqUrl.indexOf(_LogoutPath.toLowerCase()) < 0 && _reqUrl.indexOf(_VerifyAuthUnitTokenPath.toLowerCase()) < 0 && _reqUrl.indexOf(_Host) == 0) {
    _LoadingBar.start(); //在用户登入状态下,若操作的不是登陆接口,就会进行过期时检查


    CheckUserIsLogin(RunNext);
  } else {
    RunNext(true);
  }

  function RunNext(userIsLogin) {
    if (!userIsLogin) {
      

      _Message.error("用户登录超时！");

      _Router.push(_LoginPath);

      return;
    }

    request.url += (_reqUrl.indexOf('?') > 0 ? '&' : '?') + ("cb=" + new Date().getTime());
    next(function (response) {
      var _status = response.status;

      if (_status == 200) {
        var _body = response.body;

        if (_body && _body.Code == 0) {
          

          _LoadingBar.error(); //_body.Message && _Message.error(_body.Message);


          _body.Message && _Notice.error({
            desc: StringEncode(_body.Message)
          });
        } else if (_body && _body.Code == 1 || _body.Code == 200) {
          _LoadingBar.finish();

          !_disableSucMsg && _body.Message && _body.Message != "图片获取成功" && _Message.success(_body.Message);
          return response;
        } else {
          
          localStorage.clear();

          _LoadingBar.finish();

          _Router.push(_LoginPath);
        }

        _LoadingBar.finish();
      } else if (_status == 401) //401 Unauthorized
        {
          
          localStorage.clear();

          _LoadingBar.finish();

          var _searchDict = _AuthUtils.default.DecodeWindowSearch();

          if (!!!_searchDict["atpiao_bearer"]) {
            //不是单点登录
            _Router.push(_LoginPath);
          }
        } else if (_status == 404) {
        

        _Message.error("功能还未开发！");

        _LoadingBar.finish();
      } else {
        _LoadingBar.finish();

        return response;
      }
    });
  }
}); //XSS转义


function StringEncode(str) {
  var div = document.createElement('div');

  if (div.innerText) {
    div.innerText = str;
  } else {
    div.textContent = str;
  }

  var _str = div.innerHTML;
  div.remove();
  return _str;
} //获取图片URl


Object.defineProperty(_Vue.prototype, "GetImageRemotePath", {
  value: function value(path) {
    if (!!!path) {
      return "";
    }

    if (path.indexOf('data:image/png;base64') >= 0 || path.indexOf('http') >= 0) {
      return path;
    }

    return _ImageRoot + path;
  }
}); //导入Excel文件格式检验

Object.defineProperty(_Vue.prototype, "HandleExcelFormatError", {
  value: function value(file) {
    _Notice.warning({
      title: '文件格式不正确',
      desc: "\u6587\u4EF6 [" + file.name + "] \u683C\u5F0F\u4E0D\u6B63\u786E\uFF0C\u8BF7\u4E0A\u4F20 Excel \u683C\u5F0F\u7684\u6587\u4EF6\u3002"
    });
  }
});

var _menuLv2 = _AppSettings2.default.FindLv2Menu(_Router.history.getCurrentLocation() || "");

if (null != _menuLv2 && !_menuLv2.ForGuest) {
  _Vue.http.headers.common = {
    AuthUnitToken: _menuLv2.AuthUnitToken
  }; //处理第一次进入页面验证

  _Vue.http.post("" + _Host + _VerifyAuthUnitTokenPath, {
    authUnitToken: _menuLv2.AuthUnitToken
  }, {
    DisableSuccessMessage: true,
    emulateJSON: true
  }).then(function (result) {
    var _result = result.body;

    if (_result.Code == 1) {
      RunApp();
    }
  });
} else {
  RunApp();
}

function RunApp() {
  var app = new _Vue({
    el: '#app',
    router: _Router,
    render: function render(h) {
      return h(_App.default);
    },
    mounted: function mounted() {//	第二部执行这里
    }
  });
}

if (!Array.prototype.find || !Array.prototype.findIndex) {
  require(["./tool/JsTypeExtension"], function (cb) {
    cb.default();
  });
} //设置界面权限功能


(function () {
  var _config = _AppSettings2.default.InitConfig;
  var _routes = _config.routes;

  for (var _r = 0; _r < _routes.length; _r++) {
    var _route = _routes[_r];

    if ("Layout" === _route.name) {
      var _childAry = _route.children;

      var _loop_1 = function _loop_1(_c) {
        var _child = _childAry[_c]; //"debug code"
        //console.log("_child=");
        //console.log(_child);
        //"end debug code"
        //直接用 _child.component(function (component){})会造成组件立刻加载

        var _fn_child_component = _child.component;
        var _clildName = _child.name;

        _child.component = function (cb) {
          var _component = _fn_child_component().then(function (component) {
            console.log("%c call child component() =>[" + _clildName + "]", 'color: #43bb88'); //使用 mixin 实现建立界面设置加载机制
            //let _dataFn: Function = component.default.data;

            var _authDefaultSettings = component.default.AuthUnitSettings;
            var _mixin = {
              data: function data() {
                return {
                  AuthUnitSettings: {},
                  PageTitleName: ""
                };
              },
              mounted: function mounted() {
                //mixin  mounted 在组件mounted之前执行
                var _$this = this;

                var _authSettings = _authDefaultSettings;

                var _user = JSON.parse(localStorage.getItem(_UserInfoLocalKey));

                if (_user && _user.AuthUnitSettingsDictionary && _user.AuthUnitSettingsDictionary[_clildName]) {
                  //登录后获取的的界面设置
                  _authSettings = _user.AuthUnitSettingsDictionary[_clildName];
                }

                var _authSetsObj = {};

                if (!!_authSettings) {
                  for (var _key in _authSettings) {
                    var _sets = _authSettings[_key];
                    _authSetsObj[_key] = "object" == (0, _typeof2.default)(_sets) ? _sets.Value : _sets;
                  }
                }

                Object.assign(_$this.AuthUnitSettings, _authSetsObj);

                var _menuLv2 = _AppSettings2.default.FindLv2Menu(_$this.$route.path || _child.path);

                if (_menuLv2) {
                  console.log(_menuLv2);
                  _$this.PageTitleName = _menuLv2.Name; //附加参数

                  var _params = _menuLv2.Params;

                  if (_params) {
                    Object.assign(_$this.$route.params, _params);
                  }
                }

                
              }
            };

            if (!!component.default.mixins) {
              component.default.mixins.push(_mixin);
            } else {
              component.default.mixins = [_mixin];
            }

            cb && cb(component);
          });

          return _component;
        };
      };

      for (var _c = 0; _c < _childAry.length; _c++) {
        _loop_1(_c);
      }
    }
  }

  _RouteOptions.routes.splice(0);

  _RouteOptions.routes.push.apply(_RouteOptions.routes, _routes);

  _Router.addRoutes(_RouteOptions.routes);
})();

_Vue.prototype.NumberFormat = _TableUtils.default.NumberFormat;