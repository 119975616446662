"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DownLoadFileByHerf = DownLoadFileByHerf;
exports.DownLoadBase64File = DownLoadBase64File;
exports.canvasCompressDataURL = canvasCompressDataURL;
exports.TableToExcel = TableToExcel;
exports.ApiRuner = exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.typed.uint8-array");

require("core-js/modules/es6.object.assign");

require("core-js/modules/es6.promise");

require("core-js/modules/es6.object.to-string");

var _loadVue = _interopRequireDefault(require("../loadVue"));

///<reference path='../../types/index.d.ts' />
//import Vue from '../loadVue'其实是转换成这种形式
var _Vue = _loadVue.default;
var _Http = _Vue.http;

function HttpAipCall(jsObj, url, emulateJSON, opts) {
  
  return new Promise(function (fulfill, reject) {
    if (emulateJSON) {
      //form data 使用vue-resource=> Url.params =>serialize的时候只支持PlainObject
      //对像如果是class的话会序列化错误,key名称没序列化出来

      /*
      function isObject(obj) {
          return obj !== null && typeof obj === 'object';
      }
            function isPlainObject(obj) {
          return isObject(obj) && Object.getPrototypeOf(obj) == Object.prototype;
      }
      */
      jsObj = JSON.parse(JSON.stringify(jsObj));
    }

    opts = opts || {}; //true是form data的形式，false是request payload
    //form data 使用vue-resource=> Url.params进行转换

    opts.emulateJSON = emulateJSON;

    _Http.post(url, jsObj, opts).then(function (res) {
      res.body.IsSuccess = res.body.Code == 1;
      fulfill(res);
    }, function (ex) {
      //服务端没开：ex.ok==false && ex.status=0
      var _svrAbort = ex.ok == false && ex.status == 0;

      if (_svrAbort) {
        _Vue.prototype.$Message.error("服务器无法连接");
      }

      var _message = 401 === ex.status ? "账号未登录" : ex.bodyText || ex.body || "请求出错";

      if (!_svrAbort) {
        _Vue.prototype.$Message && _Vue.prototype.$Message.error(_message);
      }

      fulfill({
        body: {
          IsSuccess: false,
          Code: 0,
          Message: _message
        }
      });
      return;
    });
  });
}

function InitApiCaller(apiObj, apiHost) {
  for (var _key in apiObj) {
    var _actObj = apiObj[_key];

    if (_actObj) {
      var _loop_1 = function _loop_1(_act) {
        var _url = apiHost + "/" + _key + "/" + _act;

        var _fn = function _fn(jsObj, emulateJSON, opts) {
          if (emulateJSON === void 0) {
            emulateJSON = true;
          }

          return HttpAipCall(jsObj, _url, emulateJSON, opts);
        };

        _fn.ApiName = _key + "." + _act;
        _fn.ApiUrl = _url;
        _actObj[_act] = _fn;
      };

      for (var _act in _actObj) {
        _loop_1(_act);
      }
    }
  }
} //这个主要是用来带出下面类型的属性

/**
* emulateJSON默认为true
* */


var _; //class 中定义的字段没赋值的话js文件会不生成属性


var U8Data = {
  //U8发货单
  QueryDispatch: _,
  //U8发货单明细
  QueryDispatchDtl: _,
  QueryDispatchDtlByDLID: _,
  SummaryDispatchDtl: _,
  //U8销售订单
  QuerySaleOrder: _,
  //U8销售订单明细
  QuerySaleOrderDtl: _,
  //客户查询
  QueryCustomer: _,

  /**U8业务员列表查询 */
  QuerySalesmanList: _,

  /**U8部门列表查询 */
  QueryDeparemtntList: _,

  /**U8客户列表查询 */
  QueryCustomerList: _,

  /**U8根据客户编号查询客户信息 */
  QueryCustomerByCode: _
};
var OutBill = {
  //  查询客户是否开票
  QueryCustomerIsPrint: _,
  //查询申请单
  QueryOutBillApply: _,
  //查询申请单审核
  QueryOutBillAudit: _,

  /**
  * 保存申请单
  * */
  SaveOutBill: _,
  QueryOutBillById: _,

  /**
  * 申请单发货明细/开票明细
  * */
  QueryOutBillDtlById: _,

  /**
  * 已开发票号信息
  * */
  QueryOutBillInvoiceById: _,
  DeleteOutBillById: _,
  //提交申请单
  PostOutBillById: _,
  //撤回提交
  UnPostOutBillById: _,
  //接收提交
  AcceptPostOutBillById: _,
  //退回编辑
  AcceptBackToNewOutBillById: _,
  //开具发票
  CreateInvoiceOutBillById: _,
  //红冲发票
  RedCancelInvoiceOutBillById: _,
  //生成U8销售发票
  OutBillDataToU8ById: _,
  //取消U8同步
  CancelOutBillDataToU8ById: _,
  //作废发票
  CancelInvoiceOutBillById: _,
  //删除发票
  RemoveInvoiceOutBillById: _,
  //优惠审核
  QueryOutBillLowAudit: _,
  //优惠审核(接收)
  LowAuditAcceptPostOutBillById: _,
  //优惠审核(通过)
  LowAuditPassOutBillById: _,
  //优惠审核(取消)
  LowAuditUnPassOutBillById: _,
  //优惠审核(退回提交)
  LowAuditBackToNewOutBillById: _,
  //超额审核
  QueryOutBillOverAudit: _,
  //超额审核(接收)
  OverAuditAcceptPostOutBillById: _,
  //超额审核(通过)
  OverAuditPassOutBillById: _,
  //超额审核(取消)
  OverAuditUnPassOutBillById: _,
  //超额审核(退回提交)
  OverAuditBackToNewOutBillById: _,
  //查询税盘购票信息
  QueryTaxInvoiceBuyInfo: _,
  //人脸二维码
  QrCodeString: _,
  //下载开票明细Excel
  DownLoadExcelOfTaxInvoice: _,
  DownLoadExcelOfTaxInvoiceAll: _,
  //导入开票明细Excel
  UploadExcleOfTaxInvoice: _,

  /** 调整尾数差异 */
  AdjustOutBillDtlTailNum: _,

  /**打印发票*/
  PrintInvoice: _,

  /** 打印发票清单,参数：billId，invoiceCode，invoiceNumber */
  PrintInvoiceList: _,

  /** 导入客户开票信息 */
  UploadExcelOfBillApply: _,

  /** 导入世邦内部开票Excel */
  ImportOutBillInsideExcel: _,

  /** 根据不含税金额等分发票 */
  BillInvoiceDtlSplit: _,
  //下载申请单发票
  DownLoadInvoiceFilesByOutBillId: _,
  DownLoadInvoiceZipFileIsComplete: _,
  DownLoadInvoiceZipFile: _
};
var Department = {
  //查询部门
  DepartmentList: _,
  QueryListNoPaging: _,
  //查询子部门
  ChildDepartmentList: _,
  //保存部门
  SaveDepartment: _,
  DeleteDepartment: _
};
var UserAndRole = {
  //系统用户列表
  UserList: _,
  //保存用户
  SaveUser: _,
  DeleteUser: _,
  //用户角色列表
  UserRoleList: _,
  //  获取用户登录公司权限
  GetUserAuthByUserId: _,
  UserListNoPaging: _,
  AccessOrganizationUserList: _,
  SaveUserEmail: _,
  GetUserInfo: _,
  ExportData: _
};
var Role = {
  //系统用户列表
  RoleList: _,
  //保存用户
  SaveRole: _,
  DeleteRole: _,
  //用户角色列表
  UserRoleList: _,
  //获取Role关联的所有 AuthToken
  GetAuthUnitListByRoleId: _,
  ExportData: _
};
var Organization = {
  //查询部门
  OrganizationList: _,
  //保存部门
  SaveOrganization: _,
  DeleteOrganization: _,

  /**
  * 查询指定Id的公司信息，参数：orgId
  * */
  GetOrganizationById: _,
  ExportData: _
};
var ProductItem = {
  //查询商品档案
  ProductItemList: _,
  //保存商品档案
  SaveProductItem: _,
  DeleteProductItem: _,
  UploadExcleOfProductItem: _,
  //下载开票明细Excel
  DownLoadExcelOfProductItem: _
};
var ProductClass = {
  //查询商品类别档案
  ProductClassList: _,
  //保存商品类别档案
  SaveProductClass: _,
  DeleteProductClass: _,
  UploadExcleOfProductClass: _,
  //下载开票明细Excel
  DownLoadExcelOfProductClass: _
};
var Customer = {
  QueryCustomer: _,
  //按客户编码查询客户,参数 qString
  QueryCustomerByCode: _,
  //查询客户
  CustomerList: _,
  //保存客户
  SaveCustomer: _,
  DeleteCustomer: _,
  UploadExcleOfCustomer: _,
  //下载客户信息Excel
  DownLoadExcelOfCustomer: _,
  //下载税控客户信息导入txt文件
  DownLoadTaxTextFileOfCustomer: _
};
var Account = {
  //SendInvitation: '/account/SendInvitation',//发送关联邀请
  //AddPersonalUser: '/Account/AddPersonalUser',//个人版用户申请加入企业
  //CheckPersonalUser: '/Account/CheckPersonalUser',//个人版用户申请加入企业审核
  //AssignPersonalUser: '/Account/AssignPersonalUser',//3.给个人版用户分配权限
  //UpdatePersonalUser: '/Account/UpdatePersonalUser',//修改个人版用户信息 
  //DisablePersonalUser: '/Account/DisablePersonalUser',//删除个人版用户
  //GetInvitationUrl: '/Account/GetInvitationUrl',

  /** 发验证码 */
  SendValidCode: _,

  /** 验证码校验 */
  CheckValidCode: _,

  /** 重置密码 */
  ResetPassWord: _,

  /** 修改密码 */
  UpdatePassWord: _,

  /** 用户登录 */
  LogIn: _,

  /** 退出登录 */
  LogOut: _,

  /** 刷新登录 */
  RefreshAuth: _,

  /** 切换默认公司 */
  ChangeUserOrganization: _,

  /** 获取登录可选择公司列表 */
  OrganizationList: _,
  GetUserAccessToken: _,
  UserIsAuthenticated: _,
  ATPiaoLogin: _,
  GetRSAParameters: _
};
var BuyTaxDeduction = {
  /** 上传进项抵扣数据 */
  BuyInvoiceDeductionDataPush: _,

  /** 查询可以勾选的进项发票 */
  QueryBuyInvoiceDeductionForChecked: _,

  /** 查询抵扣信息 */
  QueryBuyInvoiceDeductionData: _,

  /** 获取抵扣数据同步信息  */
  QueryBuyInvoiceDeductionSynInfo: _,

  /** 获取抵扣数据同步信息 */
  QueryDeductionSynInfo: _,

  /** 保存抵扣数据同步信息 */
  SaveBuyInvoiceDeductionSynInfo: _,

  /** 删除抵扣数据同步信息 */
  DeleteBuyInvoiceDeductionSynInfo: _,

  /** 导出抵扣数据 */
  ExportBuyInvoiceDeductionExcel: _,

  /** 根据金额勾选发票 */
  CheckBuyInvoiceDeductionByTaxAmount: _,

  /** 当前未抵扣数据上传结束,根据_synCurrentToken 获取没有同步到的数据*/
  BuyCurrentInvoiceErrorData: _,
  BuyInvoiceDeductionDataUpdate: _,
  BuyCurrentInvoiceDeductionDataClearChecked: _
};
var BuyInvoiceAccept = {
  QueryOcrInvoice: _,
  //  根据 ocrIds 查数据
  QueryOcrInvoiceById: _,
  //  上传图片文件进行Ocr识别，返回 OcrInvoiceInfo 类型数据
  AsyncOcrInvoice: _,
  //  上传PDF或图片文件识别
  InvoiceFileOcr: _,
  //  上传发票图片
  AsyncOcrInvAry: _,
  SaveOcrInvoice: _,
  SetPictureTransform: _,
  //  重新校验发票
  AsyncOcrInvCall: _,

  /** 删除进项验收明细 */
  DeleteOcrInvoice: _,

  /**导出发票光学字符识别信息 */
  ExportInvOcrExcel: _,
  QueryBuyInvReport: _,
  DownLoadExcelOfBuyInvReport: _,
  InvOCRCountReportQuery: _
};
var Picture = {
  //参数 outOcrIds 获取图片
  FindImageFile: _,
  FindThumbnailImageFile: _,
  UploadImageFile: _
};
var OcrServiceSettings = {
  SetBaiduOCRSettings: _,
  GetBaiduOCRSettings: _,
  SetExamineSettings: _,
  GetExamineSettings: _,
  SetInvDeductionSetting: _,
  GetInvDeductionSetting: _
};
var SaleTaxInvoice = {
  QuerySaleTaxInvoice: _,
  QuerySaleTaxInvoiceWithBill: _,

  /**查询保留的发票 */
  QueryRetainSaleTaxInvoice: _,

  /**查询可用于寄快递的发票 */
  QuerySaleTaxInvoiceForExpress: _,

  /**查询可用于寄快递的发票 导出Excel */
  ExportSaleTaxInvoiceForExpress: _,
  QuerySaleTaxInvDtlById: _,
  QuerySaleTaxInvoiceWithDtl: _,
  QuerySaleTaxInvoiceDtlWithBill: _,
  DownLoadExcelOfSaleTaxInvoice: _,
  DownLoadExcelOfSaleTaxInvoiceWithBill: _,
  DownLoadExcelOfSaleTaxInvoiceWithDtl: _,
  DownLoadExcelOfSaleTaxInvoiceDtlWithBill: _,
  DownLoadExcelZipFileIsComplete: _,
  DownLoadExcelZipFile: _,

  /**销项发票信息Excel上传 */
  SaleTaxInvUploadExcel: _
};
var BuyTaxInvoice = {
  QueryBuyTaxInv: _,
  QueryViewModelById: _,
  QueryBuyTaxInvWithDtl: _,
  QueryBuyTaxInvDtlById: _,
  DeleteBuyTaxInvByID: _,

  /**进项发票信息导出Excel(不包含明细) */
  ExportBuyTaxInvExcel: _,
  ExportBuyTaxInvWithDtlReport: _,

  /**进项发票审核*/
  SpecialApproval: _,

  /** 保存图片角度*/
  SetPictureTransform: _,

  /** 设置发票标签*/
  SetInvoiceClassify: _,

  /** 设置凭证*/
  SetInvoiceErpVoucher: _
};
var SaleBillContract = {
  QuerySaleBillContract: _,
  QuerySaleBillContractWithPaymentTime: _,
  GetInitData: _,
  QuerySaleBillContractByID: _,
  SaveSaleBillContract: _,
  UpdateSaleBillContractStatus: _,

  /**退回申请*/
  BackToApplySaleBillContractStatus: _,

  /**退回复核*/
  BackToCheckSaleBillContractStatus: _,

  /**退回审核*/
  BackToAuditSaleBillContractStatus: _,
  DeleteSaleBillContract: _,

  /**上传Excel导入销售合同 */
  UploadExcelSaleBillContract: _
};
var FileStorage = {
  DownloadFile: _,
  UploadFile: _
};
var SaleBillContractRevenue = {
  QueryList: _,
  QueryRevenueAmountByContractPaymentTimeId: _,
  QueryRevenueAmountByContractId: _,
  QueryModelByID: _,
  SaveModel: _,
  DeleteModel: _,
  InsertPicture: _,
  ShowImage: _
};
var SaleContractReport = {
  QueryContractArtistFeeReport: _,
  ExportContractArtistFeeReport: _,
  DownLoadExcelOfContractArtistFeeReport: _,
  QueryContractOutBillAndRevenueReport: _,
  ExportContractOutBillAndRevenueReport: _,
  DownLoadExcelOfContractOutBillAndRevenueReport: _,
  QueryContractRevenueReport: _,
  ExportContractRevenueReport: _,
  DownLoadExcelOfContractRevenueReport: _,
  QueryContractRevenueReportByPaymentType: _
};
/** 快递公司管理相关接口 */

var ExpressCompany = {
  QueryList: _,
  SaveModel: _,
  DeleteModel: _
};
/** 快递管理相关接口 */

var Express = {
  QueryList: _,
  QueryExpressInvList: _,
  GetModelById: _,
  SaveModel: _,
  DeleteModel: _,
  ExpressEOrderSub: _,
  ExpressEOrderCancel: _,
  ExpressToAudit: _,
  ExpressCancelToAudit: _,
  ExpressAuditReject: _,
  ExpressAuditPass: _,
  ExpressCancelAuditPass: _,
  BatchCreateExpress: _,

  /** 即时物流查询 */
  GetOrderTraces: _,
  GetOrderTracesByExpressNo: _,
  QueryExpressReceiverConfig: _,
  QueryExpressSenderConfig: _,

  /**生成电子面单打印参数 */
  GenerateEOrderPrintRequestData: _,
  OpenExpressPrintTemplate: _
};
var ExpressSettings = {
  SetKdApiSettings: _,
  GetKdApiSettings: _,
  SetSenderSettings: _,
  GetSenderSettings: _
};
/** 科目余额表相关接口 */

var SubjectBalanceReport = {
  /** 查询列表 */
  QueryList: _,

  /** 查询明细列表 */
  QueryDtlList: _,

  /** 导入Excel */
  ImportExcel: _,
  DeleteSubjectBalanceById: _
};
/** 发票转出相关接口 */

var BuyInvoiceTransfer = {
  QueryList: _,
  GetModelById: _,
  SaveModel: _,
  SaveList: _,
  DeleteModel: _,
  QueryBuyTaxInvForTransfer: _,
  ExportExcel: _,
  SetTransferErpVoucher: _
};
/** 进项 红字通知单登记 */

var BuyInvoiceRedNotice = {
  QueryList: _,
  GetModelById: _,
  QueryBuyTaxInvoiceAryById: _,
  SaveModel: _,
  SaveList: _,
  DeleteModel: _,

  /**根据登记信息生成进项转出记录*/
  SyncToBuyInvoiceTransfer: _,

  /**取消 根据登记信息生成进项转出记录*/
  CancelSyncToBuyInvoiceTransfer: _,
  QueryBuyTaxInvForRedNotice: _,
  ExportExcel: _
};
var PDF = {
  /**上传PDF文件 */
  UploadPDF: _,

  /**获取PDF文件的URL路径 */
  ConvertPDFToPicture: _,
  FindPDFFile: _
};
var BuyInvoiceVerifySettings = {
  QueryList: _,
  QueryListNoPaging: _,
  CustomQueryList: _,
  CustomQueryListNoPaging: _,
  SaveModel: _,
  DeleteModelById: _,
  UploadExcel: _,
  DownLoadExcel: _
};

function BBDSetsCtor() {
  return {
    QueryList: _,
    QueryListNoPaging: _,
    SaveModel: _,
    DeleteModelById: _,
    UploadExcel: _
  };
}

var SaleBillVouch = {
  //  查询销售发票明细数据
  QuerySaleBillInvoiceDtlById: _,
  //  查询销售发票开票情况
  QuerySaleBillCreateInvoiceInfoById: _,
  //(销售发票)开具发票
  CreateInvoiceBySaleBillInvoiceId: _,
  //(销售发票)添加发票
  AddInvoiceBySaleBillInvoiceId: _,
  //(销售发票)作废发票
  CancelInvoiceBySaleBillInvoiceId: _,
  //(销售发票)移除发票
  RemoveInvoiceBySaleBillInvoiceId: _,
  //(销售发票)红冲发票
  RedCancelInvoiceBySaleBillInvoiceId: _,
  //(销售发票)同步 ERP 销售发票
  SyncToErpBySaleBillInvoiceId: _,

  /**(销售发票)取消 ERP 同步*/
  CancelSyncToErpBySaleBillInvoiceId: _,

  /**(销售发票)更新发票信息*/
  SyncInvoiceNoToErpBySaleBillInvoiceId: _,

  /** 生成销售发票 */
  CreateSaleBillInvoice: _,

  /** 查询销售发票 */
  QuerySaleBillVouchsByBillId: _,

  /** 删除销售发票 */
  DeleteSaleBillInvoice: _,

  /** 保存发票明细 */
  SaveSaleBillInvoiceDtl: _,
  SaveSaleBillInvoiceDtl_V2: _,

  /** 设为同步 */
  SyncToErpBySaleBillBySelf: _,

  /** 设为不同步 */
  CancelSyncToErpBySaleBillBySelf: _
};
var SaleBill = {
  /**
  * 导也百旺 EXCEL 开票模板
  * */
  DownLoadExcelOfTaxInvoiceForBaiWang: _,

  /**
  * 导入EXCEL生成申请单
  * */
  ImportSaleBillByExcel: _,

  /**
  * 下载合并后的ERP明细和开票明细表格
  * */
  DownLoadExcelOfErpToInvoice: _,

  /**
  * 查询客户是否开票
  * */
  QueryCustomerIsPrint: _,

  /**
  * 开具发票
  * */
  CreateInvoiceOutBillById: _,

  /**
  * 添加发票
  * */
  AddInvoiceOutBillById: _,

  /**
  * 作废发票
  * */
  CancelInvoiceOutBillById: _,

  /**
  * 移除发票
  * */
  RemoveInvoiceOutBillById: _,

  /**
  * 红冲发票
  * */
  RedCancelInvoiceOutBillById: _,

  /**
  * 同步生成ERP系统销售
  * */
  SyncToErpByOutBillId: _,

  /**
  * 取消同步生成ERP系统销售
  * */
  CancelSyncToErpByOutBillId: _,

  /**
  * 同步发票号至ERP系统
  * */
  SyncInvoiceNoToErpByOutBillId: _,

  /**
  * 同步发票号至ERP系统(根据结算单号)
  * */
  SyncInvoiceNoToErpByErpOrderNO: _,

  /**
  * 查询申请单
  * */
  QueryOutBillApply: _,

  /**
  * 查询申请单审核
  * */
  QueryOutBillAudit: _,

  /**
  * 保存申请单
  * */
  SaveOutBill: _,
  SaveOutBillReceiver: _,

  /**
  * 保存修改后的开票明细
  * */
  SaveBillInvoiceDtl: _,
  QueryOutBillById: _,

  /**
  * 申请单发货明细/开票明细
  * */
  QueryOutBillDtlById: _,

  /**
  * 已开发票号信息
  * */
  QueryOutBillCreateInvoiceInfoById: _,

  /**
  * 已开发票号信息
  * */
  QueryOutBillInvoiceById: _,
  DeleteOutBillById: _,

  /**
  * 提交申请单
  * */
  PostOutBillById: _,

  /**设置发票自取*/
  SetReceiverBySelfById: _,

  /**
  * 撤回提交
  * */
  UnPostOutBillById: _,
  //接收提交
  AcceptPostOutBillById: _,
  //退回编辑
  AcceptBackToNewOutBillById: _,
  //优惠审核
  QueryOutBillLowAudit: _,
  //优惠审核(接收)
  LowAuditAcceptPostOutBillById: _,
  //优惠审核(通过)
  LowAuditPassOutBillById: _,
  //优惠审核(取消)
  LowAuditUnPassOutBillById: _,
  //优惠审核(退回提交)
  LowAuditBackToNewOutBillById: _,
  //超额审核
  QueryOutBillOverAudit: _,
  //超额审核(接收)
  OverAuditAcceptPostOutBillById: _,
  //超额审核(通过)
  OverAuditPassOutBillById: _,
  //超额审核(取消)
  OverAuditUnPassOutBillById: _,
  //超额审核(退回提交)
  OverAuditBackToNewOutBillById: _,
  //查询税盘购票信息
  QueryTaxInvoiceBuyInfo: _,
  //下载开票明细Excel
  DownLoadExcelOfTaxInvoice: _,
  //导入开票明细Excel
  UploadExcleOfTaxInvoice: _,

  /** 调整尾数差异 */
  AdjustOutBillDtlTailNum: _,

  /**打印发票*/
  PrintInvoice: _,

  /**
  * 打印发票清单,参数：billId，invoiceCode，invoiceNumber
  * */
  PrintInvoiceList: _,

  /** 导入客户开票信息 */
  UploadExcelOfBillApply: _,

  /** 根据不含税金额等分发票 */
  BillInvoiceDtlSplit: _
};
var SaleBillFromBuy = {
  SaveSaleBill: _,
  DeleteSaleBillById: _,
  QuerySaleBillDtlToBuyInvoiceDtlById: _,

  /**同步发票号*/
  SyncInvoiceNoToErpByOutBillId: _,

  /**生成发票明细*/
  GenerateErpInvoiceDtl: _
};
var ErpItemToInvoiceItemStock = {
  /**查询进项科目存量*/
  QueryErpItemToInvoiceItemStock: _,

  /**导出进项科目存量*/
  ExportErpItemToInvoiceItemStock: _,

  /**查询进项科目存量(进项明细)*/
  QueryBuyBillVouchInvoiceDtlToBillDtls: _,

  /**导出进项科目存量(进项明细)*/
  ExportBuyBillVouchInvoiceDtlToBillDtls: _,

  /**导出进项科目存量(进项明细)设置在线调拨状态*/
  SetBuyBillVouchInvoiceDtlToBillDtlOnlineTransfer: _,

  /**查询进项科目存量(销项明细)*/
  QuerySaleBillDtlToBuyInvoiceDtls: _,

  /**导出进项科目存量(销项明细*/
  ExportSaleBillDtlToBuyInvoiceDtls: _,

  /**保存存量*/
  SaveErpItemToInvoiceItemStock: _
};
var BuyBillVouch = {
  /*根据发票号查询对应的采购发票*/
  QueryBuyTaxInvForBuyVouchByNumber: _,

  /**查询用于采购发票匹配的进项发票*/
  QueryBuyTaxInvForBuyVouch: _,

  /**生成ERP转出凭证*/
  CreateVouchToErp: _,

  /*Erp明细与发票明细匹配*/
  ErpBillDtlMapToErpInvoiceDtl: _,
  PostBuyBillVouchById: _,
  UnPostBuyBillVouchById: _,
  AcceptBackToNewBuyBillVouchById: _,
  AcceptPostBuyBillVouchById: _,

  /**(采购发票)查询采购发票明细数据*/
  QueryBuyBillVouchApply: _,

  /**(采购发票)查询采购发票明细数据*/
  QueryOutBillAudit: _,

  /**(采购发票)查询采购发票明细数据*/
  QueryBuyBillVouchDtlById: _,

  /**(采购发票)ERP数据与发票明细数据匹配明细*/
  QueryBuyBillVouchInvoiceDtlToBillDtlsById: _,

  /**(采购发票)进项发票*/
  QueryBuyBillVouchInvoicesById: _,

  /**(采购发票)添加发票*/
  AddInvoiceByBuyBillVouchId: _,

  /**(采购发票)移除发票*/
  RemoveInvoiceByBuyBillVouchId: _,

  /**(采购发票)同步 ERP 采购发票*/
  SyncToErpByBuyBillVouchId: _,

  /**(采购发票)取消 ERP 同步*/
  CancelSyncToErpByBuyBillVouchId: _,
  ///**(采购发票)更新发票信息*/
  //SyncInvoiceNoToErpByBuyBillVouchId: _,
  ///**(采购发票)更新发票信息*/
  //SyncInvoiceNoToErpByErpOrderNO: _,

  /** 保存采购发票 */
  SaveBuyBillVouch: _,

  /** 查询采购发票 */
  QueryBuyBillVouchById: _,

  /** 删除采购发票 */
  DeleteBuyBillVouchById: _,

  /** 生成可分配进项发票明细 */
  CreateErpInvoiceDtl: _,

  /** 保存匹配名称 */
  SaveInvoiceProductNameMapSettings: _,
  SaveInvoiceProductNameMapSettingsById: _,

  /** 查询匹配名称 */
  QueryInvoiceProductNameMapSettings: _,

  /** 导出匹配EXCEL */
  ExportInvoiceProductNameMapSettings: _,
  ExportData: _,
  ChangeBuyBillVouchItemStockTypeById: _
};
var ErpData = {
  //查询可用于开销售发票的出库单(根据订单明细)
  QuerySaleOutStockOrderToCreateInvoiceBySaleDtlId: _,
  //查询可用于开销售发票的出库单(根据发货单明细)
  QuerySaleOutStockOrderToCreateInvoiceBySendDtlId: _,
  //查询可用于开销售发票的出库单(根据出库单明细)
  QuerySaleOutStockOrderToCreateInvoiceByOutStockDtlId: _,
  //销售订单
  QuerySaleOrder: _,
  //销售订单明细
  QuerySaleOrderDtlByIDs: _,
  //发货单
  QuerySendOrder: _,
  //发货单明细
  QuerySendOrderDtlByIDs: _,
  //内部结算单
  QueryInnerSaleOrder: _,
  //内部结算单明细
  QueryInnerSendOrderDtlByIDs: _,
  //其它出库开票
  QueryOtherStockOutOrder: _,
  //其它出库开票明细
  QueryOtherStockOutOrderDtlByIDs: _,
  //采购入库单
  QueryBuyInStockOrder: _,
  //采购入库单明细
  QueryBuyInStockOrderDtlByIDs: _,
  SummaryDispatchDtl: _,
  //客户查询
  QueryCustomer: _,

  /**U8业务员列表查询 */
  QuerySalesmanList: _,

  /**U8部门列表查询 */
  QueryDeparemtList: _,

  /**U8客户列表查询 */
  QueryCustomerList: _,

  /**供应商列表查询 */
  QuerySupplierList: _,

  /**U8根据客户编号查询客户信息 */
  QueryCustomerByCode: _,

  /*生成Erp 明细与开票明细 的对应数据*/
  CreateBillErpnvoiceDtls: _,

  /*生成Erp 明细与开票明细 的对应数据（集采开票）*/
  CreateBillErpnvoiceDtlsFromBuyBill: _,

  /*出库负数单据(退货单)*/
  QueryBackStockOrder: _,

  /*出库负数单据(退货单) 明细*/
  QueryBackStockOrderDtlByIDs: _
};
var ErpVoucher = {
  /**同步ERP系统凭证*/
  SyncBuyBillVouchsFromErp: _,

  /**生成抵扣凭证*/
  CreateDeductionVoucher: _
}; ///通用设置处理

var BusinessBasicDataSettings = BBDSetsCtor();
var BuyInvoiceAccepter = BBDSetsCtor();
var SaleBillContractType = BBDSetsCtor();
var AuthTenant = BBDSetsCtor();
var TenantUser = BBDSetsCtor();
var BuyInvoiceBillClassify = BBDSetsCtor();
var BuyInvoiceBillClassifyGroup = BBDSetsCtor();
var SaleBillContractProjectName = BBDSetsCtor();
var ContractPaymentTypeOne = BBDSetsCtor();
var ContractPaymentTypeTwo = BBDSetsCtor();
var OrganizationBuyInvoiceVerifySettings = BBDSetsCtor();
var TaxInvoiceInStock = BBDSetsCtor();
var _Api = {
  U8Data: U8Data,
  OutBill: OutBill,
  ErpData: ErpData,
  SaleBill: SaleBill,
  SaleBillFromBuy: SaleBillFromBuy,
  SaleBillVouch: SaleBillVouch,
  Department: Department,
  UserAndRole: UserAndRole,
  Role: Role,
  Organization: Organization,
  ProductItem: ProductItem,
  ProductClass: ProductClass,
  Account: Account,
  Customer: Customer,
  BuyInvoiceAccept: BuyInvoiceAccept,
  BuyTaxDeduction: BuyTaxDeduction,
  OcrServiceSettings: OcrServiceSettings,
  SaleTaxInvoice: SaleTaxInvoice,
  BuyBillVouch: BuyBillVouch,
  BuyTaxInvoice: BuyTaxInvoice,
  SaleBillContract: SaleBillContract,
  SaleBillContractRevenue: SaleBillContractRevenue,
  BuyInvoiceBillClassify: BuyInvoiceBillClassify,
  TaxInvoiceInStock: TaxInvoiceInStock,
  SaleBillContractType: SaleBillContractType,
  AuthTenant: AuthTenant,
  TenantUser: TenantUser,
  BuyInvoiceBillClassifyGroup: BuyInvoiceBillClassifyGroup,
  SaleBillContractProjectName: SaleBillContractProjectName,
  BuyInvoiceVerifySettings: BuyInvoiceVerifySettings,
  OrganizationBuyInvoiceVerifySettings: OrganizationBuyInvoiceVerifySettings,
  FileStorage: FileStorage,
  ContractPaymentTypeOne: ContractPaymentTypeOne,
  ContractPaymentTypeTwo: ContractPaymentTypeTwo,
  SaleContractReport: SaleContractReport,
  Picture: Picture,
  ProxyWeb: {
    ProxyOpen: _
  },
  PDF: PDF,
  ExpressCompany: ExpressCompany,
  ExpressReceiverConfig: {
    QueryList: _,
    SaveModel: _,
    DeleteModel: _,
    UploadExcleOfCustomer: _
  },
  ExpressSenderConfig: {
    QueryList: _,
    SaveModel: _,
    DeleteModel: _,
    UploadExcleOfCustomer: _
  },
  Express: Express,
  ExpressSettings: ExpressSettings,
  SubjectBalanceReport: SubjectBalanceReport,
  BuyInvoiceTransfer: BuyInvoiceTransfer,
  BuyInvoiceRedNotice: BuyInvoiceRedNotice,
  ErpItemToInvoiceItemStock: ErpItemToInvoiceItemStock,
  ErpVoucher: ErpVoucher,
  BuyInvoiceBusinessData: {
    QueryBuyInvoiceDeductionData: _,

    /**查验可用于生成抵扣进项税转出的发票*/
    QueryBuyInvoiceDeductionDataForDeductionVoucher: _,
    ExportBuyInvoiceDeductionExcel: _,

    /**查验可用于勾选的发票*/
    QueryBuyInvoiceDeductionForChecked: _,

    /**根据税额勾选发票*/
    CheckBuyInvoiceDeductionByTaxAmount: _,

    /**查询可用于做进项转出的发票*/
    QueryBuyTaxInvForTransfer: _,

    /**查询可用于做红字登记的发票*/
    QueryBuyTaxInvForRedNotice: _,

    /**根据发票代码/号码 获取发票信息*/
    FindBuyTaxInvoiceBusinessInfo: _,

    /**进项发票查询*/
    QueryBuyTaxInv: _,

    /**进项发票(加明细)查询*/
    QueryBuyTaxInvWithDtl: _,
    ExportBuyTaxInvoiceExcel: _,
    ExportBuyTaxInvoiceWithDtlExcel: _,
    SetInvoiceErpVoucher: _
  },
  SupplierDataWork: {
    QueryList: _,
    QueryAccountList: _,
    ExportData: _,
    GetCoumnSettings: _,
    UpdateSupplierAccountFileId: _,
    DownloadAccountFile: _,
    AccountConfirm: _,
    SaveAccountFix: _
  },
  SupplierDataWorkForRemote: {
    QueryAccountList: _,
    DownloadFileSupplierAccountFile: _,
    ExportMangerQueryAccountData: _,
    BatchDownloadFileSupplierAccountFile: _,
    CreateSupplierAccountZipFileIsComplete: _,
    DownLoadSupplierAccountZipFile: _,
    BatchDownloadFileSupplierAccountExcelFile: _,
    CreateSupplierAccountExcelZipFileIsComplete: _,
    DownLoadSupplierAccountExcelZipFile: _
  },
  BusinessDataReport: {
    QueryList: _,
    QueryList增值税税负率预警表: _,
    QueryList附加税负率预警表: _,
    QueryList各公司增值税税负率统计表: _,
    GetCoumnSettings: _,
    PushReportData: _,
    ExportData: _,
    ExportExcel: _,
    ExportExcelByRowAry: _,
    ExportExcel各公司增值税税负率统计表: _
  },
  ApiData: {
    ProxyQueryExamineCount: _
  },
  BuyTicket: {
    AsyncOcrInvoice: _,
    QueryOcrInvoice: _
  }
};
var _clientApi = {
  CryptCtrl: {
    TaxGovActions: _
  }
}; // { Test: { CALL: _ } };

InitApiCaller(_clientApi, "http://localhost:18765");
var _atpiaoYunApi = {
  WinHelperUpdate: {
    VersionInfo: _
  }
}; // { Test: { CALL: _ } };

InitApiCaller(_atpiaoYunApi, "http://piao.feikongbao.net");
InitApiCaller(_Api, process.env.HOST_URL);

var _default = Object.assign(_Api, _clientApi, _atpiaoYunApi);

exports.default = _default;

var ApiRuner =
/** @class */
function () {
  function ApiRuner(apiHandler) {
    var _this = this;

    this.Posting = false;

    this.AipCall = function (obj, emulateJSON, opts) {
      if (emulateJSON === void 0) {
        emulateJSON = true;
      }

      var _$this = _this;
      _$this.Posting = true;
      
      return new Promise(function (fulfill, reject) {
        _$this.ApiHandler(obj, emulateJSON, opts).then(function (res) {
          fulfill && fulfill(res);
          _$this.Posting = false;
          
        }, function (ex) {
          _$this.Posting = false;
          reject && reject(ex);
        });
      });
    };

    this.ApiHandler = apiHandler;
  }

  return ApiRuner;
}();

exports.ApiRuner = ApiRuner;

function DownLoadFileByHerf(herf) {
  var _aLink = document.createElement('a');

  _aLink.href = herf;

  _aLink.click();

  if (_aLink.remove) {
    _aLink.remove();
  } else if (_aLink.parentNode && _aLink.parentNode.removeChild) {
    _aLink.parentNode.removeChild(_aLink);
  }
}

function DownLoadBase64File(data) {
  var _raw = window.atob(data.FileBase64String);

  var _rawLength = _raw.length;

  var _uInt8Array = new Uint8Array(_rawLength);

  for (var i = 0; i < _rawLength; ++i) {
    _uInt8Array[i] = _raw.charCodeAt(i);
  }

  var _blob = new Blob([_uInt8Array], {
    type: data.ContentType
  });

  if (window.navigator.msSaveBlob) {
    // for ie 10 and later
    try {
      window.navigator.msSaveBlob(_blob, data.FileName);
    } catch (e) {
      console.log(e);
    }
  } else {
    var _aLink = document.createElement('a');

    _aLink.download = data.FileName;
    _aLink.href = URL.createObjectURL(_blob);
    document.body.append(_aLink); // 修复firefox中无法触发click

    _aLink.click();

    if (_aLink.remove) {
      _aLink.remove();
    } else if (_aLink.parentNode && _aLink.parentNode.removeChild) {
      _aLink.parentNode.removeChild(_aLink);
    }
  }
}

function canvasCompressDataURL(path, obj, callback) {
  var img = new Image();
  img.src = path;

  img.onload = function () {
    var _$this = this; // 默认按比例压缩


    var _w = _$this.width,
        _h = _$this.height;

    var _rate = Math.max(_w, _h) / (obj.width || 900);

    _w = _w / _rate;
    _h = _h / _rate;
    var _quality = 0.7; // 默认图片质量为0.7
    //生成canvas

    var _canvas = document.createElement('canvas');

    var _ctx = _canvas.getContext('2d'); // 创建属性节点


    var _attrW = document.createAttribute("width");

    _attrW.nodeValue = _w;

    var _attrH = document.createAttribute("height");

    _attrH.nodeValue = _h;

    _canvas.setAttributeNode(_attrW);

    _canvas.setAttributeNode(_attrH);

    _ctx.drawImage(_$this, 0, 0, _w, _h); // 图像质量


    if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
      _quality = obj.quality;
    } // quality值越小，所绘制出的图像越模糊


    var _base64 = _canvas.toDataURL('image/jpeg', _quality); // 回调函数返回base64的值


    callback(_base64);

    if (_canvas.remove) {
      _canvas.remove();
    } else if (_canvas.parentNode && _canvas.parentNode.removeChild) {
      _canvas.parentNode.removeChild(_canvas);
    }
  };
}

function TableToExcel(elOfTables, filename, styleString) {
  var _uri = 'data:application/vnd.ms-excel;base64,',
      _base64 = function _base64(s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };

  var _aLink = document.createElement('a');

  _aLink.download = filename;
  _aLink.href = _uri + _base64(ConvertHtmlToExcel("", "", elOfTables, filename, styleString));
  document.body.append(_aLink); // 修复firefox中无法触发click

  _aLink.click();

  if (_aLink.remove) {
    _aLink.remove();
  } else if (_aLink.parentNode && _aLink.parentNode.removeChild) {
    _aLink.parentNode.removeChild(_aLink);
  }
}

function ConvertHtmlToExcel(Author, Company, dtOfEls, fileName, styleString) {
  var sbBody = [];
  var sbSheet = [];
  sbBody.push("MIME-Version: 1.0\r\n" + "X-Document-Type: Workbook\r\n" + "Content-Type: multipart/related; boundary=\"-=BOUNDARY_EXCEL\"\r\n\r\n" + "---=BOUNDARY_EXCEL\r\n" + "Content-Type: text/html; charset=\"gbk\"\r\n\r\n" + "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\"\r\n" + "xmlns:x=\"urn:schemas-microsoft-com:office:excel\">\r\n\r\n" + "<head>\r\n" + "<xml>\r\n" + "<o:DocumentProperties>\r\n" + ("<o:Author>" + Author + "</o:Author>\r\n") + ("<o:LastAuthor>" + Author + "</o:LastAuthor>\r\n") + ("<o:Created>" + new Date() + "</o:Created>\r\n") + ("<o:LastSaved>" + new Date() + "</o:LastSaved>\r\n") + ("<o:Company>" + Company + "</o:Company>\r\n") + "<o:Version>11.5606</o:Version>\r\n" + "</o:DocumentProperties>\r\n" + "</xml>\r\n" + "<xml>\r\n" + "<x:ExcelWorkbook>\r\n" + "<x:ExcelWorksheets>\r\n");

  for (var _i = 0; _i < dtOfEls.length; _i++) {
    var gid = _i.toString();

    sbBody.push("<x:ExcelWorksheet>\r\n" + ("<x:Name>Sheet" + (_i + 1) + "</x:Name>\r\n") + ("<x:WorksheetSource HRef=\"cid:" + gid + "\"/>\r\n") + "</x:ExcelWorksheet>\r\n");
    sbSheet.push("---=BOUNDARY_EXCEL\r\n" + ("Content-ID: " + gid + "\r\n") + "Content-Type: text/html; charset=\"gbk\"\r\n\r\n" + "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\"\r\n" + "xmlns:x=\"urn:schemas-microsoft-com:office:excel\">\r\n\r\n" + "<head>\r\n" + "<xml>\r\n" + "<x:WorksheetOptions>\r\n" + "<x:ProtectContents>False</x:ProtectContents>\r\n" + "<x:ProtectObjects>False</x:ProtectObjects>\r\n" + "<x:ProtectScenarios>False</x:ProtectScenarios>\r\n" + "</x:WorksheetOptions>\r\n" + "</xml>\r\n" + "<style>\r\n");
    sbSheet.push(styleString || "");
    sbSheet.push("</style>\r\n" + "</head>\r\n" + "<body>\r\n");
    var _dtEl = dtOfEls[_i];

    if (_dtEl.innerHTML) {
      sbSheet.push(_dtEl.innerHTML);
    } else {
      sbSheet.push(_dtEl);
    }

    sbSheet.push("</body>\r\n" + "</html>\r\n\r\n");
  }

  sbBody.push("</x:ExcelWorksheets>\r\n" + "</x:ExcelWorkbook>\r\n" + "</xml>\r\n" + "</head>\r\n" + "</html>\r\n\r\n");
  sbBody.push(sbSheet.join(''));
  sbBody.push("---=BOUNDARY_EXCEL--");
  return sbBody.join('');
}