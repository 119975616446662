"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

var _expand = _interopRequireDefault(require("./tableHelper/expand"));

var _constant = require("./tableHelper/constant");

var _default = {
  name: 'TableHeader',
  components: {
    RenderHeader: _expand.default
  },
  props: {
    unFixedWidth: Number,
    viewportWidth: Number,
    columnsConfig: Array,
    height: Number,
    scrollSynclData: Object,
    headerClass: {
      type: String,
      default: 'c-table-header__default'
    },
    mainColumnCount: Number,
    subColumnCount: Number,
    mainTitle: String,
    subTitle: String
  },
  data: function data() {
    return {
      cIdKey: _constant.ID_NAME,
      headerShowWidth: 0
    };
  },
  computed: {
    getHeaderTableStyle: function getHeaderTableStyle() {
      return {
        'width': this.unFixedWidth + "px"
      };
    }
  },
  methods: {
    getColumnStyle: function getColumnStyle(column) {
      if (column.showLeftBorder) {
        return {
          width: column.cWidth,
          height: this.height + "px",
          'border-right-color': 'darkgray',
          'border-right-width': '1px'
        };
      }

      return {
        width: column.cWidth,
        height: this.height + "px"
      };
    },
    getColumnWidth: function getColumnWidth(column) {
      return column.cWidth ? column.cWidth.replace('px', '') : 0;
    }
  },
  mounted: function mounted() {
    var _$this = this;

    _$this.$nextTick(function () {
      var _width = 0;
      var _tableWraper = _$this.$refs.tableHeader;

      if (_tableWraper) {
        _width = _tableWraper.clientWidth; //tableHeader

        if (0 == _width) {
          _width = _tableWraper.scrollWidth;
        }
      }

      _$this.headerShowWidth = _width;
    });
  }
};
exports.default = _default;