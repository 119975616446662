"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.number.constructor");

require("./tableHelper/requestAnimationFrameUtil");

var _lodash = _interopRequireDefault(require("lodash"));

var _constant = require("./tableHelper/constant");

var _tableUtil = require("./tableHelper/tableUtil");

var _SingleTable = _interopRequireDefault(require("./SingleTable.vue"));

var _default2 = {
  components: {
    SingleTable: _SingleTable.default
  },
  name: 'VueVirtualScrollTable',
  props: {
    /*多选模式下用于初始化勾选状态*/
    initRowSelection: Function,
    loading: Boolean,
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    filters: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    recordKey: {
      type: String,
      required: false
    },
    height: {
      //type: Number,
      default: function _default() {
        return _constant.DEFAULT_TABLE_HEIGHT;
      }
    },
    headerHeight: {
      type: Number,
      default: function _default() {
        return _constant.DEFAULT_TABLE_HEADER_HEIGHT;
      }
    },
    recordHeight: {
      type: Number,
      default: function _default() {
        return _constant.DEFAULT_TABLE_RECORD_HEIGHT;
      }
    },
    headerClass: {
      type: String,
      default: function _default() {
        return 'c-table-header__default';
      }
    },
    tableWidth: Number,

    /*是否启用多选*/
    multiSelection: {
      type: Boolean,
      default: false
    },

    /*是否显示序号*/
    showRowNumber: {
      type: Boolean,
      default: true
    },

    /*在加载数据的时候是否重置滚动条 */
    autoResetScroll: {
      type: Boolean,
      default: true
    },

    /*显示底部统计列*/
    showSummaryFooter: {
      type: Boolean,
      default: false
    }
  },
  mounted: function mounted() {
    this.handleResize();
  },
  watch: {
    height: {
      handler: function handler(val) {
        val = parseInt(val);
        this.bodyHeight = (isNaN(val) ? _constant.DEFAULT_TABLE_HEIGHT : val) - this.headerHeight;
      },
      immediate: true
    },
    //columnsConfig
    columns: {
      handler: function handler(val) {
        var cloneColumnsConfig = _lodash.default.cloneDeep(val);

        this.buildColumnUUID(cloneColumnsConfig);
        this.cloneColumnsConfig = cloneColumnsConfig;
      },
      immediate: true,
      deep: true
    },
    data: {
      handler: function handler(val) {
        this.filterResult = _lodash.default.cloneDeep(val);
      },
      immediate: true,
      deep: true
    }
  },
  data: function data() {
    return {
      owner: this,
      filterResult: [],
      cloneColumnsConfig: [],
      cloneColumnsRow: [],
      bodyHeight: _constant.DEFAULT_TABLE_HEIGHT - _constant.DEFAULT_TABLE_HEADER_HEIGHT
    };
  },
  computed: {
    isMultHeaderRows: function isMultHeaderRows() {
      return _lodash.default.find(this.columns, function (column) {
        return column.children;
      });
    }
  },
  methods: {
    getCheckedIndexAry: function getCheckedIndexAry() {
      return this.$refs.RefTable ? this.$refs.RefTable.getCheckedIndexAry() : [];
    },
    getFocusdIndex: function getFocusdIndex() {
      return this.$refs.RefTable ? this.$refs.RefTable.getFocusdIndex() : -1;
    },
    getSelectionData: function getSelectionData() {
      return this.$refs.RefTable.getSelectionData();
    },
    clickCurrentRow: function clickCurrentRow(index) {
      var _$this = this;

      var _refTable = this.$refs.RefTable;

      if (_refTable) {
        console.log('VueVirtualScrollTable clickCurrentRow');

        if (_refTable.scrollSynclData) {
          _refTable.scrollSynclData.clicked_index = index;
        }

        _$this.$nextTick(function () {
          _refTable.clickCurrentRow(index);
        });
      }
    },
    focusRowByIndex: function focusRowByIndex(index) {
      var _$this = this;

      var _refTable = this.$refs.RefTable;

      if (_refTable) {
        _$this.$nextTick(function () {
          _refTable.focusRowByIndex(index);
        });
      }
    },
    hasFooterSlot: function hasFooterSlot() {
      return this.$slots.footer !== undefined;
    },
    selectionChange: function selectionChange(selectionArray) {//console.log((this.$refs.RefTable as SingleTableVm).getSelectionData());
    },
    buildColumnUUID: function buildColumnUUID(columnsConfig) {
      var _this = this;

      return columnsConfig.map(function (item) {
        if ('children' in item) _this.buildColumnUUID(item.children);

        if (!item[_constant.ID_NAME]) {
          item[_constant.ID_NAME] = (0, _tableUtil.getRandomStr)(6);
        }

        return item;
      });
    },
    handleResize: function handleResize() {
      var tableWidth = this.$el.offsetWidth - _constant.SCROLL_WIDTH;
      var width = 0;
      var widthCount = 0;

      var columnsConfig = _lodash.default.cloneDeep(this.cloneColumnsConfig);

      for (var _i = 0, columnsConfig_1 = columnsConfig; _i < columnsConfig_1.length; _i++) {
        var column = columnsConfig_1[_i];

        if (column.width = column.width || column.minWidth) {
          width += column.width;
          widthCount++;
        }
      }

      var autoWidth = (tableWidth - width) / (columnsConfig.length - widthCount);

      for (var _a = 0, columnsConfig_2 = columnsConfig; _a < columnsConfig_2.length; _a++) {
        var column = columnsConfig_2[_a];
        column.cWidth = column.width ? column.width + "px" : autoWidth + "px";
      }

      this.cloneColumnsConfig = columnsConfig;
    }
  }
};
exports.default = _default2;