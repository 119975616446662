"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VIRTUAL_REMAIN_COUNT = exports.TABLE_TYPE_VIRTUAL = exports.TABLE_TYPE_RAFS = exports.TABLE_TYPE_COMMON = exports.DEFAULT_TABLE_WIDTH = exports.DEFAULT_TABLE_HEIGHT = exports.DEFAULT_TABLE_RECORD_HEIGHT = exports.DEFAULT_TABLE_HEADER_HEIGHT = exports.SCROLL_WIDTH = exports.SORT_NAME = exports.ID_NAME = exports.ASC = exports.DESC = exports.NO_SORTING = void 0;
var NO_SORTING = 'normal';
exports.NO_SORTING = NO_SORTING;
var DESC = 'desc';
exports.DESC = DESC;
var ASC = 'asc';
exports.ASC = ASC;
var ID_NAME = '__cId';
exports.ID_NAME = ID_NAME;
var SORT_NAME = '__sortType';
exports.SORT_NAME = SORT_NAME;
var SCROLL_WIDTH = 17;
exports.SCROLL_WIDTH = SCROLL_WIDTH;
var DEFAULT_TABLE_HEADER_HEIGHT = 30;
exports.DEFAULT_TABLE_HEADER_HEIGHT = DEFAULT_TABLE_HEADER_HEIGHT;
var DEFAULT_TABLE_RECORD_HEIGHT = 30;
exports.DEFAULT_TABLE_RECORD_HEIGHT = DEFAULT_TABLE_RECORD_HEIGHT;
var DEFAULT_TABLE_HEIGHT = 400;
exports.DEFAULT_TABLE_HEIGHT = DEFAULT_TABLE_HEIGHT;
var DEFAULT_TABLE_WIDTH = 800; //export const DEFAULT_TABLE_WIDTH = 'inherit';

exports.DEFAULT_TABLE_WIDTH = DEFAULT_TABLE_WIDTH;
var TABLE_TYPE_COMMON = 'COMMON';
exports.TABLE_TYPE_COMMON = TABLE_TYPE_COMMON;
var TABLE_TYPE_RAFS = 'RAFS';
exports.TABLE_TYPE_RAFS = TABLE_TYPE_RAFS;
var TABLE_TYPE_VIRTUAL = 'VIRTUAL';
exports.TABLE_TYPE_VIRTUAL = TABLE_TYPE_VIRTUAL;
var VIRTUAL_REMAIN_COUNT = 3;
exports.VIRTUAL_REMAIN_COUNT = VIRTUAL_REMAIN_COUNT;