var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.tableFiexedClass, style: _vm.fixedStyle },
    [
      _c(
        "section",
        {
          staticClass: "c-table-wrapper__header-wrapper",
          class: _vm.headerClass,
          style: _vm.getTableFixeStyle
        },
        [
          _c("virtual-scroll-table-fixed-header", {
            attrs: {
              "columns-config": _vm.columnsConfig,
              "columns-width": _vm.fixedWidth,
              height: _vm.headerHeight,
              "scroll-syncl-data": _vm.scrollSynclData
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("virtual-scroll-table-fixed-body", {
        attrs: {
          "virtual-items": _vm.virtualItems,
          "table-owner": _vm.tableOwner,
          fixedRight: _vm.fixedRight,
          "record-key": _vm.recordKey,
          "columns-config": _vm.columnsConfig,
          "item-height": _vm.itemHeight,
          "body-height": _vm.bodyHeight,
          "viewport-height": _vm.fixedHeight,
          "viewport-width": _vm.fixedWidth,
          "scroll-syncl-data": _vm.scrollSynclData,
          "enable-select-style": _vm.enableSelectStyle
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }