"use strict";

(function () {
  'use strict';

  var vendors = ['webkit', 'moz'];

  for (var i = 0; i < vendors.length && !window.requestAnimationFrame; ++i) {
    var vp = vendors[i];
    window.requestAnimationFrame = window[vp + "RequestAnimationFrame"];
    window.cancelAnimationFrame = window[vp + "CancelAnimationFrame"] || window[vp + "CancelRequestAnimationFrame"];
  } // iOS6 is buggy


  if (/iP(ad|hone|od).*OS 6/.test(window.navigator.userAgent) || !window.requestAnimationFrame || !window.cancelAnimationFrame) {
    var lastTime_1 = 0;

    window.requestAnimationFrame = function (callback) {
      var now = new Date().getTime();
      var nextTime = Math.max(lastTime_1 + 16, now);
      return setTimeout(function () {
        callback(lastTime_1 = nextTime);
      }, nextTime - now);
    };

    window.cancelAnimationFrame = clearTimeout;
  }
})();