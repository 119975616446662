"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _expand = _interopRequireDefault(require("./tableHelper/expand"));

var _constant = require("./tableHelper/constant");

var _default = {
  name: 'VirtualScrollTableFixedHeader',
  components: {
    RenderHeader: _expand.default
  },
  props: {
    columnsConfig: Array,
    columnsWidth: Number,
    height: Number,
    scrollSynclData: Object
  },
  data: function data() {
    return {
      cIdKey: _constant.ID_NAME
    };
  },
  watch: {
    columnsConfig: {
      handler: function handler(config) {//console.log('handler VirtualScrollTableFixedHeader columnsConfig');
      },
      immediate: true
    }
  },
  computed: {
    getHeaderStyle: function getHeaderStyle() {
      return {
        height: this.height + "px",
        width: this.columnsWidth + "px"
      };
    }
  },
  methods: {
    getColumnStyle: function getColumnStyle(column) {
      return {
        width: column.cWidth,
        //width: `${200}px`,
        height: this.height + "px"
      };
    }
  }
};
exports.default = _default;