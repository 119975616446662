<template>
    <div :class="classes"
         @mouseenter="handleMouseenter"
         @mouseleave="handleMouseleave"
         v-click-outside="handleClose">
        <div :class="[prefixCls + '-rel']"
             ref="reference"
             @click="handleClick"
             @mousedown="handleFocus(false)"
             @mouseup="handleBlur(false)">
            <slot></slot>
        </div>
    </div>
</template>
<script>
    //改元素改为全局挂载
    const _GlobalElement = document.createElement('div');
    document.body.appendChild(_GlobalElement);

    import Vue from "vue";
    import Popper from 'popper.js/dist/umd/popper.js';
    import iButton from 'view-design/src/components/button/button.vue';
    import { directive as clickOutside } from 'v-click-outside-x';
    import TransferDom from 'view-design/src/directives/transfer-dom';
    import { oneOf } from 'view-design/src/utils/assist';
    import { transferIndex, transferIncrease } from 'view-design/src/utils/transfer-queue';
    import Locale from 'view-design/src/mixins/locale';
    import PopperComponent from './poptipComponent.vue';
    //console.log('poptipComponent.default', PopperComponent);
    //let PopperComponent = Vue.extend(poptipComponent);
    const prefixCls = 'ivu-poptip';

    export default {
        name: 'iViewPoptip',
        mixins: [Locale],
        directives: { clickOutside, TransferDom },
        components: { iButton },
        props: {
            trigger: {
                validator(value)
                {
                    return oneOf(value, ['click', 'focus', 'hover']);
                },
                default: 'click'
            },
            placement: {
                validator(value)
                {
                    return oneOf(value, ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end']);
                },
                default: 'top'
            },
            title: {
                type: [String, Number]
            },
            content: {
                type: [String, Number],
                default: ''
            },
            width: {
                type: [String, Number]
            },
            confirm: {
                type: Boolean,
                default: false
            },
            okText: {
                type: String
            },
            cancelText: {
                type: String
            },
            transfer: {
                type: Boolean,
                default()
                {
                    return !this.$IVIEW || this.$IVIEW.transfer === '' ? false : this.$IVIEW.transfer;
                }
            },
            popperClass: {
                type: String
            },
            wordWrap: {
                type: Boolean,
                default: false
            },
            // default by css: 8px 16px
            padding: {
                type: String
            },
            // 3.4.0
            disabled: {
                type: Boolean,
                default: false
            },
            options: {
                type: Object,
                default()
                {
                    return {
                        modifiers: {
                            computeStyle: {
                                gpuAcceleration: false,
                            },
                            preventOverflow: {
                                boundariesElement: 'window'
                            }
                        }
                    };
                }
            }
        },
        data()
        {
            return {
                prefixCls: prefixCls,
                showTitle: true,
                isInput: false,
                disableCloseUnderTransfer: false,  // transfer 模式下，点击 slot 也会触发关闭
                tIndex: this.handleGetIndex(),
                visible: false,
                componentInstance: null,
                popperInstance: null
            };
        },
        computed: {
            classes()
            {
                return [
                    `${prefixCls}`,
                    {
                        [`${prefixCls}-confirm`]: this.confirm
                    }
                ];
            },
            popperClasses()
            {
                return [
                    `${prefixCls}-popper`,
                    {
                        [`${prefixCls}-confirm`]: this.transfer && this.confirm,
                        [`${this.popperClass}`]: !!this.popperClass
                    }
                ];
            },
            styles()
            {
                let style = {};

                if (this.width)
                {
                    style.width = `${this.width}px`;
                }

                if (this.transfer) style['z-index'] = 1060 + this.tIndex;

                return style;
            },
            localeOkText()
            {
                if (this.okText === undefined)
                {
                    return this.t('i.poptip.okText');
                } else
                {
                    return this.okText;
                }
            },
            localeCancelText()
            {
                if (this.cancelText === undefined)
                {
                    return this.t('i.poptip.cancelText');
                } else
                {
                    return this.cancelText;
                }
            },
            contentClasses()
            {
                return [
                    `${prefixCls}-body-content`,
                    {
                        [`${prefixCls}-body-content-word-wrap`]: this.wordWrap
                    }
                ];
            },
            contentPaddingStyle()
            {
                const styles = {};
                if (this.padding !== '') styles['padding'] = this.padding;
                return styles;
            },
        },
        methods: {
            handleClick()
            {
                console.log('handleClick');
                if (this.disabled) return;

                if (this.confirm)
                {
                    this.visible = !this.visible;
                    return true;
                }
                if (this.trigger !== 'click')
                {
                    return false;
                }
                this.visible = !this.visible;
            },
            handleTransferClick()
            {
                if (this.transfer) this.disableCloseUnderTransfer = true;
            },
            handleClose()
            {
                let _$this = this;
                if (!_$this.visible)
                {
                    return;
                }
                let _el = event.target;
                let _elOfCmp = _$this.componentInstance.$refs.popperContent
                while (_el)
                {
                    _el = _el.parentElement;
                    if (_elOfCmp == _el)
                    {
                        //弹出元素是挂在顶层的
                        return;
                    }
                }
                if (_$this.disableCloseUnderTransfer)
                {
                    _$this.disableCloseUnderTransfer = false;
                    return false;
                }
                if (_$this.confirm)
                {
                    _$this.visible = false;
                    return true;
                }
                if (_$this.trigger !== 'click')
                {
                    return false;
                }
                _$this.visible = false;
            },
            handleFocus(fromInput = true)
            {
                if (this.disabled) return;

                if (this.trigger !== 'focus' || this.confirm || (this.isInput && !fromInput))
                {
                    return false;
                }
                this.visible = true;
            },
            handleBlur(fromInput = true)
            {
                if (this.trigger !== 'focus' || this.confirm || (this.isInput && !fromInput))
                {
                    return false;
                }
                this.visible = false;
            },
            handleMouseenter()
            {
                if (this.disabled) return;

                if (this.trigger !== 'hover' || this.confirm)
                {
                    return false;
                }
                if (this.enterTimer) clearTimeout(this.enterTimer);
                this.enterTimer = setTimeout(() =>
                {
                    this.visible = true;
                }, 100);
            },
            handleMouseleave()
            {
                if (this.trigger !== 'hover' || this.confirm)
                {
                    return false;
                }
                if (this.enterTimer)
                {
                    clearTimeout(this.enterTimer);
                    this.enterTimer = setTimeout(() =>
                    {
                        this.visible = false;
                    }, 100);
                }
            },
            cancel()
            {
                this.visible = false;
                this.$emit('on-cancel');
            },
            ok()
            {
                this.visible = false;
                this.$emit('on-ok');
            },
            getInputChildren()
            {
                const $input = this.$refs.reference.querySelectorAll('input');
                const $textarea = this.$refs.reference.querySelectorAll('textarea');
                let $children = null;

                if ($input.length)
                {
                    $children = $input[0];
                } else if ($textarea.length)
                {
                    $children = $textarea[0];
                }

                return $children;
            },
            handleGetIndex()
            {
                transferIncrease();
                return transferIndex;
            },
            handleIndexIncrease()
            {
                this.tIndex = this.handleGetIndex();
            }
        },
        watch: {
            visible: {
                handler: function (
                    val
                )
                {
                    let _$this = this;
                    let _componentInstance = _$this.componentInstance;
                    if (_componentInstance)
                    {
                        _componentInstance.visible = val;
                        _componentInstance.$slots = _$this.$slots;//传递slots
                        let _popper = _$this.popperInstance;
                        if (_popper && val)
                        {
                            _$this.$nextTick(
                                () =>
                                {
                                    _popper.scheduleUpdate();
                                    _popper.update();
                                }
                            );
                        }
                        else
                        {
                            //不设置会挡住后面元素
                            _componentInstance.$el.style = "width:0px,height:0px";
                        }
                    }
                    //let _props = {
                    //    trigger: _$this.trigger,
                    //    title: _$this.title,
                    //    content: _$this.content,
                    //    width: _$this.width,
                    //    confirm: _$this.confirm,
                    //    okText: _$this.okText,
                    //    cancelText: _$this.cancelText,
                    //    transfer: _$this.transfer,
                    //    popperClass: _$this.popperClass,
                    //    wordWrap: _$this.wordWrap,
                    //    padding: _$this.padding,
                    //    disabled: _$this.disabled,
                    //};
                    //console.log(_$this,_componentInstance,_props,_componentInstance.$el.clientWidth);
                },
                immediate: true,
            }
        },
        mounted()
        {
            let _$this = this;
            if (null == _$this.componentInstance)
            {
                let _props = {
                    trigger: _$this.trigger,
                    title: _$this.title,
                    content: _$this.content,
                    width: _$this.width,
                    confirm: _$this.confirm,
                    okText: _$this.okText,
                    cancelText: _$this.cancelText,
                    transfer: _$this.transfer,
                    popperClass: _$this.popperClass,
                    wordWrap: _$this.wordWrap,
                    padding: _$this.padding,
                    disabled: _$this.disabled,
                    owner: _$this
                };
                //console.log(_props);
                let _componentInstance = new PopperComponent(
                    {
                        propsData: _props
                    }
                );
                _componentInstance.$slots = _$this.$slots;//传递slots
                _componentInstance.$on(
                    'row-click',
                    function ()
                    {
                        console.log(
                            'row-click',
                            arguments
                        );
                    }
                );
                _componentInstance.$mount();
                //console.log(_componentInstance);
                _GlobalElement.appendChild(_componentInstance.$el);
                _$this.componentInstance = _componentInstance;
                _$this.popper = _componentInstance.$el;


                let _refEl = _$this.$el;
                //let _slot0 = _$this.$slots;
                //if ( _slot0
                //    && (_slot0 = _slot0.default)
                //    && _slot0.length >= 1
                //)
                //{
                //    _slot0 = 1 == _slot0.length ? _slot0[0] : _slot0.find(slot => slot.elm.nodeType !== 3);//text
                //    if (
                //        _slot0
                //    )
                //    {
                //        _refEl = _slot0.elm;
                //    }
                //}
                //console.log('proper====>', _refEl);

                let _popper = _$this.popperInstance;

                if (null == _popper)
                {
                    const options = _$this.options;
                    if (/^(top|bottom|left|right)(-start|-end)?$/g.test(_$this.placement))
                    {
                        options.placement = _$this.placement;
                    }

                    //这段有问题
                    //if (!options.modifiers.offset) {
                    //    options.modifiers.offset = {};
                    //}
                    //options.modifiers.offset.offset = _$this.offset;

                    options.modifiers.autoSizing = {
                        enabled: true,
                        fn: function (data)
                        {
                            //由于弹出元素会被popjs重新设置style所以需要用这方法附加style属性
                            let _placement = data.placement;//_componentInstance.$el.getAttribute('x-placement');
                            if ('top' == _placement)
                            {
                                _componentInstance.contentStyle = {
                                    'margin-bottom': '16px'
                                };
                                _componentInstance.arrowStyle = {
                                    'bottom': '3px',
                                    'left': '50%',
                                    'border-top-color': '#fff'
                                };
                            }

                            if ('bottom' == _placement)
                            {
                                _componentInstance.contentStyle = {
                                    'margin-top': '16px'
                                };
                                _componentInstance.arrowStyle = {
                                    'top': '3px',
                                    'left': '50%',
                                    'border-bottom-color': '#fff'
                                };
                            }

                            //console.log('x-placement',_placement);
                            let _popperEl = _componentInstance.$refs.popper;
                            let _width = _popperEl ? _popperEl.clientWidth : 0;
                            let _height = _popperEl ? _popperEl.clientHeight : 0;
                            data.styles.width = _width;
                            data.styles.height = _height - 6;//因为用margin-bottom设置尖角，修正位置
                            return data;
                        },
                        order: 840,
                    };
                    options.onCreate = () =>
                    {
                        _popper && _popper.update();
                    };
                    options.onUpdate = () =>
                    {
                    };
                    _popper = new Popper(
                        _refEl,//rel
                        _componentInstance.$el,//显示的元素必需要有正确的长宽，不然定位会有问题
                        options
                    );
                    _$this.popperInstance = _popper;
                }
                else
                {
                    _popper.reference = _refEl;
                    _popper.scheduleUpdate();
                    _popper.update();
                }
            }

            if (!_$this.confirm)
            {
                _$this.showTitle = (_$this.$slots.title !== undefined) || _$this.title;
            }
            if (_$this.trigger === 'focus')
            {
                _$this.$nextTick(() =>
                {
                    const $children = _$this.getInputChildren();
                    if ($children)
                    {
                        _$this.isInput = true;
                        $children.addEventListener('focus', _$this.handleFocus, false);
                        $children.addEventListener('blur', _$this.handleBlur, false);
                    }
                });
            }
        },
        beforeDestroy()
        {
            let _$this = this;
            const $children = _$this.getInputChildren();
            if ($children)
            {
                $children.removeEventListener('focus', _$this.handleFocus, false);
                $children.removeEventListener('blur', _$this.handleBlur, false);
            }

            let _componentInstance = _$this.componentInstance;
            if (_componentInstance)
            {
                let _el = _componentInstance.$el;
                if (_el.remove)
                {
                    _el.remove();
                }
                else if (_el.parentNode && _el.parentNode.removeChild)
                {
                    _el.parentNode.removeChild(_el);
                }
                _componentInstance.$destroy();
            }
            let _popper = _$this.popperInstance;
            if (_popper)
            {
                _popper.destroy();
                _$this.popperInstance = null;
            }
        }
    };
</script>
