"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calDomItemsHeight = exports.getRandomStr = exports.calculateColAndRowSpan = exports.convertColumnsConfigToRow = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var convertColumnsConfigToRow = function convertColumnsConfigToRow(columns, addMultistageHeader) {
  if (addMultistageHeader === void 0) {
    addMultistageHeader = false;
  }

  var cloneColumns = _lodash.default.cloneDeep(columns);

  var result = [];

  for (var _i = 0, cloneColumns_1 = cloneColumns; _i < cloneColumns_1.length; _i++) {
    var column = cloneColumns_1[_i];

    if (!column.children) {
      result.push(column);
      continue;
    }

    if (addMultistageHeader) {
      result.push(column);
    }

    result.push.apply(result, convertColumnsConfigToRow(column.children, addMultistageHeader));
  }

  return result;
};

exports.convertColumnsConfigToRow = convertColumnsConfigToRow;

var buildColumnLevel = function buildColumnLevel(column, parent) {
  if (parent) {
    column.level = parent.level + 1;
  }

  if (column.children) {
    for (var _i = 0, _a = column.children; _i < _a.length; _i++) {
      var childrenColumn = _a[_i];
      buildColumnLevel(childrenColumn, column);
    }
  }
};

var buildColumnColSpan = function buildColumnColSpan(column) {
  if (!column.children) {
    column.colSpan = 1;
    return;
  }

  var colSpan = 0;

  for (var _i = 0, _a = column.children; _i < _a.length; _i++) {
    var childrenColumn = _a[_i];
    buildColumnColSpan(childrenColumn);
    colSpan += childrenColumn.colSpan;
  }

  column.colSpan = colSpan;
};

var getColumnMaxLevel = function getColumnMaxLevel(columns) {
  var levels = columns.map(function (item) {
    return item.level;
  });
  return levels.length > 0 ? Math.max.apply(null, levels) : -1;
};

var buildColumnRowSpan = function buildColumnRowSpan(columns, maxLevel) {
  for (var _i = 0, columns_1 = columns; _i < columns_1.length; _i++) {
    var column = columns_1[_i];
    column.rowSpan = column.children ? 1 : maxLevel - column.level + 1;
  }
};

var buildColAndRowSpanRow = function buildColAndRowSpanRow(columns, maxLevel) {
  var rows = [];

  for (var i = 0; i < maxLevel; i++) {
    rows.push([]);
  }

  for (var _i = 0, columns_2 = columns; _i < columns_2.length; _i++) {
    var column = columns_2[_i];
    rows[column.level - 1].push(column);
  }

  return rows;
};

var calculateColAndRowSpan = function calculateColAndRowSpan(columns) {
  var cloneColumns = _lodash.default.cloneDeep(columns);

  for (var _i = 0, cloneColumns_2 = cloneColumns; _i < cloneColumns_2.length; _i++) {
    var column = cloneColumns_2[_i];
    column.level = 1;
    buildColumnLevel(column);
    buildColumnColSpan(column);
  }

  var multistageHeaderColumns = convertColumnsConfigToRow(cloneColumns, true);
  var maxLevel = getColumnMaxLevel(multistageHeaderColumns);
  buildColumnRowSpan(multistageHeaderColumns, maxLevel);
  return buildColAndRowSpanRow(multistageHeaderColumns, maxLevel);
};

exports.calculateColAndRowSpan = calculateColAndRowSpan;

var getRandomStr = function getRandomStr(len) {
  if (len === void 0) {
    len = 32;
  }

  var $chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  var maxPos = $chars.length;
  var str = '';

  for (var i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxPos));
  }

  return str;
};

exports.getRandomStr = getRandomStr;

var calculateDomItemsMinHeight = function calculateDomItemsMinHeight(itemHeight, remainHeight, scrollTop) {
  return scrollTop > remainHeight ? Math.floor((scrollTop - remainHeight) / itemHeight) * itemHeight : 0;
};

var calculateDomItemsMaxHeight = function calculateDomItemsMaxHeight(itemHeight, remainHeight, viewPortHeight, renderItemsHeight, scrollTop) {
  return scrollTop > remainHeight ? Math.ceil((scrollTop + remainHeight + viewPortHeight) / itemHeight) * itemHeight : renderItemsHeight;
};

var calDomItemsHeight = function calDomItemsHeight(itemHeight, remainHeight, viewPortHeight, renderItemsHeight, scrollTop) {
  var minHeight = calculateDomItemsMinHeight(itemHeight, remainHeight, scrollTop);
  var maxHeight = calculateDomItemsMaxHeight(itemHeight, remainHeight, viewPortHeight, renderItemsHeight, scrollTop);
  return [minHeight, maxHeight];
};

exports.calDomItemsHeight = calDomItemsHeight;