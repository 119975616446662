"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.array.find");

var _vue = _interopRequireDefault(require("vue"));

var _vueResource = _interopRequireDefault(require("vue-resource"));

var _viewDesign = _interopRequireWildcard(require("view-design"));

var _zhCN = _interopRequireDefault(require("view-design/dist/locale/zh-CN"));

var _vueVirtualScrollTable = _interopRequireDefault(require("./components/vue-virtual-scroll-table"));

var _iviewUnionTable = _interopRequireDefault(require("./components/iview-union-table"));

var _iViewPoptip = _interopRequireDefault(require("./components/iViewPoptip"));

_vue.default.use(_vueResource.default);

_vue.default.use(_viewDesign.default, {
  locale: _zhCN.default
}); //Vue.use(IView, {
//	locale,
//	transfer: true,
//	size: 'small',
//	capture: false,
//	select: {
//		arrow: 'md-arrow-dropdown',
//		arrowSize: 20
//	}
//});
//IView.install(Vue);
//IView Poptip 扩展


var _Poptip = _viewDesign.Poptip;

(function () {
  //弹出面板，增加一个直接确认功能：
  //当directConfirm返回true时，不弹出确认面板，直接运行'on-ok'上绑定的函数
  _Poptip.props.directConfirm = {
    type: Function,
    default: null
  };
  _Poptip.props.disableConfirm = {
    type: Function,
    default: null
  };
  var _handleClick = _Poptip.methods.handleClick;

  _Poptip.methods.handleClick = function () {
    if (this.confirm) {
      if (this.directConfirm && this.directConfirm()) {
        console.log(this); //直接确认

        this.ok();
        return true;
      }

      if (this.disableConfirm && this.disableConfirm()) {
        //禁用气泡提示
        return true;
      }

      var _slot0 = this.$slots;
      var _child0_1 = this.$el.firstElementChild; //rel

      if (_child0_1) {
        _child0_1 = _child0_1.firstElementChild; //slot
      }

      if (_child0_1 && _slot0 && (_slot0 = _slot0.default) && _slot0.length >= 1) {
        _slot0 = 1 == _slot0.length ? _slot0[0] : _slot0.find(function (slot) {
          return slot.elm == _child0_1;
        });

        if (_slot0 && (_slot0 = _slot0.componentInstance) && (_slot0.disabled || _slot0.loading)) {
          //禁用气泡提示
          return true;
        }
      }
    }

    return _handleClick.apply(this, arguments);
  };
})(); //IView Tree 扩展


var _Tree = _viewDesign.Tree;

(function () {
  //树弄控件增加一个查看模式，禁点击check
  _Tree.props.onlyForView = {
    type: Boolean,
    default: false
  };
  var _handleCheck = _Tree.methods.handleCheck;

  _Tree.methods.handleCheck = function () {
    if (this.onlyForView) {
      return;
    }

    return _handleCheck.apply(this, arguments);
  };
})(); //IView InputNumber 扩展


var _InputNumber = _viewDesign.InputNumber;

(function () {
  var _handleKeyDown = _InputNumber.methods.keyDown;

  _InputNumber.methods.keyDown = function (e) {
    if (e.keyCode === 13) {
      this.$emit('on-enter', event);
    } else {
      _handleKeyDown.apply(this, arguments);
    }
  };
})(); //IView Upload 扩展


var _Upload = _viewDesign.Upload;

(function () {
  _Upload.props.beforeClick = {
    type: Function,
    default: null
  };
  var _handleClick = _Upload.methods.handleClick;

  _Upload.methods.handleClick = function () {
    if (!!this.beforeClick && !this.beforeClick()) {
      return;
    }

    _handleClick.apply(this, arguments);
  };

  var _uploadFiles = _Upload.methods.uploadFiles;

  _Upload.methods.uploadFiles = function () {
    var _files = arguments[0];
    _files = Array.prototype.slice.call(_files);
    arguments[0] = _files.sort(function (a, b) {
      //上传文件，按修改时间排序
      return a.lastModified - b.lastModified;
    });
    console.log(_files);

    _uploadFiles.apply(this, arguments);
  };
})();

if (!!window.ActiveXObject || -1 != navigator.userAgent.indexOf('.NET CLR') || -1 != navigator.userAgent.indexOf('MSIE')) {
  //IE 下还是用 IView Table
  _vue.default.component('VueVirtualScrollTable', _viewDesign.Table); //封装成组建

} else {
  _vue.default.component('VueVirtualScrollTable', _vueVirtualScrollTable.default); //封装成组建

}

_vue.default.component('UnionTable', _iviewUnionTable.default); //封装成组建


_vue.default.component('ViewPoptip', _iViewPoptip.default); //封装成组建


var _IView = _viewDesign.default;
_vue.default.prototype.$LoadingBar = _IView.LoadingBar;
_vue.default.prototype.$Message = _IView.Message;
_vue.default.prototype.$Notice = _IView.Notice;
_vue.default.prototype.$Modal = _IView.Modal;
_vue.default.config.productionTip = false;
var _default = _vue.default;
exports.default = _default;