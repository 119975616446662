"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _expand = _interopRequireDefault(require("./tableHelper/expand"));

var _default = {
  name: 'VirtualScrollTableFixedBody',
  components: {
    RenderBody: _expand.default
  },
  props: {
    //主表体组件this
    tableOwner: Object,
    columnsConfig: Array,
    virtualItems: Object,
    recordKey: String,
    itemHeight: Number,
    bodyHeight: Number,
    viewportHeight: Number,
    viewportWidth: Number,
    scrollSynclData: Object,
    fixedRight: Boolean,
    //是否启用选中样式
    enableSelectStyle: {
      type: Boolean,
      default: false
    }
  },
  //watch: {
  //    virtualItems: {
  //        handler: function (val)
  //        {
  //            //this.renderData = val.renderData;
  //            //this.renderData = _.cloneDeep(val.renderData);
  //            //console.log('watch virtualItems', val);
  //        },
  //        immediate: true,
  //        deep: true,
  //    },
  //},
  data: function data() {
    return {};
  },
  computed: {
    getTableFixeStyle: function getTableFixeStyle() {
      //固定列样式
      var _$this = this;

      if (_$this.fixedRight) {
        return {
          height: _$this.getBodyHeight,
          width: _$this.getBodyWidth,
          "overflow-x": "hidden",
          "overflow-y": "hidden",
          "border-right": "1px none #dddddd" //根据固定情况移除对应边框

        };
      }

      return {
        height: _$this.getBodyHeight,
        width: _$this.getBodyWidth,
        "overflow-x": "hidden",
        "overflow-y": "hidden",
        "border-left": "1px none #dddddd" //根据固定情况移除对应边框

      };
    },
    getRecordHeight: function getRecordHeight() {
      return this.itemHeight + "px";
    },
    getBodyHeight: function getBodyHeight() {
      return this.viewportHeight + "px";
    },
    getBodyWidth: function getBodyWidth() {
      return this.viewportWidth + "px";
    },
    getBodyWrapperStyle: function getBodyWrapperStyle() {
      //表体宽度
      return {
        //overflow-y: scroll 元素Y坐标自动向上偏移的。
        //"overflow-y":"hidden" 元素Y坐标是固定的。
        transform: "translateY(" + -this.scrollSynclData.scrollTop + "px)",
        height: this.bodyHeight + "px",
        width: this.viewportWidth + "px",
        position: 'relative'
      };
    }
  },
  methods: {
    handleRowClick: function handleRowClick(record) {
      var _index = record.__dataIndex;
      this.scrollSynclData.clicked_index = _index; //console.log('click', _index, JSON.stringify(record));

      this.tableOwner.$emit('on-row-click', JSON.parse(JSON.stringify(record)), _index);
    },
    handleRowDblClick: function handleRowDblClick(record) {
      var _index = record.__dataIndex;
      this.tableOwner.$emit('on-row-dblclick', JSON.parse(JSON.stringify(record)), _index);
    },
    handleMouseIn: function handleMouseIn(vkey) {
      this.scrollSynclData.hover_index = vkey;
    },
    handleMouseOut: function handleMouseOut(vkey) {
      this.scrollSynclData.hover_index = -1;
    },
    getColumnStyle: function getColumnStyle(column) {
      return {
        width: column.cWidth,
        height: this.itemHeight + "px"
      };
    },
    getRowWrapperStyle: function getRowWrapperStyle(record) {
      var _$this = this;

      var _isChecked = _$this.scrollSynclData.clicked_index == record.__dataIndex;

      ;

      var _isHover = _isChecked || _$this.scrollSynclData.hover_index == record.__dataIndex;

      if (!_isHover) {
        return {
          'transform': "translateY(" + record.translateY + ")",
          'height': _$this.itemHeight + "px"
        };
      } //指中行样式


      return {
        'transform': "translateY(" + record.translateY + ")",
        'height': _$this.itemHeight + "px",
        'box-shadow': '0px 2px 6px -2px rgba(0,0,0,.2)',
        'background-color': "#ebf7ff",
        'margin-top': "-1px",
        'border-top': "1px solid #dddddd" //背景会挡住上一行的下边框显示

      };
    },
    getRowContainerStyle: function getRowContainerStyle(record) {
      var _$this = this; //不分左右了,取消 enableSelectStyle 判断
      //if (!_$this.enableSelectStyle)
      //{
      //    return {
      //        'height': _$this.getRecordHeight
      //    };
      //}


      var _isChecked = _$this.scrollSynclData.clicked_index == record.__dataIndex;

      ;

      var _isHover = _isChecked || _$this.scrollSynclData.hover_index == record.__dataIndex;

      return {
        'box-shadow': _isHover ? '0px 2px 6px 0px rgba(0,0,0,.2)' : '',
        'height': _$this.getRecordHeight
      };
    }
  }
};
exports.default = _default;