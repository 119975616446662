"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.regexp.search");

function GenAuthUnitList(menuAry) {
  var _authULis = [];

  for (var _kLv1 in menuAry) {
    var _oLv1 = menuAry[_kLv1];
    var _childAry = [];

    for (var _kLv2 in _oLv1.Subs) {
      var _oLv2 = _oLv1.Subs[_kLv2];

      _childAry.push({
        title: _oLv2.Name,
        AuthUnitToken: _oLv2.AuthUnitToken,
        checked: false
      });
    }

    _authULis.push({
      title: _oLv1.Route.Name,
      expand: true,
      children: _childAry,
      checked: false
    });
  }

  return _authULis;
}

function GenerUserMenuArray(user, menuAry, findCurretnUrl) {
  var _authUnitLis = user.AuthUnitCodeList;
  var _menuAry = [];

  var _allAuth = _authUnitLis && _authUnitLis.length > 0 && _authUnitLis[0] == "*";

  for (var _i = 0, _iCnt = menuAry.length; _i < _iCnt; _i++) {
    var _menu = menuAry[_i];
    var _subAry = [];
    var _subs = _menu.Subs;

    for (var _s = 0, _sCnt = _subs.length; _s < _sCnt; _s++) {
      var _sub = _subs[_s];

      if (_allAuth || _sub.ForGuest || -1 != _authUnitLis.indexOf(_sub.AuthUnitToken)) {
        _sub = JSON.parse(JSON.stringify(_sub));
        findCurretnUrl && findCurretnUrl(_menuAry.length, _subAry.length, _sub); //获取当前访问路径

        _subAry.push(_sub);
      }
    }

    if (_subAry.length > 0) {
      _menu = JSON.parse(JSON.stringify(_menu));
      _menu.Subs = _subAry;

      _menuAry.push(_menu);
    }
  }

  return _menuAry;
}

function GetCheckedAuthLis(refAuthTree) {
  var _chkNodeLis = refAuthTree.getCheckedNodes();

  var _authLis = [];

  for (var _i = 0; _i < _chkNodeLis.length; _i++) {
    var _authToken = _chkNodeLis[_i].AuthUnitToken;

    if (!_authToken) {
      continue;
    }

    _authLis.push({
      AuthUnitToken: _authToken
    });
  }

  return _authLis;
}

function DecodeWindowSearch() {
  var _winLoca = window.location; //http://piao.feikongbao.net:7005?ViewName=InvoiceOCRScan
  //hash模式 通过?ViewName=InvoiceOCRScan访问网站跳转至对应页面

  var _search = _winLoca.search;

  if (_search.length > 0 && _search[0] == "?") {
    _search = _search.substring(1);
  }

  var _searchDict = {};

  if (_search) {
    var _searchTplAry = _search.split('&');

    for (var _t = 0; _t < _searchTplAry.length; _t++) {
      _search = _searchTplAry[_t];

      var _sIndex = _search.indexOf('=');

      var _searchKey = _search.substring(0, _sIndex);

      var _searchValue = _search.substring(_sIndex + 1);

      _searchDict[_searchKey] = _searchValue;
    }
  }

  return _searchDict;
}

var _default = {
  GenAuthUnitList: GenAuthUnitList,
  GetCheckedAuthLis: GetCheckedAuthLis,
  GenerUserMenuArray: GenerUserMenuArray,
  DecodeWindowSearch: DecodeWindowSearch
};
exports.default = _default;