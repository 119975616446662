"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es7.string.pad-end");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.object.assign");

var _vue = _interopRequireDefault(require("vue"));

var _decimal = require("decimal.js");

var _HTMLElementUtils = require("./HTMLElementUtils");

var _iLookUpEdit = _interopRequireDefault(require("../components/iLookUpEdit/iLookUpEdit"));

function SetInputsValue(dict, targetKey, row) {
  if (dict && row) {
    for (var _dkey in dict) {
      if (targetKey == _dkey) {
        continue;
      }

      dict[_dkey].value = row[_dkey];
    }
  }
}

function LookUpEditRenderCtor( //valueFieldOfLookUp,
keyFieldOfLookUp, columnsOfLookUp, dataOfLookUp, filterMethodOfLookUp, pageCountOfLookUp) {
  if (pageCountOfLookUp === void 0) {
    pageCountOfLookUp = 6;
  } //默认参数语法打包时支持不完善，改成这种形式


  pageCountOfLookUp = typeof pageCountOfLookUp !== 'undefined' ? pageCountOfLookUp : 6;
  
  return function (data, field, rowChangeCb, inputsDict) {
    var _key = field.key;
    return function (h, params) {
      var _index = params.index;
      return h(_iLookUpEdit.default, {
        props: {
          size: 'small',
          value: data[_index][_key],
          //valueField: valueFieldOfLookUp,
          keyField: keyFieldOfLookUp,
          columns: columnsOfLookUp,
          data: dataOfLookUp,
          filterMethod: filterMethodOfLookUp,
          pageCount: pageCountOfLookUp
        },
        on: {
          'input': function input(val, rowOfLookUp) {
            

            var _row = data[_index] = params.row;

            _row[_key] = val;

            if (rowChangeCb) {
              rowChangeCb(_index, _key, _row, rowOfLookUp);
              SetInputsValue(inputsDict[_index], _key, _row);
            }
          }
        }
      });
    };
  };
}

function InputRenderCtor() {
  //加法：add
  //减法：sub
  //乘法：mul
  //除法：div
  return function (data, field, rowChangeCb, inputsDict) {
    var _cvNumber = 'number' == field.type;

    var _key = field.key;
    return function (h, params) {
      var _index = params.index;

      var _vNodeOfInput = h('Input', {
        hook: null == rowChangeCb ? {} : {
          'create': function create(vNode0, vNode1) {
            //console.log('create', _index, _key, data[_index][_key]);
            _vue.default.nextTick(function () {
              var _inputs = vNode1.elm.getElementsByTagName('input');

              if (_inputs.length > 0) {
                var _input = _inputs[0];

                var _dict = inputsDict[_index] || {};

                _dict[_key] = _input;
                inputsDict[_index] = _dict;
              }
            });
          }
        },
        props: {
          type: 'text',
          size: 'small',
          value: params.row[_key]
        },
        style: {},
        on: {
          'input': function input(ev) {}
        },
        nativeOn: _cvNumber ? {
          'input': function input(ev) {
            return handlerInput(params, ev);
          },
          'keydown': function keydown(ev) {
            return handlerKeyDown(params, ev);
          }
        } : {
          'input': function input(ev) {
            return handlerInput(params, ev);
          }
        }
      });

      return _vNodeOfInput;
    };

    function handlerKeyDown(params, event) {
      if (event.ctrlKey) {
        return true;
      }

      var _target = event.target;

      if (9 == event.keyCode || 13 == event.keyCode || 8 == event.keyCode //退格
      || 37 == event.keyCode //左
      || 39 == event.keyCode //右
      //|| 38 == event.keyCode//上
      //|| 40 == event.keyCode//下
      ) {
          return true;
        }

      var _val = _target.value; //旧IE上没有event.key属性；使用keyCode做判断；符号"."的 keyCode是190 ,小键盘上 keyCode是110

      if ((190 == event.keyCode || 110 == event.keyCode) && _val.length > 0 && _val.indexOf('.') == -1) {
        return true;
      } //旧IE上没有event.key属性；使用keyCode做判断；符号"-"的 keyCode是189 ,小键盘上 keyCode是109


      if ((189 == event.keyCode || 109 == event.keyCode) && 0 == _target.selectionStart && _val[0] != '-') {
        return true;
      }

      if (event.keyCode >= 48 && event.keyCode <= 57 || //小键盘数字
      event.keyCode >= 96 && event.keyCode <= 105) {
        return true;
      }

      return (0, _HTMLElementUtils.StopEvent)(event);
    }

    ;

    function handlerInput(params, event) {
      if (event.ctrlKey) {
        return true;
      }

      var _index = params.index;
      var _target = event.target;
      var _val = _target.value;

      if (_cvNumber) {
        if (_val.length == 0) {
          _target.value = _val = "0";
        } //else
        //{
        //  _target.value
        //    = _val
        //    = _val.replace(/[^\d.]/g, "")
        //      .replace(/^\./g, "")
        //      .replace(/\.{2,}/g, ".")
        //      .replace(".", "$#$")
        //      .replace(/\./g, "")
        //      .replace("$#$", ".")
        //      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        //}

      }

      if (params.row[_key] === _val) {
        return;
      }

      _vue.default.nextTick(function () {
        var _data = data;

        if (typeof data === "function") {
          _data = data();
        }

        var _row = _data[_index] = params.row;

        _row[_key] = _val;

        if (rowChangeCb) {
          rowChangeCb(_index, _key, _row, null);
          SetInputsValue(inputsDict[_index], _key, _row);
        }
      });
    }

    ;
  };
}
/**
 * 创建表格列信息
 * @param fieldAry  表格列基础信息
 * @param showIndex 是否显示发票编号
 * @param genFn
 * @param data
 * @param rowChangeCb
 */


function CreateColumnAry(fieldAry, showIndex, genFn, data, rowChangeCb) {
  if (!fieldAry) {
    return [];
  }

  var _colAry = [];
  var _inputsDict = {};

  if (showIndex == true) {
    _colAry.push({
      type: 'index',
      width: 40,
      align: 'center'
    });
  }

  for (var _c = 0, _cCnt = fieldAry.length; _c < _cCnt; _c++) {
    var _field = fieldAry[_c];

    var _renderFn = _field.genFn ? _field.genFn(data, _field, rowChangeCb, _inputsDict) : 'static' != _field.type && genFn && data ? genFn(data, _field, rowChangeCb, _inputsDict) : _field.render;

    var _colObj = Object.assign({}, _field, {
      render: _renderFn
    });

    _colAry.push(_colObj);
  }

  return _colAry;
}

function CreateInputColumnAry(fieldAry, showIndex, data, rowChangeCb) {
  return CreateColumnAry(fieldAry, showIndex, InputRenderCtor(), data, rowChangeCb);
} //出现不存在index时 直接返回=>[]


function NewArrayByIndexs(array, indexAry) {
  var _array = [];

  for (var _d = 0, _dCnt = indexAry.length; _d < _dCnt; _d++) {
    var _dr = array[indexAry[_d]];

    if (!_dr) {
      return [];
    }

    _array.push(_dr);
  }

  return _array;
} //无数据返回=>[]


function GetCheckedIndexAry(table) {
  //IView Table 组件没提供对应Api，暂时用这方法取选中的数据 
  if (null == table) {
    return [];
  }

  if (typeof table.getCheckedIndexAry == "function") {
    return table.getCheckedIndexAry();
  }

  var _obj = table.objData;

  if (null == _obj) {
    return [];
  }

  var _indexLis = new Array();

  for (var _index in _obj) {
    if (_obj[_index]._isChecked) {
      _indexLis.push(parseInt(_index));
    }
  }

  return _indexLis;
}

function GetFocusdIndex(table) {
  //IView Table 组件没提供对应Api，暂时用这方法取选中的数据 
  if (null == table) {
    return -1;
  }

  if (typeof table.getFocusdIndex == "function") {
    return table.getFocusdIndex();
  }

  var _obj = table.objData;

  if (null == _obj) {
    return -1;
  }

  for (var _index in _obj) {
    if (_obj[_index]._isHighlight) {
      return parseInt(_index);
    }
  }

  return -1;
} //表汇总函数，支持SummaryType:SUM/AVG/MIN/MAN/COUNT


function TableSummays(data, fieldAry) {
  //  检测需要汇总的字段是否为空
  var _summaryObj = {};

  if (!fieldAry || !fieldAry.length) {
    return _summaryObj;
  } //  动态创建汇总后保存数据的字段


  for (var _f = 0, _fCnt = fieldAry.length; _f < _fCnt; _f++) {
    var _field = fieldAry[_f];

    if (!_field.SummayName) {
      _field.SummayName = _field.FieldName;
    }

    _summaryObj[_field.SummayName] = new _decimal.Decimal(0);
  } //  检测需要汇总的数据是否为空


  if (!data || !data.length) {
    SummarysFix();
    return _summaryObj;
  }

  for (var _d = 0, _dCnt = data.length; _d < _dCnt; _d++) {
    var _dr = data[_d];

    for (var _f = 0, _fCnt = fieldAry.length; _f < _fCnt; _f++) {
      var _field = fieldAry[_f];
      var _sumyName = _field.SummayName;
      var _summary = _summaryObj[_sumyName];

      var _sumType = ("" + _field.SummaryType).toUpperCase();

      if ("COUNT" == _sumType) {
        _summaryObj[_sumyName] = _summary.add(1);
        continue;
      }

      var _dVal = _dr[_field.FieldName];

      if ('string' == typeof _dVal) {
        _dVal = _dVal.replace(/,/g, "");
      }

      var _dIsNaN = isNaN(parseFloat(_dVal));

      if (_dIsNaN) {
        continue;
      }

      switch (_sumType) {
        case "MIN":
          if (0 == _d || _summary.greaterThan(_dVal)) {
            _summaryObj[_sumyName] = new _decimal.Decimal(_dVal);
          }

          break;

        case "MAX":
          if (_summary.lessThan(_dVal)) {
            _summaryObj[_sumyName] = new _decimal.Decimal(_dVal);
          }

          break;

        case "AVG":
        case "SUM":
        default:
          _summaryObj[_sumyName] = _summary.add(_dVal);
          break;
      }
    }
  }

  SummarysFix();
  return _summaryObj;

  function SummarysFix() {
    for (var _f = 0, _fCnt = fieldAry.length; _f < _fCnt; _f++) {
      var _field = fieldAry[_f];
      var _sumyName = _field.SummayName;

      var _sumType = ("" + _field.SummaryType).toUpperCase();

      var _summary = _summaryObj[_sumyName];

      if ("AVG" == _sumType) {
        _summary = _summary.equals(0) ? _summary : _summary.div(fieldAry.length);
      }

      if (_field.Formater) {
        //有格式化函数的情况下直接用格式化
        _summaryObj[_sumyName] = _field.Formater(_summary);
      } else {
        if (_field.Fixed && !isNaN(_field.Fixed)) {
          _summary = _summary.toFixed(_field.Fixed);
        }

        _summaryObj[_sumyName] = parseFloat(_summary).toLocaleString();
      }
    }
  }
}

function FocusInputElement(refEl) {
  if (refEl && refEl.$el && refEl.$el.getElementsByTagName) {
    var _imputAry = refEl.$el.getElementsByTagName('input');

    if (_imputAry.length > 0) {
      _imputAry[0].focus();

      _imputAry[0].select();
    }

    return;
  }

  if ("function" == typeof refEl.focus && "function" == typeof refEl.select) {
    refEl.focus();
    refEl.select();
  }
}

function RenderDataTime(h, day) {
  if (null == day || !day || "" == day) {
    return h('span');
  }

  day = new Date(day);
  return h('span', DateTime2YYYMMDD(day));
}

function DateTime2YYYMMDD(day) {
  day = new Date(day);
  return day.getFullYear() + "/" + (day.getMonth() + 1) + "/" + day.getDate();
}

var RenderNumber = function RenderNumber(h, params) {
  var _value = params.row[params.column.key];
  return h('span', NumberFormat(_value));
}; //const RenderBoolean = (h, params) =>
//{
//  let _value: boolean = params.row[params.column.key];
//  if (!!_value)
//  {
//    return h('Icon', {
//      props: {
//        type: 'checkmark',
//        color: '#2d8cf0',
//        size: 16
//      }
//    });
//  }
//}


var IndexRender = function IndexRender(h, params, focIndex) {
  var _index = params.index;
  return h('span', [_index + 1, ' ', _index === focIndex ? h('Icon', {
    props: {
      type: 'ios-checkmark-circle',
      size: 16,
      color: '#2d8cf0'
    }
  }) : '']);
};

var BooleanRender = function BooleanRender(h, params, conditionFunction) {
  var _value;

  if (typeof conditionFunction === "function") {
    _value = conditionFunction();
  } else {
    _value = params.row[params.column.key];
  }

  if (!!_value) {
    return h('Icon', {
      props: {
        type: 'md-checkmark',
        color: '#2d8cf0',
        size: 16
      }
    });
  }
};

var MonthRender = function MonthRender(h, params) {
  var _dateString = params.row[params.column.key];

  if (!!_dateString) {
    var _date = new Date(_dateString);

    return h('span', _date.getFullYear() + "-" + (_date.getMonth() + 1));
  }
};

var DateRender = function DateRender(h, params) {
  var _dateString = params.row[params.column.key];

  if (!!_dateString) {
    var _date = new Date(_dateString);

    return h('span', _date.getFullYear() + "/" + (_date.getMonth() + 1) + "/" + _date.getDate());
  }
};

var LongDateRender = function LongDateRender(h, params) {
  var _dateString = params.row[params.column.key];

  if (!!_dateString) {
    var _date = new Date(_dateString);

    return h('span', _date.getFullYear() + "/" + (_date.getMonth() + 1) + "/" + _date.getDate() + "\r\n" + _date.getHours() + ":" + _date.getMinutes() + ":" + _date.getSeconds());
  }
};
/**
 * 根据 invColOrdery 排序列设置
 * @param tableColumn
 * @param invColOrdery
 * @param invColRemove
 */


var OrderTalbeColumn = function OrderTalbeColumn(tableColumn, invColOrdery, invColRemove) {
  if (null != invColRemove) {
    tableColumn = tableColumn.filter(function (o) {
      return -1 == invColRemove.indexOf(o.title);
    });
  }

  if (invColOrdery) {
    var _colAryFind = [];
    var _colAryNotFind = [];

    for (var _c = 0; _c < tableColumn.length; _c++) {
      var _col = tableColumn[_c];

      var _index = invColOrdery.indexOf(_col.title);

      if (_index < 0) {
        _colAryNotFind.push(_col);
      } else {
        _colAryFind[_index] = _col;
      }
    }

    _colAryFind = _colAryFind.filter(function (o) {
      return o != null;
    });

    _colAryFind.push.apply(_colAryFind, _colAryNotFind);

    return _colAryFind;
  }

  return tableColumn;
};

var GenerateFieldAry = function GenerateFieldAry(tableColumnAry) {
  var _columnAry = [];

  if (tableColumnAry) {
    _columnAry = tableColumnAry.map(function (m) {
      return {
        Key: m.key,
        Title: m.title,
        Map: m.map
      };
    });
  }

  return _columnAry;
};

function NumberFormat(val, dec) {
  if (dec === void 0) {
    dec = 2;
  } //千分位 ，保留两位小数
  //if (null == val || 0 == val)
  //{
  //	return '';
  //}


  val = parseFloat(val || 0).toLocaleString();

  var _index = val.lastIndexOf('.');

  if (-1 == _index) {
    val += '.'.padEnd(dec + 1, '0');
  } else if (2 == val.length - _index) {
    var _decLen = val.length - _index - 1;

    if (_decLen < dec) {
      val = val.padEnd(val.length + (dec - _decLen), '0');
    }
  }

  return val;
}

var _default = {
  NumberFormat: NumberFormat,
  CreateColumnAry: CreateColumnAry,
  CreateInputColumnAry: CreateInputColumnAry,
  GetCheckedIndexAry: GetCheckedIndexAry,
  TableSummays: TableSummays,
  NewArrayByIndexs: NewArrayByIndexs,
  FocusInputElement: FocusInputElement,
  LookUpEditRenderCtor: LookUpEditRenderCtor,
  RenderDataTime: RenderDataTime,
  DateTime2YYYMMDD: DateTime2YYYMMDD,
  RenderNumber: RenderNumber,
  GetFocusdIndex: GetFocusdIndex,
  IndexRender: IndexRender,
  BooleanRender: BooleanRender,

  /** 月份渲染 */
  MonthRender: MonthRender,

  /** 日期渲染 */
  DateRender: DateRender,

  /** 长日期渲染 */
  LongDateRender: LongDateRender,
  OrderTalbeColumn: OrderTalbeColumn,
  ArraySplice0_Then_Push: function ArraySplice0_Then_Push(ary, aryNew) {
    if (ary) {
      ary.splice(0);
      ary.push.apply(ary, aryNew || []);
    }
  },
  EmnuToDict: function EmnuToDict(enumType) {
    var _dict = {};

    var _keyAry = Object.keys(enumType);

    for (var _k = 0; _k < _keyAry.length; _k++) {
      var _key = _keyAry[_k];
      var _txt = ExamineResultText[ExamineResult[_key]];

      if (null != _txt) {
        _dict[_key] = _txt;
      }
    }

    return _dict;
  },
  GenerateFieldAry: GenerateFieldAry
}; ////动态挂载组件
//let ShowBox =
//  (function ()
//  {
//    const _InoviceSpiltBox = require('./InoviceSpiltBox').default
//    let $Component = Vue.extend(_InoviceSpiltBox)
//    console.log(_InoviceSpiltBox);
//    var $div = document.createElement("div");
//    //添加到页面   
//    document.body.appendChild($div);
//    let _cmp: any = new $Component();
//    _cmp.CallBack = function (v)
//    {
//      console.log(v);
//      BillInvDtlSplit(_DataBillInvDtl[0], 1, v);
//    }
//    _cmp.$mount($div)
//    return function ()
//    {
//      console.log('showBox');
//      _cmp.IsShow = true;
//    }
//  })();

exports.default = _default;