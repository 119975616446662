"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

var _expand = _interopRequireDefault(require("./tableHelper/expand"));

var VmProps =
/** @class */
function () {
  function VmProps() {
    //主表体组件this
    this.tableOwner = Object;
    this.hiddenVerticalScroll = Boolean;
    this.columnsConfig = Array;
    this.data = Array;
    this.recordKey = String;
    this.fixedLeftWidth = Number;
    this.fixedRightWidth = Number;
    this.unFixedWidth = Number;
    this.itemHeight = Number;
    this.viewportHeight = Number;
    this.viewportWidth = Number;
    this.scrollSynclData = Object;
    this.autoResetScroll = Boolean;
    this.showLeftBorder = {
      type: Boolean,
      default: false
    };
  }

  return VmProps;
}();

var VmData =
/** @class */
function () {
  function VmData(vmThis) {}

  return VmData;
}();

var VmComputed =
/** @class */
function () {
  function VmComputed() {
    this.getTableWrapperStyle = function () {
      return {
        'height': this.viewportHeight + "px",
        'width': this.viewportWidth + "px",
        'overflow-x': this.hiddenVerticalScroll ? 'hidden' : 'scroll' //hidden

      };
    };

    this.getHeaderTableStyle = function () {
      // style="box-shadow: 2px 0 6px -2px rgba(0,0,0,.2);box-sizing:border-box"
      return {
        'width': this.unFixedWidth + "px"
      };
    };
  }

  return VmComputed;
}();

var VmMethods =
/** @class */
function () {
  function VmMethods() {
    this.handleRowClick = function (record) {
      var _$this = this;

      var _index = record.__dataIndex;
      _$this.scrollSynclData.clicked_index = _index;
      

      _$this.tableOwner.$emit('on-row-click', JSON.parse(JSON.stringify(record)), _index);
    };

    this.handleRowDblClick = function (record) {
      var _$this = this;

      var _index = record.__dataIndex;
      console.log('dblClick');

      _$this.tableOwner.$emit('on-row-dblclick', JSON.parse(JSON.stringify(record)), _index);
    };

    this.handleMouseIn = function (vkey) {
      this.scrollSynclData.hover_index = vkey;
    };

    this.handleMouseOut = function (vkey) {
      this.scrollSynclData.hover_index = -1;
    };

    this.getColumnStyle = function (column) {
      return {
        width: column.cWidth,
        height: this.itemHeight + "px"
      };
    };

    this.getColumnWidth = function (column) {
      return column.cWidth ? column.cWidth.replace('px', '') : 0;
    };

    this.getRowWrapperStyle = function (record) {
      var _$this = this;

      var _isChecked = _$this.scrollSynclData.clicked_index == record.__dataIndex;

      ;

      var _isHover = _isChecked || _$this.scrollSynclData.hover_index == record.__dataIndex;

      var _color = _isHover ? "#ebf7ff" : "";

      return {
        'height': _$this.itemHeight + "px",
        'background-color': _color
      };
    };
  }

  return VmMethods;
}();

var _default = {
  name: 'TableBody',
  components: {
    RenderBody: _expand.default
  },
  props: new VmProps(),
  watch: {},
  data: function data() {
    return new VmData(this);
  },
  computed: new VmComputed(),
  methods: new VmMethods()
};
exports.default = _default;