"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Browser = Browser;
exports.StopEvent = StopEvent;

require("core-js/modules/es6.regexp.constructor");

//https://blog.csdn.net/catherine_sue/article/details/61924879
function Browser() {
  var _userAgent = navigator.userAgent; //取得浏览器的userAgent字符串

  var _isOpera = _userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器


  var _isFF = _userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  //判断是否Safari浏览器


  var _isSafari = _userAgent.indexOf("Safari") > -1;

  var _isChrome = _userAgent.indexOf("Chrome") > -1; //判断是否IE浏览器


  var _isIE = _userAgent.indexOf("compatible") > -1 && _userAgent.indexOf("MSIE") > -1 && !_isOpera;

  var _ieVer = 0;

  if (_isIE) {
    var _reIE = new RegExp("MSIE (\\d+\\.\\d+);");

    _reIE.test(_userAgent);

    _ieVer = parseFloat(RegExp["$1"]); //fIEVersion
  }

  return {
    ie: _ieVer,
    opera: _isOpera,
    chrome: _isChrome,
    safari: _isSafari,
    firefox: _isFF
  };
}

function StopEvent(event) {
  if (event.stopPropagation) {
    event.stopPropagation();
  } else {
    event.cancelBubble = true;
  }

  if (event.preventDefault) {
    event.preventDefault();
  } else {
    event.returnValue = false;
  }

  return false;
} ////http://developer.51cto.com/art/201204/331588.htm
//let LocalStorage = (function ()
//{
//  let _brow = Browser();
//  let ls = window.localStorage;
//  function _onstorage(key, callback)
//  {
//    let oldValue = ls[key];
//    /*     
//    IE下即使是当前页面触发的数据变更，当前页面也能收到onstorage事件，其他浏览器则只会在其他页面收到     
//    */
//    return function (e)
//    {
//      //IE下不使用 setTimeout 尽然获取不到改变后的值?!       
//      setTimeout(
//        function ()
//        {
//          e = e || window.storageEvent;
//          let tKey = e.key,
//            newValue = e.newValue;
//          //IE下不支持key属性,因此需要根据storage中的数据判断key中的数据是否变化       
//          if (!tKey)
//          {
//            let nv = ls[key];
//            if (nv != oldValue)
//            {
//              tKey = key;
//              newValue = nv;
//            }
//          }
//          if (tKey == key)
//          {
//            callback && callback(newValue);
//            oldValue = newValue;
//          }
//        },
//        0
//      );
//    }
//  }
//  return {
//    getItem: function (key)
//    {
//      return ls.getItem(key);
//    },
//    setItem: function (key, val)
//    {
//      return ls.setItem(key, val);
//    },
//    removeItem: function (key, val)
//    {
//      return ls.removeItem(key);
//    },
//    clear: function ()
//    {
//      return ls.clear();
//    },
//    onstorage: function (key, callback)
//    {
//      //IE6/IE7/Chrome使用Timer检查更新，其他使用onstorage事件       
//      /*     
//          Chrome下(14.0.794.0)重写了document.domain之后会导致onstorage不触发     
//          鉴于onstorage的兼容性问题暂时不使用onstorage事件，改用传统的轮询方式检查数据变化     
//      */
//      if (!this.useTimer)
//      {
//        //IE注册在document上       
//        if (document.attachEvent && !_brow.opera
//        )
//        {
//          document.attachEvent('onstorage', _onstorage(key, callback));
//        }
//        //其他注册在window上       
//        else
//        {
//          window.addEventListener('storage', _onstorage(key, callback), false);
//        };
//      }
//      else
//      {
//        /*     
//            Timer检查方式     
//         */
//        let listener = _onstorage(key, callback);
//        setInterval(
//          function ()
//          {
//            listener({});
//          },
//          this.interval
//        );
//      }
//    },
//    //是否使用Timer来check       
//    useTimer: (_brow.ie && _brow.ie < 8) || (_brow.chrome),
//    //检查storage是否发生变化的时间间隔       
//    interval: 1000
//  };
//})();