var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "c-table-header__record", style: _vm.getHeaderStyle },
    _vm._l(_vm.columnsConfig, function(column, index) {
      return _c(
        "li",
        {
          key: column[_vm.cIdKey],
          staticClass: "c-table-header-column",
          style: _vm.getColumnStyle(column),
          attrs: { columnKey: column[_vm.cIdKey], title: column.title }
        },
        [
          _c(
            "div",
            { staticClass: "c-table-header-column__container" },
            [
              !column.renderHeader
                ? _c("span", [_vm._v(_vm._s(column.title))])
                : _c("render-header", {
                    attrs: {
                      render: column.renderHeader,
                      column: column,
                      "column-index": index
                    }
                  })
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }