"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _default = {
  name: 'TableExpand',
  functional: true,
  props: {
    row: Object,
    render: Function,
    columnIndex: Number,
    index: {
      type: Number,
      default: -1
    },
    column: {
      type: Object,
      default: null
    }
  },
  render: function render(h, ctx) {
    var _props = ctx.props;
    var _row = _props.row;
    var params = {
      row: _row,
      index: _row && _row.__dataIndex ? _row.__dataIndex : _props.index,
      column: _props.column,
      columnIndex: _props.columnIndex
    };
    return _props.render(h, params);
  }
};
exports.default = _default;