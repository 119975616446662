"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _app = _interopRequireDefault(require("../app.initconfig"));

var _default = {
  Host: process.env.HOST_URL,
  ImageRoot: process.env.IMAGE_ROOT_URL,
  LoginPath: '/Account/Login',
  LogoutPath: '/Account/Logout',
  RefresPath: "/Account/RefreshAuth",
  VerifyAuthUnitTokenPath: "/Account/VerifyAuthUnitToken",
  UserStorageKey: "userInfo",
  AuthSetsEvenyKey: "AuthUnitSettings",
  InitConfig: _app.default,
  FindLv2Menu: function FindLv2Menu(path) {
    var _clildPathAry = (path || "").split('/');

    if (_clildPathAry.length > 2) {
      var _lv1 = _clildPathAry[1];
      var _lv2 = _clildPathAry[2];
      var _menuLv1 = _app.default.menus[_lv1];

      if (_menuLv1 && _menuLv1.Subs && _menuLv1.Subs[_lv2]) {
        return _menuLv1.Subs[_lv2];
      }
    }

    return null;
  }
};
exports.default = _default;