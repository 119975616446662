var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isMultHeaderRows
        ? _c(
            "single-table",
            {
              ref: "RefTable",
              attrs: {
                showSummaryFooter: _vm.showSummaryFooter,
                "table-owner": _vm.owner,
                data: _vm.filterResult,
                "columns-config": _vm.cloneColumnsConfig,
                "record-key": _vm.recordKey,
                "header-height": _vm.headerHeight,
                "body-height": _vm.bodyHeight,
                "table-width": _vm.tableWidth,
                "record-height": _vm.recordHeight,
                "header-class": _vm.headerClass,
                "multi-selection": _vm.multiSelection,
                "show-row-number": _vm.showRowNumber,
                "init-row-selection": _vm.initRowSelection,
                "auto-reset-scroll": _vm.autoResetScroll,
                loading: _vm.loading
              },
              on: { "on-selection-change": _vm.selectionChange }
            },
            [
              _vm.hasFooterSlot()
                ? _c("template", { slot: "footer" }, [_vm._t("footer")], 2)
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }