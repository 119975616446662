"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.string.fixed");

require("core-js/modules/es6.number.constructor");

var _vue = _interopRequireDefault(require("vue"));

var _TableHeader = _interopRequireDefault(require("./TableHeader.vue"));

var _TableBody = _interopRequireDefault(require("./TableBody.vue"));

var _selectionUtil = require("./tableHelper/selectionUtil");

var _rowNumberUtil = require("./tableHelper/rowNumberUtil");

//import elementResizeDetectorMaker from 'element-resize-detector';
var VmProps =
/** @class */
function () {
  function VmProps() {
    //主表体组件this
    this.loading = Boolean;
    this.tableOwner = Object;
    this.columns = Array;
    this.data = Array;
    this.subKey = String;
    this.recordKey = String;
    this.headerHeight = Number;
    this.bodyHeight = Number;
    this.tableHeight = Number;
    this.tableWidth = Number;
    this.recordHeight = Number;
    this.headerClass = {
      type: String,
      default: 'c-table-header__default'
    };
    /*是否启用多选*/

    this.multiSelection = {
      type: Boolean,
      default: false
    };
    /*是否显示序号*/

    this.showRowNumber = {
      type: Boolean,
      default: true
    };
    /*多选模式下用于初始化勾选状态*/

    this.initRowSelection = Function;
    /*在加载数据的时候是否重置滚动条 */

    this.autoResetScroll = Boolean;
    this.mainTitle = String;
    this.subTitle = String;
  }

  return VmProps;
}();

var VmComputed =
/** @class */
function () {
  function VmComputed() {
    this.getUnFixedColumnsConfig = function () {
      return this.cloneColumnsConfig.filter(function (m) {
        return m.fixed == '';
      });
    };

    this.getUnFixedWidth = function () {
      return getColumnsWidth(this.getUnFixedColumnsConfig);
    };

    this.getAllColumnsWidth = function () {
      return getColumnsWidth(this.cloneColumnsConfig);
    };

    this.getBodyHeight = function () {
      return this.data.length * this.recordHeight;
    };

    this.getTableWrapperStyle = function () {
      //最外层嵌套元素样式
      var _width = this.bodyVisable ? this.tableWidth ? this.tableWidth : this.bodyWidth : 0;

      return {
        'width': 0 != _width ? _width + "px" : 'inherit',
        'position': "relative"
      };
    };

    this.getMainBodyColumnsConfig = function () {
      var _$this = this;

      var _columns = _$this.getMainColumns;
      var _subColumnsConfig = _$this.getSubColumns;

      if (_subColumnsConfig.length > 0) {
        _subColumnsConfig[_subColumnsConfig.length - 1].showLeftBorder = true; //显示边界线

        var _subWidth_1 = getColumnsWidth(_subColumnsConfig);

        _subColumnsConfig = _subColumnsConfig.map(function (m) {
          var _m = JSON.parse(JSON.stringify(m));

          _m.render = m.render;
          _m.fixed = "";
          return _m;
        });
        var _col = {
          title: "",
          sortable: false,
          width: _subWidth_1,
          cWidth: _subWidth_1 + "px",
          disableDrag: true,
          fixed: "",
          render: function render(h, params) {
            var _index = params.index; //console.log(_$this.rowStateArray[_index]);

            if (!_$this.rowStateArray[_index].expend) {
              return h();
            }

            return h(_TableBody.default, {
              props: {
                columnsConfig: _subColumnsConfig,
                unFixedWidth: _subWidth_1,
                showLeftBorder: true,
                data: null == _$this.data || 0 == _$this.data.length || !_$this.subKey ? [] : _$this.data[_index][_$this.subKey]
              }
            });
          },
          key: ""
        };
        return [_col].concat(_columns);
      }

      return _columns;
    };

    this.getMainColumns = function () {
      var _$this = this;

      var _columns = _$this.cloneColumnsConfig.filter(function (p) {
        return p.fixed != 'sub';
      });

      return _columns; //_columns = _TableUtils.CreateInputColumnAry(
      //	_columns,
      //	false,
      //	_$this.data
      //);
      //return _columns;
    };

    this.getSubColumns = function () {
      var _$this = this;

      return _$this.cloneColumnsConfig.filter(function (p) {
        return p.fixed == 'sub';
      });
    };

    this.getExpMainBodyColumnsConfig = function () {
      return [this.expendColumn].concat(this.getMainBodyColumnsConfig);
    };

    this.getExpHeaderColumns = function () {
      return [this.expendColumn].concat(this.cloneColumnsConfig);
    };

    this.getHeaderStyle = function () {
      var _translateX = "translateX(" + -this.scrollSynclData.scrollLeft + "px)";

      return {
        'transform': _translateX,
        'width': getColumnsWidth(this.cloneColumnsConfig) + 100 + 'px'
      };
    };
  }

  return VmComputed;
}();

var VmData =
/** @class */
function () {
  function VmData() {
    /*勾选状态数据*/
    this.cellSelectionArray = [];
    /*折叠状态数据*/

    this.rowStateArray = []; //展开折叠操作列

    this.expendColumn = null; //滚动条同步对像,body通过这个对像将同步信息传给header

    this.scrollSynclData = {
      scrollTop: 0,
      scrollLeft: 0,
      scrollbarWidth: 16,
      offsetWidth: 0,
      hover_index: -1,
      clicked_index: -1,
      virtualItems: {
        renderData: [],
        newItems: [],
        replaceItemsIndex: 0
      }
    };
    this.cloneColumnsConfig = null;
    this.bodyVisable = false;
    this.hiddenVerticalScroll = false;
    this.bodyWidth = 0;
    this.selectionColumn = null;
    this.rowNumberColumn = null;
  }

  return VmData;
}();

var VmWatch =
/** @class */
function () {
  function VmWatch() {
    this.columns = {
      handler: function handler(config) {
        var _$this = this;

        if (null == _$this.expendColumn) {
          _$this.expendColumn = {
            title: '',
            sortable: true,
            width: 50,
            cWidth: '50px',
            disableDrag: true,
            fixed: '',
            key: '',
            noNeedVertical: true,
            enableEllipsis: true,
            render: function render(h, params) {
              var _index = params.index;
              var _expend = _$this.rowStateArray[_index];
              return h("div", {
                staticStyle: {
                  'margin-top': '2px',
                  'height': 'inherit'
                },
                on: {
                  click: function click(e) {
                    _expend.expend = !_expend.expend;
                  }
                }
              }, [h("span", {
                staticStyle: {
                  'padding-left': '4px',
                  'font-size': '12px',
                  'width': '12px',
                  'color': '#2d8cf0'
                },
                attrs: {}
              }, true == _expend.expend ? "[收缩]" : "[展开]") //h("span", {
              //	staticClass: true == _expend.expend ? "iconfont icon-checkbox_on" : "iconfont icon-check-box-off",
              //	staticStyle: {
              //		'font-size': '18px',
              //		'width': '40px',
              //		'color': '#2d8cf0'
              //	},
              //	attrs: {}
              //})
              ]);
            }
          };
        }

        if (null == _$this.rowNumberColumn) {
          _$this.rowNumberColumn = (0, _rowNumberUtil.generateRowNumberColumn)(_$this, _$this);
          _$this.rowNumberColumn.fixed = 'sub';
        }

        if (!config || config.length == 0) {
          _$this.cloneColumnsConfig = [_$this.rowNumberColumn];
        }

        if (null == _$this.selectionColumn) {
          //用临时变量的话会selectionColumn绑定到组件上会有异常
          //（因为构建时, VmWatch进入了两次, 函数内的_toggleSelectObject和绑定到组件上的_toggleSelectObject不是同一对象）
          _$this.selectionColumn = (0, _selectionUtil.generateSelectionColumn)(_$this, _$this, _$this.subKey, _$this.cellSelectionArray);
          _$this.selectionColumn.fixed = 'sub';
        }

        if (_$this.multiSelection) {
          config = [_$this.selectionColumn].concat(config);
        }

        if (_$this.showRowNumber) {
          config = [_$this.rowNumberColumn].concat(config);
        }

        var _resize = false;

        if (_$this.cloneColumnsConfig) {
          getAndInitColumnsDefaultWidth(config);

          for (var _cIndex = 0; _cIndex < _$this.cloneColumnsConfig.length; _cIndex++) {
            var _col_1 = _$this.cloneColumnsConfig[_cIndex];
            var _col_2 = config[_cIndex];

            if (_col_1.defaultWidth != _col_2.defaultWidth || _col_1.key != _col_2.key || _col_1.render != _col_2.render || _col_1.title != _col_2.title || _col_1.renderHeader != _col_2.renderHeader) {
              _$this.cloneColumnsConfig = config;
              _resize = true;
              break;
            }
          }
        } else {
          _$this.cloneColumnsConfig = config;
          _resize = true;
        }

        if (_resize || _$this.getTableWrapperWidth() > getColumnsWidth(_$this.cloneColumnsConfig)) {
          //"debug code"
          //console.log('columns resize');
          //"end debug code"
          _$this.handleResize();
        }
      },
      immediate: true
    };
    this.data = {
      handler: function handler(rowAry) {
        var _$this = this;

        var _checkedCount = 0;

        if (rowAry && rowAry.length > 0) {
          _$this.rowStateArray.splice(0);

          _$this.cellSelectionArray.splice(0);

          for (var _pIndex = 0, _sIndex = 0; _pIndex < rowAry.length; _pIndex++) {
            _$this.rowStateArray.push({
              expend: true
            });

            var _row = rowAry[_pIndex];
            var _subAry = _row[_$this.subKey];

            if (_subAry) {
              for (var _s = 0, _sCnt = _subAry.length; _s < _sCnt; _s++) {
                var _sub = _subAry[_s];
                _sub.__dataIndex = _sIndex++;
                _sub.__parentIndex = _pIndex;

                _$this.cellSelectionArray.push({
                  index: _sIndex,
                  checked: false
                });
              }
            }
          }
        }

        var _selectionColumn = _$this.selectionColumn;

        if (_$this.multiSelection && _selectionColumn) {
          
          var _toggleSelectObject = _selectionColumn.toggleSelectObject;

          _selectionColumn.resetSelection();

          var _cellDict = _selectionColumn.cellSelectionDict;

          for (var _index in _cellDict) {
            _cellDict[_index].checked = false;
          }

          if (rowAry && rowAry.length > 0) {
            //判断全选
            _toggleSelectObject.checked = _checkedCount == rowAry.length;
          } else {
            _toggleSelectObject.checked = false;
          }

          if (_toggleSelectObject && _toggleSelectObject.setCellChecked) {
            _toggleSelectObject.setCellChecked(_toggleSelectObject.checked);
          }
        }

        _$this.scrollSynclData.clicked_index = -1;
      },
      immediate: true,
      deep: true
    };
  }

  return VmWatch;
}();

var VmMethods =
/** @class */
function () {
  function VmMethods() {
    this.getCheckedIndexAry = function () {
      return this.selectionColumn ? this.selectionColumn.getSelectionIndexAry() : [];
    };

    this.getFocusdIndex = function () {
      return this.scrollSynclData ? this.scrollSynclData.clicked_index : -1;
    };

    this.clickCurrentRow = function (index) {
      var _$this = this;

      _$this.scrollSynclData.clicked_index = index;

      _$this.tableOwner.$emit('on-row-click', JSON.parse(JSON.stringify(_$this.data[index])), index);
    };

    this.hasFooterSlot = function () {
      var _$this = this;

      return _$this.$slots.footer !== undefined;
    };

    this.getSelectionData = function () {
      return this.selectionColumn.getSelectionData();
    };

    this.getTableWrapperWidth = function () {
      var _$this = this;

      var _tableWraper = _$this.$refs.tableWrapper;

      if (!_tableWraper) {
        return -1;
      }

      var _bodyWidth = _tableWraper.clientWidth; //tableHeader

      if (0 == _bodyWidth) {
        _bodyWidth = _tableWraper.scrollWidth;
      }

      return 0 == _bodyWidth ? 0 : _bodyWidth;
    };

    this.tableResize = function () {
      //自适应列宽
      var _$this = this;

      _$this.bodyWidth = _$this.getTableWrapperWidth();

      var _getAllColumnsWidth = getAndInitColumnsDefaultWidth(_$this.cloneColumnsConfig, _$this.bodyWidth); // _$this.getAllColumnsWidth;


      var _getUnFixedWidth = _$this.getUnFixedWidth;
      _$this.hiddenVerticalScroll = _$this.bodyWidth > _getAllColumnsWidth;

      var _diffWidth = _$this.bodyWidth - _getAllColumnsWidth; //console.log('bodyWidth=', _$this.bodyWidth, '_defWidth=', _diffWidth);


      var _lessWidth = _diffWidth;
      var _unFixColumns = _$this.getUnFixedColumnsConfig;

      for (var _c = 0, _cLen = _unFixColumns.length, _cLast = _cLen - 1; _c < _cLen; _c++) {
        var _col = _unFixColumns[_c];

        if (_$this.hiddenVerticalScroll) {
          //如果长度超过设定宽度，调整列宽度
          var _defaultWidth = parseInt(_col.defaultWidth);

          var _rateWidth = parseInt(_defaultWidth / _getUnFixedWidth * _diffWidth);

          _col.width = _defaultWidth + (_lessWidth < _rateWidth || _cLast == _c ? _lessWidth : _rateWidth);
          _lessWidth -= _rateWidth;
          _col.cWidth = _col.width + "px"; //console.log('_col=', _col.key, '_lessWidth=', _lessWidth, '_w2=', _rateWidth, 'cWidth=', _col.cWidth);
        } else {
          _col.cWidth = _col.defaultWidth + "px";
        }
      } //强制刷新computed依赖缓存


      _$this.$set(_$this, 'cloneColumnsConfig', _$this.cloneColumnsConfig.slice());

      _$this.$nextTick(function () {
        _$this.bodyVisable = !!_$this.$refs.tableHeader; //console.log(_$this.$refs.tableHeader);
      });
    };

    this.handleResize = function () {
      var _$this = this;

      _$this.bodyVisable = false;

      _$this.$nextTick(function () {
        _$this.tableResize();
      });
    };

    this.onVirtualScroll = function () {
      var _$this = this;

      var _virtualScrollBody = _$this.$refs.virtualScrollBody;

      var _scrollTop = _virtualScrollBody ? _virtualScrollBody.scrollTop : 0;

      var _scrollLeft = _virtualScrollBody ? _virtualScrollBody.scrollLeft : 0;

      if (_$this.scrollSynclData) {
        _$this.scrollSynclData.scrollTop = _scrollTop;
        _$this.scrollSynclData.scrollLeft = _scrollLeft;
      }
    };
  }

  return VmMethods;
}();

var _default = {
  components: {
    TableHeader: _TableHeader.default,
    TableBody: _TableBody.default
  },
  name: 'SingleTable',
  props: new VmProps(),
  watch: new VmWatch(),
  data: function data() {
    return new VmData();
  },
  computed: new VmComputed(),
  methods: new VmMethods(),
  mounted: function mounted() {
    var _$this = this;

    _$this.tableResize(); ////_$this.$nextTick(() => _$this.ready = true);


    on(window, 'resize', _$this.handleResize); //_$this.observer = elementResizeDetectorMaker();
    //_$this.observer.listenTo(_$this.$el, _$this.handleResize);

    _$this.$on('on-visible-change', function (val) {
      if (val) {
        _$this.handleResize();
      }
    });
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleResize); //this.observer.removeListener(this.$el, this.handleResize);
  }
};
exports.default = _default;
var isServer = _vue.default.prototype.$isServer;
/* istanbul ignore next */

var on = function () {
  if (!isServer && document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
}();
/* istanbul ignore next */


var off = function () {
  if (!isServer && document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
}();
/**
 * 计算列宽
 */


function getColumnsWidth(columnsConfigArray) {
  var _bodyWidth = 0;
  var _cfgAry = columnsConfigArray;

  for (var _c = 0; _c < _cfgAry.length; _c++) {
    var _col = _cfgAry[_c];

    var _columnWidth = parseInt(_col.cWidth ? _col.cWidth.replace('px', '') : _col.width);

    _col.cWidth = _columnWidth + "px";
    _bodyWidth += _columnWidth;
  }

  return _bodyWidth;
}
/**
 * 计算默认列宽
 */


function getAndInitColumnsDefaultWidth(columns, tableWidth) {
  if (!columns) {
    return 0;
  }

  var _bodyWidth = 0;
  var _cfg = columns;

  for (var _c = 0; _c < _cfg.length; _c++) {
    var _col = _cfg[_c];

    if (!_col.defaultWidth) {
      _col.defaultWidth = parseInt(_col.cWidth ? _col.cWidth.replace('px', '') : _col.width ? _col.width : 0);

      if (0 == _col.defaultWidth || isNaN(_col.defaultWidth)) {
        _col.defaultWidth = _col.minWidth || 85; //默认60
      }

      _col.cWidth = _col.defaultWidth + "px";
    }

    _bodyWidth += _col.defaultWidth;
  }

  return _bodyWidth;
}