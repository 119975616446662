"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateSelectionColumn = generateSelectionColumn;
//表格多选列
//checkbox 组件
var SelectionCheckBox = {
  name: 'SelectionCheckBox',
  props: {
    checkSyncObject: Object
  },
  watch: {
    checkSyncObject: {
      handler: function handler(val) {
        var _$this = this;

        _$this.checked = val.checked;
        _$this.index = val.index;

        val.setCellChecked = function (check) {
          _$this.checked = check;
          
        };
      },
      immediate: true,
      deep: true
    }
  },
  data: function data() {
    var _$this = this;

    return {
      index: -1,
      checked: false
    }; ////直接把输入属性做绑定对像绑定
    //return _$this.checkSyncObject;
  },
  render: function render(h, params) {
    var _$this = this; //console.log('render selection');


    _$this.checked = _$this.checkSyncObject.checked;
    _$this.index = _$this.checkSyncObject.index;
    return h("div", {
      on: {
        click: function click(e) {
          var _synObject = _$this.checkSyncObject;
          

          var _checked = !_$this.checked;

          _$this.checked = _checked;

          if (_synObject.onCellCheckedChange) {
            _synObject.onCellCheckedChange(_checked, _$this.index);
          }
        }
      }
    }, [h("span", {
      staticClass: true == _$this.checked ? "iconfont icon-checkbox_on" : "iconfont icon-check-box-off",
      staticStyle: {
        'font-size': '18px',
        'width': '40px',
        'color': '#2d8cf0'
      },
      attrs: {}
    })]);
  },
  destroyed: function destroyed() {
    var _$this = this;

    

    if (_$this.checkSyncObject) {
      _$this.checkSyncObject.setCellChecked = null;
    }
  }
};

function generateSelectionColumn(vmThis, //主表体组件this
tableOwner, subKey, cellSelectionArray) {
  //let _cellSelectionDict = {} as any as Map<string, { checked: boolean }>;
  var _checkedCount = 0;
  var _defaultChecked = false;

  var _selectionGeter = function _selectionGeter() {
    return _getSelectionData(cellSelectionArray, vmThis.data, _defaultChecked, subKey);
  };
  /* 全选回调 */


  var _onToggleSelected = function _onToggleSelected(checked) {
    //console.log('onCellCheckedChange', checked);
    for (var _key in cellSelectionArray) {
      var _cellSelection = cellSelectionArray[_key];
      _cellSelection.checked = checked;

      if (_cellSelection.setCellChecked) {
        _cellSelection.setCellChecked(checked);
      }
    }

    _defaultChecked = checked;

    if (checked) {
      _checkedCount = cellSelectionArray ? cellSelectionArray.length : 0;
    } else {
      _checkedCount = 0;
    }

    if (_toggleSelectObject.setCellChecked) {
      _toggleSelectObject.checked = checked;

      _toggleSelectObject.setCellChecked(checked);
    }

    if (checked) {
      emitSelections('on-select-all');
    } else {
      emitSelections('on-select-all-cancel');
    }

    emitSelections('on-selection-change');
  };

  var _toggleSelectObject = {
    checked: false,
    onCellCheckedChange: _onToggleSelected,
    index: -1
  };
  /* 单选回调 */

  var _onCellCheckedChange = function _onCellCheckedChange(checked, index) {
    cellSelectionArray[index].checked = checked;

    if (checked) {
      _checkedCount++;
    } else {
      _checkedCount--;
    }

    if (subKey) {
      var _data = vmThis.data;
      var _hasData = false;

      for (var _i = 0; _i < _data.length; _i++) {
        for (var _i2 = 0; _i2 < _data[_i][subKey].length; _i2++) {
          if (index == _data[_i][subKey][_i2].__dataIndex) {
            tableOwner.$emit(checked ? 'on-select' : 'on-select-cancel', _selectionGeter, JSON.parse(JSON.stringify(_data[_i][subKey][_i2])));
            _hasData = true;
            break;
          }

          if (_hasData) {
            break;
          }
        }
      }
    } else {
      tableOwner.$emit(checked ? 'on-select' : 'on-select-cancel', _selectionGeter, JSON.parse(JSON.stringify(vmThis.data[index])));
    }

    emitSelections('on-selection-change');
    vmThis.$nextTick(function () {
      var _dataCount = cellSelectionArray ? cellSelectionArray.length : 0;

      console.log(_checkedCount, _dataCount);

      var _checked = _checkedCount == _dataCount;

      _toggleSelectObject.checked = _checked;

      if (_toggleSelectObject.setCellChecked) {
        _toggleSelectObject.setCellChecked(_checked);
      }
    });
  };

  return {
    title: '选择',
    sortable: true,
    width: 31,
    disableDrag: true,
    fixed: 'left',
    renderHeader: function renderHeader(h, params) {
      var _column = params.column;
      var _index = params.index;
      console.log(params);
      return h(SelectionCheckBox, {
        props: {
          checkSyncObject: _toggleSelectObject
        }
      });
    },
    render: function render(h, params) {
      var _row = params.row;
      var _index = _row.__dataIndex;
      var _checkObject = cellSelectionArray[_index];
      _checkObject.onCellCheckedChange = _onCellCheckedChange;
      _checkObject.index = _index;
      return h(SelectionCheckBox, {
        props: {
          checkSyncObject: _checkObject
        }
      });
    },
    key: '',
    noNeedVertical: true,
    enableEllipsis: true,
    getSelectionData: function getSelectionData() {
      return _getSelectionData(cellSelectionArray, vmThis.data, _defaultChecked, subKey);
    },
    getSelectionIndexAry: function getSelectionIndexAry() {
      return _getSelectionIndexAry(cellSelectionArray, vmThis.data, _defaultChecked);
    },
    resetSelection: function resetSelection() {
      _checkedCount = 0;
    },
    toggleSelectObject: _toggleSelectObject,
    cellSelectionDict: cellSelectionArray
  };

  function emitSelections(eventKey) {
    if (tableOwner.$listeners[eventKey]) {
      
      tableOwner.$emit(eventKey, _selectionGeter());
    }
  }
} //获取勾选数据


function _getSelectionData(checkedDict, data, defaultChecked, subKey) {
  if (subKey) {
    var _data = [];

    for (var _i = 0; _i < data.length; _i++) {
      _data.push.apply(_data, data[_i][subKey]);
    }

    data = _data;
  }

  var _selectionIndexes = [];
  var _unSelectionIndexes = [];

  for (var _i_1 in checkedDict) {
    var _index = parseInt(_i_1);

    if (checkedDict[_i_1].checked) {
      _selectionIndexes.push(_index);
    } else {
      _unSelectionIndexes.push(_index);
    }
  }

  if (defaultChecked) {
    //全选情况下,排除非选择项目
    return JSON.parse(JSON.stringify(data.filter(function (data, index) {
      return _unSelectionIndexes.indexOf(index) == -1;
    })));
  } //非全选情况下,只获取选择项


  return JSON.parse(JSON.stringify(data.filter(function (data, index) {
    return _selectionIndexes.indexOf(index) > -1;
  })));
} //获取勾选数据index


function _getSelectionIndexAry(checkedDict, data, defaultChecked) {
  var _selectionIndexes = [];
  var _unSelectionIndexes = [];

  for (var _i in checkedDict) {
    var _index = parseInt(_i);

    if (checkedDict[_i].checked) {
      _selectionIndexes.push(_index);
    } else {
      _unSelectionIndexes.push(_index);
    }
  }

  var _indexLis = new Array();

  for (var _index = 0; _index < data.length; _index++) {
    if (defaultChecked) {
      //全选情况下,排除非选择项目
      if (_unSelectionIndexes.indexOf(_index) == -1) {
        _indexLis.push(_index);
      }
    } else {
      //非全选情况下,只获取选择项
      if (_selectionIndexes.indexOf(_index) > -1) {
        _indexLis.push(_index);
      }
    }
  }

  return _indexLis;
}