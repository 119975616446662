var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "tableWrapper", staticStyle: { width: "inherit" } }, [
    _c(
      "article",
      {
        ref: "tableHeader",
        staticClass: "c-table-wrapper",
        style: _vm.getTableWrapperStyle
      },
      [
        _vm.bodyVisable
          ? _c("table-header", {
              attrs: {
                "header-class": _vm.headerClass,
                "columns-config": _vm.getUnFixedColumnsConfig,
                height: _vm.headerHeight,
                "un-fixed-width": _vm.getUnFixedWidth,
                "fixed-left-width": _vm.getFixedLeftWidth,
                "fixed-right-width": _vm.getFixedRightWidth,
                "viewport-width": _vm.tableWidth,
                "scroll-syncl-data": _vm.scrollSynclData
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.bodyVisable
          ? _c("virtual-scroll-table-body", {
              ref: "virtualScrollBody",
              attrs: {
                "table-owner": _vm.tableOwner,
                "hidden-vertical-scroll": _vm.hiddenVerticalScroll,
                data: _vm.data,
                "record-key": _vm.recordKey,
                "columns-config": _vm.getUnFixedColumnsConfig,
                "un-fixed-width": _vm.getUnFixedWidth,
                "fixed-left-width": _vm.getFixedLeftWidth,
                "fixed-right-width": _vm.getFixedRightWidth,
                "item-height": _vm.recordHeight,
                "viewport-height": _vm.bodyHeight,
                "viewport-width": _vm.tableWidth,
                "scroll-syncl-data": _vm.scrollSynclData,
                "auto-reset-scroll": _vm.autoResetScroll
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.bodyVisable
          ? _c("virtual-scroll-table-fixed", {
              attrs: {
                fixedRight: false,
                "hidden-vertical-scroll": _vm.hiddenVerticalScroll,
                fixedStyle: _vm.getFixedLeftStyle,
                "table-owner": _vm.tableOwner,
                "table-fiexed-class": _vm.getFixedLeftClass,
                headerClass: _vm.headerClass,
                "virtual-items": _vm.scrollSynclData.virtualItems,
                "scroll-syncl-data": _vm.scrollSynclData,
                "record-key": _vm.recordKey,
                "columns-config": _vm.getFixedLeftColumnsConfig,
                "header-height": _vm.headerHeight,
                "item-height": _vm.recordHeight,
                "body-height": _vm.getBodyHeight,
                "fixed-height": _vm.bodyHeight,
                "fixed-width": _vm.getFixedLeftWidth,
                "enable-select-style": true
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("section", { style: _vm.getFixedRightHeadStyle }),
        _vm._v(" "),
        _vm.bodyVisable
          ? _c("virtual-scroll-table-fixed", {
              attrs: {
                fixedStyle: _vm.getFixedRightStyle,
                fixedRight: true,
                "hidden-vertical-scroll": _vm.hiddenVerticalScroll,
                "table-owner": _vm.tableOwner,
                "table-fiexed-class": _vm.getFixedRightClass,
                headerClass: _vm.headerClass,
                "virtual-items": _vm.scrollSynclData.virtualItems,
                "scroll-syncl-data": _vm.scrollSynclData,
                "record-key": _vm.recordKey,
                "columns-config": _vm.getFixedRightColumnsConfig,
                "header-height": _vm.headerHeight,
                "item-height": _vm.recordHeight,
                "body-height": _vm.getBodyHeight,
                "fixed-height": _vm.bodyHeight,
                "fixed-width": _vm.getFixedRightWidth
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.bodyVisable && _vm.showSummaryFooter
          ? _c("table-summary-footer", {
              staticStyle: {
                "border-bottom-style": "solid",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(221, 221, 221)"
              },
              attrs: {
                data: _vm.data,
                "header-class": _vm.headerClass,
                "columns-config": _vm.getUnFixedColumnsConfig,
                height: _vm.headerHeight,
                "un-fixed-width": _vm.getUnFixedWidth,
                "fixed-left-width": _vm.getFixedLeftWidth,
                "fixed-right-width": _vm.getFixedRightWidth,
                "viewport-width": _vm.tableWidth,
                "scroll-syncl-data": _vm.scrollSynclData
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          class: { "c-table-loading-shadow": _vm.loading },
          style: _vm.getTableShadowStyle
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.hasFooterSlot()
      ? _c(
          "div",
          { staticClass: "c-table-footer", style: _vm.getTableFooterStyle },
          [_vm._t("footer")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }