var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClose,
          expression: "handleClose"
        }
      ],
      class: _vm.classes,
      on: { mouseenter: _vm.handleMouseenter, mouseleave: _vm.handleMouseleave }
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          class: [_vm.prefixCls + "-rel"],
          on: {
            click: _vm.handleClick,
            mousedown: function($event) {
              return _vm.handleFocus(false)
            },
            mouseup: function($event) {
              return _vm.handleBlur(false)
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }